import React from 'react';
import { Form, Input, Button,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;



var token= ''
var sacco= ''


class ResourceForm extends React.Component {

  state = {
    resource_name:'' ,  
    datarequested:false,
    attachment:null,

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('resource_name', this.state.resource_name);

    axios.post(serverconfig.backendserverurl+'/api/resource_attachments/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res => {

      //now update the object reource file
      let form_data1 = new FormData();
      form_data1.append('pri_id', res.data.new_object_id);
      form_data1.append('update_type', "resource_attachment");

      this.state.attachment==null?
      console.log("No pre attachmnet file")
      :
      form_data1.append('attachment', this.state.attachment, this.state.attachment?this.state.attachment.name:"");

      axios.post(serverconfig.backendserverurl+'/customqueries/late_fileupload', form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      }).catch(error => console.log(error))
      //end the object file upload



    }).catch(error => console.log(error))

  }

  handleresource_nameChange= (e) => this.setState({ resource_name: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
      <Form
          onFinish={(event) => this.handleFormSubmit(event)}
      >
      <FormItem label="Resource Name"
        name="idname"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="resource_name"  placeholder="Put a name here." value={this.state.resource_name} onChange={this.handleresource_nameChange} />
      </FormItem>

      <FormItem label="Attachment" style={{marginRight:3}}>
        <Input type="file" accept=".pdf" placeholder="file attachment"  
        onChange={async(e) =>{
          this.setState({ attachment: e.target.files[0]});
        } }/>
      </FormItem> 

      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default ResourceForm;



