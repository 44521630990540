import React from 'react';
import { Form, Input, Button,Spin,DatePicker,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import moment from 'moment';

import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''
var bizuserid= ''

class CandidatePremedicalForm extends React.Component {

  state = {
    date:'' ,  
    candidate:'',
    end_date:'',
    medical_facility:'',
    doctor_name:'',
    attachment:null,
    datarequested:true,
    recruits:[],
    selected_recruit:'',
    doctor_contact:'',
    more_details:'',
    medical_facilities:[],
    status:'',
    userrights:{},

  }

  componentDidMount(){
   
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/medical_facilities/`)
    .then(res => {
        this.setState({
          medical_facilities:res.data
        })

     //   this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/recruits/`)
    .then(res => {
        this.setState({
          recruits:res.data
        })

        this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('candidate', this.state.candidate);
    form_data.append('medical_facility', this.state.medical_facility);
    form_data.append('doctor_name', this.state.doctor_name);
    form_data.append('doctor_contact', this.state.doctor_contact);
    form_data.append('more_details', this.state.more_details);
    form_data.append('status', this.state.status);

    
    axios.post(serverconfig.backendserverurl+'/api/candidate_premidicals/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {

      //now update the object reource file
      let form_data1 = new FormData();
      form_data1.append('pri_id', res.data.new_object_id);
      form_data1.append('update_type', "premedicals");

      this.state.attachment==null?
      console.log("No pre attachmnet file")
      :
      form_data1.append('attachment', this.state.attachment, this.state.attachment?this.state.attachment.name:"");

      axios.post(serverconfig.backendserverurl+'/customqueries/late_fileupload', form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      }).catch(error => console.log(error))
      //end the object file upload
    

  }   
  )
  .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>   
        </div>
      )

    }else{
      return (
        <div>
          {
         this.state.userrights.create_premedical===true?
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

          <FormItem label="Date of premedical"
            name='startdate'
            rules={[
              {
                required: true,
                message: 'Please Select date',
              },
            ]}
          >
              <DatePicker value={moment()} onChange={(date, dateString) => this.setState({date: dateString})} format={dateFormat} />
          </FormItem>

       
        <FormItem label="Select Candidate">
          <Select 
          placeholder="Candidate" 
          style={{  }} 
          value={this.state.candidate}
          onChange={(val)=>{this.setState({candidate:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.recruits.map(
              (accnt)=>(
                <Option value={accnt.id}>{accnt.FullName} | {accnt.recruit_number}</Option>
              ))}
          </Select>
      </FormItem>


      <FormItem label="Medical facility"
        name="medical_facility"
        rules={[
          {
            required: true,
            message: 'Please input medical_facility',
          },
        ]}
      >
        <Select 
          placeholder="Facility" 
          style={{  }} 
          value={this.state.medical_facility}
          onChange={(val)=>{this.setState({medical_facility:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.medical_facilities.map(
              (accnt)=>(
                <Option value={accnt.name}>{accnt.name}</Option>
              ))}
          </Select>
      </FormItem>

      <FormItem label="Doctor / health-personel name"
        name="doctor_name"
        rules={[
          {
            required: false,
            message: 'Please input doctor_name',
          },
        ]}    
      >
        <Input name="doctor_name"  placeholder="doctor_name here" value={this.state.doctor_name} onChange={(val)=>{this.setState({doctor_name:val.target.value})}} />
      </FormItem>

      <FormItem label="Doctor / health-personel contact"
        name="doctor_contact"
        rules={[
          {
            required: false,
            message: 'Please input doctor_contact',
          },
        ]}    
      >
        <Input name="doctor_contact"  placeholder="doctor_contact here" value={this.state.doctor_contact} onChange={(val)=>{this.setState({doctor_contact:val.target.value})}} />
      </FormItem>

      <FormItem label="More details"
        name="more_details"
        rules={[
          {
            required: false,
            message: 'Please input more_details',
          },
        ]}    
      >
        <Input name="more_details"  placeholder="more_details here" value={this.state.more_details} onChange={(val)=>{this.setState({more_details:val.target.value})}} />
      </FormItem>

      <FormItem label="Status"
      >
        <Select 
          value={this.state.status} 
          onChange={(val)=>{this.setState({status:val})}}
          placeholder="Status" >
          <Option value='Passed'>Passed</Option>
          <Option value='Failed'>Failed</Option>  
          </Select>
      </FormItem>

      <FormItem label="Attachment" style={{marginRight:3}}>
        <Input type="file" accept=".pdf" placeholder="file attachment"  
        onChange={async(e) =>{
          this.setState({ attachment: e.target.files[0]});
        } }/>
      </FormItem> 


      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>

      </Form>
      :
      null}
        </div>
      );

    }
    
  }

}


export default CandidatePremedicalForm;



