import React from 'react';
import CordinatorReportForm from '../components/CordinatorReportForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined,PrinterFilled,EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import ReactToPrint from 'react-to-print';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var token= ''




class CordinatorReportList_Cand_display extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    cordinator_reports: [],
    datarequested:true,
    cased_mode_visible:false,
    showprintermodal:false,
    selected_report:{},
    candidateobj:{}
  };


  componentDidMount(){
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/cordinator_reports`)
    .then(res => {
        this.setState({
            cordinator_reports:res.data
        })
        this.setState({datarequested:false})
    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/cordinator_reports/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  render() {

    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Candidate',
        dataIndex: 'candidate_name',
        key: 'id',
        ...this.getColumnSearchProps('candidate_name'),
      },
      {
        title: 'Cordinator',
        dataIndex: 'cordinator_name',
        key: 'id',
        ...this.getColumnSearchProps('cordinator_name'),
      },

      {
        title: 'Report no',
        dataIndex: 'report_no',
        key: 'id',
        ...this.getColumnSearchProps('report_no'),
      },
      {
        title: 'Report title',
        dataIndex: 'report_title',
        key: 'id',
        ...this.getColumnSearchProps('report_title'),
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <a href={record.attachment}>Download</a>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here view details</p>} title="View details">
         <FundViewOutlined style={{color:'blue'}} 
          onClick={()=>{
          this.setState({selected_report:record})

          //get attached candidate
          axios.get(`${serverconfig.backendserverurl}/api/recruits/${record.candidate}`)
          .then(res => {  
              this.setState({
                  candidateobj: res.data
              })

              this.setState({cased_mode_visible:true})

            })


         
         }}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to print case details</p>} title="Print">
        <PrinterFilled style={{color:'blue',fontSize: '20px'}} 
          onClick={()=>{
              this.setState({selected_report:record})

              //get attached candidate
              axios.get(`${serverconfig.backendserverurl}/api/recruits/${record.candidate}`)
              .then(res => {  
                  this.setState({
                      candidateobj: res.data
                  })

                  this.setState({showprintermodal:true})


                })

          }}/>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>

          <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>Date </th>
                <th>Report title</th>
                <th>Report no </th>
              </tr>
            </thead>
            <tbody>
            {this.state.cordinator_reports.map(
              (item)=>(
                <tr>
                <td>{item.date}</td>
                <td>{item.report_title}</td>
                <td>{item.report_no}</td>
                </tr>
              ))}

          </tbody>
          </reactstrp.Table> 

        </div>
         )

    }

  }
}

export default CordinatorReportList_Cand_display; 
