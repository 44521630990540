import React from 'react';
import { Form, Input, Button,Spin,DatePicker,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import moment from 'moment';

import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''

class CandidateTicketEditForm extends React.Component {

  state = {
    date_of_payment:'' ,  
    candidate:'',
    date_of_departure:'',
    air_line_name:'',
    air_line_phone:'',
    attachment:null,
    datarequested:true,
    recruits:[],
    selected_recruit:'',
    air_line_email:'',

    ticketno:'',
    check_in_time:'',
    status:'Pending',
    more_details:'',

    pick_up:'',
    destination:''

  }

  componentDidMount(){
    const ticketID=this.props.match.params.ticketID;

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/recruits/`)
    .then(res => {
        this.setState({
          recruits:res.data
        })

        //this.setState({datarequested:false})

    })

    axios.get(`${serverconfig.backendserverurl}/api/candidate_tickets/${ticketID}`)
    .then(res => {  
      
        this.setState({date_of_payment:res.data.date_of_payment})
        this.setState({date_of_departure:res.data.date_of_departure})
        this.setState({candidate:res.data.candidate})

        this.setState({air_line_name:res.data.air_line_name})
        this.setState({air_line_phone:res.data.air_line_phone})
        this.setState({air_line_email:res.data.air_line_email})

        this.setState({status:res.data.status})
        this.setState({ticketno:res.data.ticketno})
        this.setState({check_in_time:res.data.check_in_time})
        this.setState({more_details:res.data.more_details})

        this.setState({pick_up:res.data.pick_up})
        this.setState({destination:res.data.destination})

        this.setState({datarequested:false})

    })



  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    const ticketID=this.props.match.params.ticketID;

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('date_of_payment', this.state.date_of_payment);
    form_data.append('date_of_departure', this.state.date_of_departure);
    form_data.append('candidate', this.state.candidate);

    form_data.append('air_line_name', this.state.air_line_name);
    form_data.append('air_line_phone', this.state.air_line_phone);
    form_data.append('air_line_email', this.state.air_line_email);
    form_data.append('status', this.state.status);
    form_data.append('ticketno', this.state.ticketno);
    form_data.append('check_in_time', this.state.check_in_time);
    form_data.append('more_details', this.state.more_details);

    form_data.append('pick_up', this.state.pick_up);
    form_data.append('destination', this.state.destination);

    axios.put(serverconfig.backendserverurl+`/api/candidate_tickets/${ticketID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>  {

      //now update the object reource file
      let form_data1 = new FormData();
      form_data1.append('pri_id', ticketID);
      form_data1.append('update_type', "ticket");

      this.state.attachment==null?
      console.log("No pre attachmnet file")
      :
      form_data1.append('attachment', this.state.attachment, this.state.attachment?this.state.attachment.name:"");

      axios.post(serverconfig.backendserverurl+'/customqueries/late_fileupload', form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      }).catch(error => console.log(error))
      //end the object file upload



  })
  .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>   
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

          <FormItem label="Payment date"
          
          >
              <DatePicker  onChange={(date, dateString) => this.setState({date_of_payment: dateString})} format={dateFormat} />
          </FormItem>

          <FormItem label="Departure date"
          
          >
              <DatePicker onChange={(date, dateString) => this.setState({date_of_departure: dateString})} format={dateFormat} />
          </FormItem>


        <FormItem label="Select Candidate">
          <Select 
          placeholder="Candidate" 
          style={{  }} 
          value={this.state.candidate}
          onChange={(val)=>{this.setState({candidate:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.recruits.map(
              (accnt)=>(
                <Option value={accnt.id}>{accnt.FullName} | {accnt.recruit_number}</Option>
              ))}
          </Select>
      </FormItem>


      <FormItem label="Airline name"
        
      >
        <Input name="air_line_name"  placeholder="Put a air_line_name here." value={this.state.air_line_name} onChange={(val)=>{this.setState({air_line_name:val.target.value})}} />
      </FormItem>

      <FormItem label="Airline phone contact"
         
      >
        <Input name="air_line_phone"  placeholder="air_line_phone here" value={this.state.air_line_phone} onChange={(val)=>{this.setState({air_line_phone:val.target.value})}} />
      </FormItem>

      <FormItem label="Airline email address"
        
      >
        <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} name="air_line_email"  placeholder="air_line_phone here" value={this.state.air_line_email} onChange={(val)=>{this.setState({air_line_email:val.target.value})}} />
      </FormItem>


      <FormItem label="Status"
      >
        <Select 
          value={this.state.status} 
          onChange={(val)=>{this.setState({status:val})}}
          placeholder="Status" >
          <Option value='Pending'>Pending</Option>
          <Option value='Confirmed'>Travel confirmed </Option>
          <Option value='Expired'>Expired</Option>  
          </Select>
      </FormItem>


      <FormItem label="Ticket no"
       
      >
        <Input name="ticketno"  placeholder="ticketno here" value={this.state.ticketno} onChange={(val)=>{this.setState({ticketno:val.target.value})}} />
      </FormItem>

      <FormItem label="Check in time"
          
      >
        <Input name="check_in_time"  placeholder="check_in_time here" value={this.state.check_in_time} onChange={(val)=>{this.setState({check_in_time:val.target.value})}} />
      </FormItem>

      <FormItem label="Pickup">
        <Input name="pick_up"  placeholder="pick_up here" value={this.state.pick_up} onChange={(val)=>{this.setState({pick_up:val.target.value})}} />
      </FormItem>

      <FormItem label="Destination">
        <Input name="destination"  placeholder="destination here" value={this.state.destination} onChange={(val)=>{this.setState({destination:val.target.value})}} />
      </FormItem>

      <FormItem label="More details"
          
      >
        <Input name="more_details"  placeholder="more_details here" value={this.state.more_details} onChange={(val)=>{this.setState({more_details:val.target.value})}} />
      </FormItem>


      <FormItem label="Attachment" style={{marginRight:3}}>
        <Input type="file" accept=".pdf" placeholder="file attachment"  
        onChange={async(e) =>{
          this.setState({ attachment: e.target.files[0]});
        } }/>
      </FormItem> 


      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default CandidateTicketEditForm;



