import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''


class IncomePaymentForm extends React.Component {

  state = {
    productName:'',  
    unit:'',
    unitprice:'',
    productDescription:'',
    productSerial:'',
    productImage:null,   
    paymentmode:'',
    date:'',
    datarequested:false,

    companyaccounts: [],
    debitaccount:''
  }

  componentDidMount(){

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  

  //submit button pressed
  onFinish=(event) =>{
    this.setState({datarequested:true})

    const incomeid=this.props.incomeid;

    let form_data = new FormData();
    form_data.append('incomeid', incomeid);
    form_data.append('date', this.state.date);
    form_data.append('paymentmode', event.paymentmode);
    form_data.append('amount', event.amount);
    form_data.append('comments', event.comments);
    form_data.append('phonenumberfrom', event.phonenumberfrom?event.phonenumberfrom:'');
    form_data.append('phonenumberto', event.phonenumberto?event.phonenumberto:'');
    form_data.append('accountto',event.accountto?event.accountto:"");
    form_data.append('clientbank',event.clientbank?event.clientbank:"");
    form_data.append('transreference', event.transreference?event.transreference:"");
    form_data.append('user', localStorage.getItem('username'));
    form_data.append('chequeno',event.chequeno?event.chequeno:"");
    form_data.append('debitaccount', this.state.debitaccount);

    ///make a post request now
      axios.post(serverconfig.backendserverurl+'/customqueries/createincomepayment', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>        this.setState({datarequested:false})
,    window.location.reload(false),message.info('Successfully made payment')
    )
    .catch(error => console.log(error))

    }

    handlepaymentmodeChange= (e) => {
       this.setState({paymentmode:e})
    }

    handledatechange= (date, dateString) => this.setState({ date: dateString});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Card>
  
          <Form
                name="productform"
                onFinish={(event) => this.onFinish(event)}
            >
  
              <FormItem 
                  label="Date"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: 'Please select date',
                    },
                  ]}
              >
                <DatePicker placeholder="date" format={dateFormat} onChange={this.handledatechange} />
              </FormItem>
  
              <FormItem 
                    label="Payment Mode"
                    name="paymentmode"
                    rules={[
                      {
                        required: true,
                        message: 'Please select payment mode',
                      },
                    ]}
                    >
                        <Select 
                        value={this.state.paymentmode} 
                        onChange={this.handlepaymentmodeChange}
                        placeholder="Payment Mode" >
                              <Option value='cash'>Cash</Option>
                              <Option value='mobilemoney'>Mobile Money</Option>
                              <Option value='cheque'>Cheque</Option>
                              <Option value='electronicfundtransfer'>Electronic Fund Transfer</Option>                         
                        </Select>
              </FormItem>
  
              <FormItem label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: 'Please input amount',
                  },
                ]}
                
                >
                  <InputNumber
                    style={{ width: 150 }} 
                    defaultValue={0.0}
                    formatter={value => `x ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\x\s?|(,*)/g, '')}
                  />    
                              
                </FormItem>
                
                {
                  this.state.paymentmode=="mobilemoney"?
  
                  <div>
                  <FormItem 
                  label="Phone From"
                  name="phonenumberfrom"
                  rules={[
                    {
                      required: false,
                      message: 'Please input Phone',
                    },
                  ]}
                  
                  >
                        <PhoneInput
                              countrySelectProps={{ unicodeFlags: true }}
                              defaultCountry="UG"
                              placeholder="Enter phone"
                              />
                  </FormItem> 
  
                  <FormItem 
                  label="Phone To"
                  name="phonenumberto"
                  rules={[
                    {
                      required: false,
                      message: 'Please input Phone',
                    },
                  ]}
                  
                  >
                        <PhoneInput
                              countrySelectProps={{ unicodeFlags: true }}
                              defaultCountry="UG"
                              placeholder="Enter phone"
                              />
                  </FormItem> 
                  </div>
                  :
                  <br></br>
                }
  
                  {
                  this.state.paymentmode=="electronicfundtransfer"               
                  ?
                  <div>
  
                <FormItem label="Account To"
                  name="accountto"
                  rules={[
                    {
                      required: false,
                      message: 'Please input account',
                    },
                  ]}
                
                >
                  <Input placeholder="Account To"  />
                </FormItem>
            
                <FormItem label="Client Bank"
                  name="clientbank"
                  rules={[
                    {
                      required: false,
                      message: 'Please input bank',
                    },
                  ]}
                
                >
                  <Input placeholder="Client Bank"  />
                </FormItem>
            
            
                <FormItem label="Transaction Reference"
                  name="transreference"
                  rules={[
                    {
                      required: false,
                      message: 'Please input reference',
                    },
                  ]}
                
                >
                  <Input placeholder="Reference"  />
                </FormItem>
  
                  </div>
  
                  :
                  <br></br>
  
                }
  
  
                {
                  this.state.paymentmode=="cheque"                
                  ?
                  <div>
  
                <FormItem label="Cheque Number"
                  name="chequeno"
                  rules={[
                    {
                      required: false,
                      message: 'Please input cheque number',
                    },
                  ]}
                
                >
                  <Input placeholder="Cheque Number"  />
                </FormItem>
          
                  </div>
  
                  :
                  <br></br>
  
                }
  
  
              <FormItem label="Comments"
              name="comments"
              rules={[
                {
                  required: true,
                  message: 'Please input comments',
                },
              ]}
              
              >
                  <TextArea
                    placeholder="Comment here"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
              </FormItem>
  

              <FormItem label={<h4 style={{fontWeight:'bold'}}>Receiving AC (Credit A/C).</h4>}
                name="debitaccount"
                rules={[
                  {
                    required: true,
                    message: 'Please select account',
                  },
                ]}
              >
                <Select 
                placeholder="Account" 
                style={{  }} 
                value={this.state.debitaccount}
                onChange={(val)=>{this.setState({debitaccount:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                    
                >
                  {this.state.companyaccounts.map(
                    (accnt)=>(
                      <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                    ))}
                </Select>
              </FormItem>
          
              <FormItem>
                <Button  type="primary" htmlType="submit">Make Payment</Button>
              </FormItem>
              </Form>
          </Card>
  
   
        </div>
      );

    }

    
  }

}


export default IncomePaymentForm;



