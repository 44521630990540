export const backendserverurl='https://12-be-l4bo-3xpo.pitech.co.ug'

//https://1-be-pifs01-mta-app1.pitech.co.ug
//127.0.0.1:8000
//138.197.8.179
//http://138.197.8.179:10225/
//104.131.54.129:10225

//http://client1.labour.local/
//https://12-be-l4bo-3xpo.pitech.co.ug --demo

//https://leeyerecruiters-8812328bk-01.pitech.co.ug/
//


