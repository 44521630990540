import React from 'react';
import { Form, Input, Button,Spin,Image } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''


class RecruitmentAgencyDetails extends React.Component {

  state = {
    companynames:'' ,  
    location_street:'',
    telephone:'',
    date_ofregistration:'',
    company_regsitration_no:'',
    Photo:null,
    Photo_url:'',
    datarequested:false,
    email_address:'',
    location_city:''

  }

  componentDidMount(){
    const agencyID=this.props.match.params.agencyID;

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/recruitment_agencies/${agencyID}`)
    .then(res => {  
        this.setState({
            idtype: res.data

        })

        this.setState({companynames:res.data.companynames})
        this.setState({location_street:res.data.location_street})
        this.setState({location_city:res.data.location_city})
        this.setState({telephone:res.data.telephone})
        this.setState({company_regsitration_no:res.data.company_regsitration_no})

        this.setState({date_ofregistration:res.data.date_ofregistration})
        this.setState({email_address:res.data.email_address})

        this.setState({datarequested:false})

    })



  }




  //submit button pressed
  handleFormSubmit=(event) =>{
    const agencyID=this.props.match.params.agencyID;

    this.setState({datarequested:true})
    
    let form_data = new FormData();
    form_data.append('companynames', this.state.companynames);
    form_data.append('location_street', this.state.location_street);
    form_data.append('location_city', this.state.location_city);
    form_data.append('telephone', this.state.telephone);
    form_data.append('company_regsitration_no', this.state.company_regsitration_no);
    form_data.append('email_address', this.state.email_address);

    form_data.append('date_ofregistration', moment().format(dateFormat).toString());

    axios.put(serverconfig.backendserverurl+`/api/recruitment_agencies/${agencyID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>        
       </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Company names"
    
      >
        <Input name="companynames"  placeholder="Put names here." value={this.state.companynames} onChange={(val)=>{this.setState({companynames:val.target.value})}} />
      </FormItem>

      <FormItem label="Company location_street"
      >
        <Input name="location_street"  placeholder="Put location_street here." value={this.state.location_street} onChange={(val)=>{this.setState({location_street:val.target.value})}} />
      </FormItem>

      <FormItem label="Company location_city"
      >
        <Input name="location_city"  placeholder="Put location_city here." value={this.state.location_city} onChange={(val)=>{this.setState({location_city:val.target.value})}} />
      </FormItem>

      <FormItem label="Telephone"
       
      >
        <Input name="telephone"  placeholder="Put telephone here." value={this.state.telephone} onChange={(val)=>{this.setState({telephone:val.target.value})}} maxLength={10} />
      </FormItem>

      <FormItem label="company regsitration_no"
      
      >
        <Input name="company_regsitration_no"  placeholder="Put company_regsitration_no here." value={this.state.company_regsitration_no} onChange={(val)=>{this.setState({company_regsitration_no:val.target.value})}} />
      </FormItem>

      <FormItem label="company email_address"
     
      >
        <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />}    name="email_address"  placeholder="Put email_address here." value={this.state.email_address} onChange={(val)=>{this.setState({email_address:val.target.value})}} />
      </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default RecruitmentAgencyDetails;



