import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined,DollarCircleFilled, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled,CloudSyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../../serverconn'
import * as serversocketconfig from '../../serversocketconfig'

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import * as primarycolor from '../../primarycolor'

import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import BloodtypeSharpIcon from '@mui/icons-material/BloodtypeSharp';
import LocalHospitalSharpIcon from '@mui/icons-material/LocalHospitalSharp';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import OpacityIcon from '@mui/icons-material/Opacity';

import RecruitorForm from '../components/RecruiterForm'
import CandidatePremedicalForm from '../components/CandidatePremedicalForm'
import CandidateGccmedicalForm from '../components/CandidateGccmedicalForm'
import ExpenseForm from '../components/ExpenseForm'
import CandidateTrainingForm from '../components/CandidateTrainingForm'

import CandidateInterpolForm from '../components/CandidateInterpolForm'
import CandidateContractForm from '../components/CandidateContractForm'
import CandidateVisaForm from '../components/CandidateVisaForm'
import CandidateTicketForm from '../components/CandidateTicketForm'
import CordinatorReportForm from '../components/CordinatorReportForm'
import IncomeForm from '../components/IncomeForm'


var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var token= ''


class QuickMenu extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    totalmemberaccounts:0,
    totalfemaleaccounts:0,
    totalmaleaccounts:0,
    totalloansthismonth:0,
    totalmembersavings:0,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    monthlyloanissuances:0,
    monthlyloanapplications:0,
    monthlyloanassessments:0,
    monthlyloanapprovals:0,
    dashboardlocked:false,
    modalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    total_of_agents:0,
    showcandidateform:false,
    showpremedicalform:false,
    showgccmedical:false,
    showexpense:false,
    showtraining:false,

    showinterpol:false,
    showcontract:false,
    showvisa:false,
    showticket:false,
    showcordinator:false,
    showincome:false

  };



  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })


   
   //get the user profile here
   axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
   .then(res => {
       this.setState({
         userprofile:res.data
       })
       //this.setState({datarequested:false})
   })

   axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
   .then(res => {
       this.setState({
         userrights:res.data[0]
       })
       this.setState({datarequested:false})
   })

  }



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ?(
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


render() {

if(this.state.dashboardlocked===true){
  return(
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
    <h3 style={{fontWeight:'bolder',color:'red'}}>NOTE: Your dashboard is currently locked. Contact system adminstrators.Your Licence may be expired or some thing else. Thanks</h3>
    </div>
  )
}else{

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

     
        return(
          <div className="animated fadeIn">  
          <h3 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>QUICK MENU</h3>

          <Row>
            {this.state.userrights.create_candidate?
             <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
             <Tooltip title="Click to Create new candidate" placement="top">
             <Card
                   onClick={()=>{
                     this.setState({modalvisible:true})
                     this.setState({showcandidateform:true})
 
                   }}
                   hoverable
                   style={{ 
                     background:"white",
                     display: 'flex',
                     justifyContent:'center',
                     padding:4,
                     alignItems:'center' }}
                 >
     
                     <div style={{width:'100%',
                       padding:10,
                       alignItems:'center',
                       alignSelf:'center',
                       display:'flex',
                       justifyContent:'center',
                       flexDirection:'column'
                       }}>
                   <UserOutlined style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                   <h4 style={{fontWeight:'bold'}}>ADD CANDIDATE</h4>
                       </div>
                 </Card>
               </Tooltip>
              
             </Col>
            :
            null}
           
           {this.state.userrights.create_premedical?

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to Create new premedical" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showpremedicalform:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <BloodtypeSharpIcon style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD PREMEDICAL</h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>
            :
            null}

          {this.state.userrights.create_gccmedical?

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to Create new gcc medical" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showgccmedical:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <BloodtypeSharpIcon style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD GCC MEDICAL</h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>:
            null}

            {this.state.userrights.create_expenses?
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to Create new expense" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showexpense:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <DollarCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD EXPENSE</h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>:
            null}

            {this.state.userrights.create_trainingschedules?
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to add candidate training" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showtraining:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD TRAINING </h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>:
            null}

          {this.state.userrights.create_interpol?

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to add interpol" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showinterpol:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD INTERPOL </h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>:
            null}

          {this.state.userrights.create_contract?

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to add contract" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showcontract:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD CONTRACT </h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>:
            null}

          {this.state.userrights.create_visas?

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to add Visa" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showvisa:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD VISA </h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>:
            null}

          {this.state.userrights.create_tickets?

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to add ticket" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showticket:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD TICKET </h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>:
            null}

          {this.state.userrights.create_candidatereports?

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to add cordinator report" placement="top">
            <Card
                  onClick={()=>{
                    this.setState({modalvisible:true})
                    this.setState({showcordinator:true})

                  }}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>ADD CORDINATOR REPORT </h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>:
            null}

          {this.state.userrights.create_candidatereports?

          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <Tooltip title="Click to add income" placement="top">
          <Card
                onClick={()=>{
                  this.setState({modalvisible:true})
                  this.setState({showincome:true})

                }}
                hoverable
                style={{ 
                  background:"white",
                  display: 'flex',
                  justifyContent:'center',
                  padding:4,
                  alignItems:'center' }}
              >

                  <div style={{width:'100%',
                    padding:10,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column'
                    }}>
                <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                <h4 style={{fontWeight:'bold'}}>ADD INCOME </h4>
                    </div>
              </Card>
            </Tooltip>
          
          </Col>:
          null}

            </Row>


            <Modal    
              visible={this.state.modalvisible}
              title="Form"
              onCancel={()=>{

                this.setState({modalvisible:false})
                this.setState({showcandidateform:false})
                this.setState({showpremedicalform:false})
                this.setState({showgccmedical:false})
                this.setState({showexpense:false})
                this.setState({showtraining:false})

                this.setState({showinterpol:false})
                this.setState({showcontract:false})
                this.setState({showvisa:false})
                this.setState({showticket:false})
                this.setState({showcordinator:false})
                this.setState({showincome:false})



              }}
              footer={[
                <Button key="back" onClick={()=>{
                  this.setState({modalvisible:false})
                  this.setState({showcandidateform:false})
                  this.setState({showpremedicalform:false})
                  this.setState({showgccmedical:false})
                  this.setState({showexpense:false})
                  this.setState({showtraining:false})

                  this.setState({showinterpol:false})
                  this.setState({showcontract:false})
                  this.setState({showvisa:false})
                  this.setState({showticket:false})
                  this.setState({showcordinator:false})
                  this.setState({showincome:false})


                  }}>
                  Cancel
                </Button>
                ]}
                >
                  {this.state.showcandidateform?
                    <RecruitorForm />
                    :
                    null}

                  {this.state.showpremedicalform?
                    <CandidatePremedicalForm btnText="Create" />
                    :
                    null}

                  {this.state.showgccmedical?
                    <CandidateGccmedicalForm btnText="Create" />
                    :
                    null}

                  {this.state.showexpense?
                    <ExpenseForm btnText="Create" />
                    :
                    null}

                  {this.state.showtraining?
                    <CandidateTrainingForm btnText="Create" />
                    :
                    null}

                  {/* show interpol */}

                {this.state.showinterpol?
                    <CandidateInterpolForm btnText="Create" />
                    :
                    null}

              {this.state.showcontract?
                    <CandidateContractForm btnText="Create" />
                    :
                    null}

              {this.state.showvisa?
                    <CandidateVisaForm btnText="Create" />
                    :
                    null}

              {this.state.showticket?
                    <CandidateTicketForm btnText="Create" />
                    :
                    null}

              {this.state.showcordinator?
                    <CordinatorReportForm btnText="Create" />
                    :
                    null}

              {this.state.showincome?
                    <IncomeForm btnText="Create" />
                    :
                    null}

            </Modal>
          </div>
        )
      

    }
  
  }

  }
}

export default QuickMenu; 
