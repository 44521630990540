import React from 'react';
import { Form, Input, Button,Spin,DatePicker,Select,Card,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import moment from 'moment';

import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
const { TextArea } = Input;

var token= ''
var bizuserid= ''


class CandidateContractModificationForm extends React.Component {

  state = {
    date:'' ,  
    candidate:'',
    end_date:'',
    start_date:'',
    status:'Pending',
    contract_no:'',
    attachment:null,
    datarequested:true,
    recruits:[],
    selected_recruit:'',
    doctor_contact:'',
    employer_name:'',
    recruitment_agencies: [],
    recruitment_agency:'',
    employer_national_id:'',
    employer_address_street:'',
    employer_address_city:'',
    employer_mobile_contact:'',
    employer_mobile_contact_two:'',
    issuance_date:'',
    candidate_contracts: [],
    contract:'',
    action:'',
    reasonoftermination:'',
    message:'',
    datasubmittedsuccessfully:false,
    userrights:{},

  }


  componentDidMount(){
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/recruits/`)
    .then(res => {
        this.setState({
          recruits:res.data
        })

        //this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/recruitment_agencies`)
    .then(res => {
        this.setState({
            recruitment_agencies:res.data
        })
       this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

   
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('start_date', this.state.start_date);
    form_data.append('end_date', this.state.end_date);
    form_data.append('issuance_date', this.state.issuance_date);

    form_data.append('employer_name', this.state.employer_name);
    form_data.append('employer_national_id', this.state.employer_national_id);
    form_data.append('employer_address_street', this.state.employer_address_street);
    form_data.append('employer_address_city', this.state.employer_address_city);

    form_data.append('employer_mobile_contact', this.state.employer_mobile_contact);
    form_data.append('employer_mobile_contact_two', this.state.employer_mobile_contact_two);

    form_data.append('reasonoftermination', this.state.reasonoftermination);
    form_data.append('contractID', this.state.contract);
    form_data.append('action', this.state.action);

    
    axios.post(serverconfig.backendserverurl+'/customqueries/update_contract_manual', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res => {
      this.setState({message:res.data.message})
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})

    }).catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>   
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title={this.state.message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{


      return (
        <div>
        {
         this.state.userrights.create_contract===true?

       <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

      <h3>Contract modifications</h3>

        <FormItem label="Select Candidate">
          <Select 
          placeholder="Candidate" 
          style={{  }} 
          value={this.state.candidate}
          onChange={(val)=>{
            this.setState({candidate:val})
          
            //load contracts
            axios.get(serverconfig.backendserverurl+`/api/candidate_contracts/?candidate=${val}`)
            .then(res => {
                this.setState({
                    candidate_contracts:res.data
                })
            })
          
          
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.recruits.map(
              (accnt)=>(
                <Option value={accnt.id}>{accnt.FullName} | {accnt.recruit_number}</Option>
              ))}
          </Select>
        </FormItem>


        <FormItem label="Select Contract">
          <Select 
          placeholder="Contract" 
          style={{  }} 
          value={this.state.contract}
          onChange={(val)=>{
            this.setState({contract:val})

            axios.get(`${serverconfig.backendserverurl}/api/candidate_contracts/${val}`)
            .then(res => {  
              
                this.setState({start_date:res.data.start_date})
                this.setState({end_date:res.data.end_date})
                this.setState({candidate:res.data.candidate})
                this.setState({recruitment_agency:res.data.recruitment_agency})
                this.setState({contract_no:res.data.contract_no})

                this.setState({employer_name:res.data.employer_name})
                this.setState({employer_national_id:res.data.employer_national_id})

                this.setState({employer_address_street:res.data.employer_address_street})
                this.setState({employer_address_city:res.data.employer_address_city})
                this.setState({employer_mobile_contact:res.data.employer_mobile_contact})
                this.setState({employer_mobile_contact_two:res.data.employer_mobile_contact_two})
                this.setState({status:res.data.status})
                this.setState({issuance_date:res.data.issuance_date})


            })
          
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.candidate_contracts.map(
              (accnt)=>(
                <Option value={accnt.id}>{accnt.contract_no} | {accnt.start_date} - {accnt.end_date}</Option>
              ))}
          </Select>
        </FormItem>

        <FormItem label="Action">
          <Select 
          placeholder="Action" 
          style={{  }} 
          value={this.state.action}
          onChange={(val)=>{
            this.setState({action:val})
          
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
           <Option value={'amend'}>Amend</Option>
           <Option value={'terminate'}>Terminate</Option>
           <Option value={"changeemployer"}>Change employer</Option>
          </Select>
        </FormItem>

          {
            this.state.action==="amend"?
            <div style={{borderRadius: '10px',border: `2px solid black`,padding:10,margin:10}}>
            <FormItem label={"Issuance date ("+this.state.issuance_date+")"}>
                <DatePicker  onChange={(date, dateString) => this.setState({issuance_date: dateString})} format={dateFormat} />
            </FormItem>
            

            <FormItem label={"Start date ("+this.state.start_date+")"}
            >
                <DatePicker  onChange={(date, dateString) => this.setState({start_date: dateString})} format={dateFormat} />
            </FormItem>

            <FormItem label={"End date ("+this.state.end_date+")"}
            >
                <DatePicker onChange={(date, dateString) => this.setState({end_date: dateString})} format={dateFormat} />
            </FormItem>
  
            </div>
            :
            null
          }

        {
        this.state.action==="terminate"?
        <div style={{borderRadius: '10px',border: `2px solid black`,padding:10,margin:10}}>

        <FormItem label="Reason of termination"
          name="reasonoftermination"
          rules={[
            {
              required: false,
              message: 'Reason of termination',
            },
          ]}    
        >
          <TextArea
            placeholder="Reason"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.reasonoftermination}
            onChange={(val)=>{this.setState({reasonoftermination:val.target.value})}}
          />
    
        </FormItem>
        </div>
           :
           null
         }


      {
            this.state.action==="changeemployer"?
            <div style={{borderRadius: '10px',border: `2px solid black`,padding:10,margin:10}}>
           <FormItem label="Employer name"
       
      >
        <Input name="employer_name"  placeholder="Employer name" value={this.state.employer_name} onChange={(val)=>{this.setState({employer_name:val.target.value})}} />
      </FormItem>

      <FormItem label="Employer national id"
      
      >
        <Input name="employer_national_id"  placeholder="Employer national id" value={this.state.employer_national_id} onChange={(val)=>{this.setState({employer_national_id:val.target.value})}} />
      </FormItem>

      <FormItem label="Employer address street name"
          
      >
        <Input name="employer_address_street"  placeholder="Employer address street" value={this.state.employer_address_street} onChange={(val)=>{this.setState({employer_address_street:val.target.value})}} />
      </FormItem>

      <FormItem label="Employer address city"
        
      >
        <Input name="employer_address_city"  placeholder="Employer address city" value={this.state.employer_address_city} onChange={(val)=>{this.setState({employer_address_city:val.target.value})}} />
      </FormItem>


      <FormItem label="Employer contact one"
       
      >
        <Input name="employer_mobile_contact"  placeholder="Employer contact one" value={this.state.employer_mobile_contact} onChange={(val)=>{this.setState({employer_mobile_contact:val.target.value})}} />
      </FormItem>

      <FormItem label="Employer contact two"
         
      >
        <Input name="employer_mobile_contact_two"  placeholder="Employer contact two" value={this.state.employer_mobile_contact_two} onChange={(val)=>{this.setState({employer_mobile_contact_two:val.target.value})}} />
      </FormItem>
  
        </div>
        :
        null
      }
    

      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>

      </Form>
              :
              null}
        </div>
      );

    }

    }
    
  }

}


export default CandidateContractModificationForm;



