import React from 'react';
import { Form, Input,Popover,Tooltip, Button,Select,DatePicker,Spin,Image, Card,Divider,Switch,Result, message,Modal,Popconfirm,Table } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined,PrinterOutlined, LoadingOutlined,MailOutlined,PlusCircleFilled,CloseCircleFilled,CheckCircleFilled,WarningFilled,DeleteOutlined } from '@ant-design/icons';
import uuid from 'react-uuid'
import Resizer from "react-image-file-resizer";
import * as primarycolor from '../../primarycolor'
//import uuid from 'react-uuid'
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;

var token= ''
var instance_id= ''
var bizuserid= ''
var username= ''


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })
  }

  render() {
    return (
     <div style={{padding:5}} id="print-content">

      <style>
        {`@media print {
  /* Set the width of the printed content to 80mm */
  @page {
    size: 80mm;
    margin: 0;
  }

  /* Remove any unnecessary elements from the print layout */
  body {
    margin: 0;
    padding: 0;
  }

  /* Adjust the font size for better readability */
  p, span, h1, h2, h3, h4, h5, h6 {
    font-size: 16px;
  }

  /* Adjust the line-height for better readability */
  p, span, h1, h2, h3, h4, h5, h6 {
    line-height: 1.5;
  }

  /* Add padding to the content to avoid clipping */
  #print-content {
    padding: 10px;
  }

  /* Set the font family to a monospace font for better alignment */
  body, p, span, h1, h2, h3, h4, h5, h6 {
    font-family: 'Courier New', Courier, monospace;
  }

  /* Adjust the margins to avoid clipping */
  @media print {
    html, body {
      height: 100%;
    }

    #print-content {
      min-height: 100%;
    }
  }
}`}
      </style>
          
   <h4 style={{color:'black',fontWeight:'bolder'}}>********************************</h4>

<h4 style={{color:'black',fontWeight:'bolder'}}>  {this.state.companyprofile.system_name}</h4>
<h4 style={{color:'black',fontWeight:'bolder'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}

</h4>               
<h4 style={{color:'black',fontWeight:'bolder'}}>  Email: {this.state.companyprofile.company_email}</h4>
<h4 style={{color:'black',fontWeight:'bolder'}}>  SLIP/RECEIPT NO: {this.props.journalno}</h4>
<h4 style={{color:'black',fontWeight:'bolder'}}>  DATE: {this.props.date}</h4>

<h3 style={{color:'black',fontWeight:'bolder'}}>  PAYMENT RECEIPT</h3>

<h4 style={{color:'black',fontWeight:'bolder'}}>********************************</h4>

{
  this.props.receiptitems.map((item)=>{
    return(<p style={{color:'black',fontWeight:'bolder'}}> {item.itemnumber}  {item.income_category}   <CurrencyFormat value={item.income_amount} displayType={'text'} thousandSeparator={true} /> </p>)
  })
}

<h3 style={{color:'black',fontWeight:'bolder'}}>  TOTAL PAID: <CurrencyFormat value={this.props.receipttotal} displayType={'text'} thousandSeparator={true} /></h3>
<h3 style={{color:'black',fontWeight:'bolder'}}>  Paid by: {this.props.paidby}</h3>

<h4 style={{color:'black',fontWeight:'bolder'}}>********************************</h4>
<h4 style={{color:'black',fontWeight:'bolder'}}>Supported by pitech.co.ug</h4>

</div>
);
}
}


class RecruitorForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    first_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_of_birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Signature:null,
    Thumbprint:null,

    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    signaturefile:null,
    thumbprintfile:null,
    marital_status:'',
    own_residence:'',
    recommender:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    instance_idpackageobj:{},
    members:[],
    companyprofile:{},

    occupation:'',
    postaladdress:'',
    homecelllocaltion:'',
    cell:'',
    cellleader:'',
    nameofemployer_business:'',

    addressofemployer_business:'',
    refree_institutionalmember_name:'',
    refree_institutionalmember_contact:'',
    refree_cellleader_name:'',
    refree_cellleader_contact:'',
    next_of_kin_contact:'',

    child_account_school:'',
    child_account_school_class:'',

    membertype:'senior',
    pysical_form:null,
    saving_period:'',

    id_expirydate:'',
    ugdistricts:[],
    cellleader_contact:'',
  
    accounttypeobject:{},
    idtypeobject:{},

    otheraccountslist:[],
    other_accounts_model_visible:false,

    other_account_type:'',
    other_account_bank_or_telecom:'',
    other_account_name:'',
    other_account_number_contact:'',
    milk_selling_price:0,

    collectionhubs: [],
    selected_hub:'',
    second_name:'',
    nationalid_attachmentfile:'',
    nationalid_attachment:null,
    passport_attachment:null,
    passport_attachmentfile:'',
    skills_modalvisible:false,
    skills_set:[],

    skills: [],
    selected_skill:false,
    applicationform_attachment:null,
    consentform_attachment:null,

    personalbrokers: [],
    companybrokers: [],
    company_broker:'',
    personal_broker:'',
    passport_details:'',
    height:0,
    religion:'',
    next_of_kin_name:'',
    next_of_kin_contact:'',
    next_of_kin_relationship:'',
    education_level:'',
    previous_employmentdetails:'',
    languanges:'',
    parent_details:'',
    no_of_children:0,
    weight:0,
    accounts: [],
    coordinator:'',

    passport_no:'',
    passport_date_ofissuance:'',
    passport_dateofexpiry:'',
    passport_placeofissuance:'',

    returned_candidateId:'',
    attached_incomes: [],
    incomecategories:[],
    incomeattachemnt_visible:false,
    income_category:'',
    income_amount:0,

    companyaccounts: [],
    debitaccount:'',
    journalno:'',
    itemnumber:0,
    userrights:{},

  }

  componentDidMount(){

    if(localStorage.getItem("instance_id")){
       instance_id= localStorage.getItem("instance_id") 
    }else{
       instance_id= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
      username= ''
    }


    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    //get the instance_id package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })

    })


    axios.get(serverconfig.backendserverurl+`/api/skills/`)
    .then(res => {
      this.setState({
        skills:res.data
      })
   //   this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/personalbrokers`)
    .then(res => {
        this.setState({
            personalbrokers:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/companybrokers`)
    .then(res => {
        this.setState({
          companybrokers:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/accounts/?account_type=${'cordinator'}`)
    .then(res => {
      this.setState({
        accounts:res.data
      })


    })


    axios.get(serverconfig.backendserverurl+`/api/incomecategories/`)
    .then(res => {
        this.setState({
          incomecategories:res.data
        })

        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
       // this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

  
}

  //imgae resizer
  resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      400,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });


  //convert back to file
  dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };


//calculate total of payments
calculateTotalOfPayments=()=>{
 var total =0

 this.state.attached_incomes.map((item)=>{
  total+=Number(item.income_amount)
 })

  return total;
}




  //submit button pressed
  handleFormSubmit=(event) =>{

        if (this.state.RegisteredPhoneNumber1===''){
          message.info("Phone Number missing")
        }else{

          //first submission
          let form_data__1 = new FormData();
          form_data__1.append('action', 'get_number');

          axios.post(serverconfig.backendserverurl+'/customqueries/getautogenerated_recruiterno', form_data__1, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res => {
                  var account_no=res.data.account_no


                  //check if account noumber is gotten
                  this.setState({datarequested:true})
          
                  let form_data = new FormData();
                  
                  form_data.append('date_of_registration', this.state.date);
                  form_data.append('first_name', this.state.first_name);
                  form_data.append('second_name', this.state.second_name);
                  form_data.append('national_id_no', this.state.ID_number);
                  form_data.append('AreaOfResidence', this.state.Area_Of_Residence);
                  form_data.append('District', this.state.District);
                  form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
                    
                  form_data.append('gender', this.state.gender);
                  form_data.append('height', this.state.height);
                  form_data.append('marital_status', this.state.marital_status);
                  form_data.append('religion', this.state.religion);
                  form_data.append('date_of_birth', this.state.date_of_birth);

                  form_data.append('next_of_kin_name', this.state.next_of_kin_name);
                  form_data.append('next_of_kin_relationship', this.state.next_of_kin_relationship);
                  form_data.append('next_of_kin_contact', this.state.next_of_kin_contact);

                  form_data.append('education_level', this.state.education_level);
                  form_data.append('previous_employmentdetails', this.state.previous_employmentdetails);

                  form_data.append('languanges', this.state.languanges);
                  form_data.append('parent_details', this.state.parent_details);
                  form_data.append('no_of_children', this.state.no_of_children);

                  form_data.append('previous_employmentdetails', this.state.previous_employmentdetails);

                  form_data.append('created_by', username);
                  form_data.append('passport_details', 
                  JSON.stringify({
                    "passport_no":this.state.passport_no,
                    "passport_date_ofissuance":this.state.passport_date_ofissuance,
                    "passport_dateofexpiry":this.state.passport_dateofexpiry,
                    "passport_placeofissuance":this.state.passport_placeofissuance,
                  }));

                  form_data.append('skills_and_competence', JSON.stringify(this.state.skills_set));

                  if (this.state.personal_broker==="" || this.state.personal_broker==="null" || this.state.personal_broker===null){
                    console.log("no pers broker")

                  }else{
                    form_data.append('persona_broker', this.state.personal_broker);
                  }
                  

                  if (this.state.company_broker==="" || this.state.company_broker==="null" || this.state.company_broker===null){
                    console.log("no comp broker")
                  }else{
                    form_data.append('company_broker', this.state.company_broker);
                  }


                  form_data.append('weight', this.state.weight);
                  form_data.append('recruit_number', account_no);

                  if (this.state.coordinator==="" || this.state.coordinator==="null" || this.state.coordinator===null){
                    console.log("no coordinator")
                  }else{
                    form_data.append('coordinator', this.state.coordinator);
                  }

                  form_data.append('status', "Pending");

                  axios.post(serverconfig.backendserverurl+'/api/recruits/', form_data, {
                    headers: {
                      'content-type': 'multipart/form-data'
                    }
                  })
                  .then(res =>{
                      console.log(res.data)

                      var cand_id=res.data.new_object_id


                    //now update the object reource file

                      let form_data2 = new FormData();
                      form_data2.append('pri_id', res.data.new_object_id);
                      form_data2.append('update_type', "candidate");

                      this.state.Photo==null?
                      console.log("No profile file")
                      :
                      form_data2.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
                  
                      this.state.nationalid_attachment==null?
                      console.log("No id file")
                      :
                      form_data2.append('nationalid_attachment', this.state.nationalid_attachment, this.state.nationalid_attachment?this.state.nationalid_attachment.name:"");
            
                      this.state.passport_attachment==null?
                      console.log("No pass file")
                      :
                      form_data2.append('passport_attachment', this.state.passport_attachment, this.state.passport_attachment?this.state.passport_attachment.name:"");
            
                      this.state.applicationform_attachment==null?
                      console.log("No pass file")
                      :
                      form_data2.append('applicationform_attachment', this.state.applicationform_attachment, this.state.applicationform_attachment?this.state.applicationform_attachment.name:"");
            
                      this.state.consentform_attachment==null?
                      console.log("No pass file")
                      :
                      form_data2.append('consentform_attachment', this.state.consentform_attachment, this.state.consentform_attachment?this.state.consentform_attachment.name:"");
            
                      axios.post(serverconfig.backendserverurl+'/customqueries/late_fileupload', form_data2, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      })
                      .then(res => {
                          //methiods to show completed
                          this.setState({datarequested:false})
                          this.setState({datasubmittedsuccessfully:true})
                      }).catch(error => console.log(error))
                      //end the object file upload



                      //create associaated incomes to the candidate
                      let form_data__3 = new FormData();
                      form_data__3.append('attached_incomes', JSON.stringify(this.state.attached_incomes));
                      form_data__3.append('cand_id', cand_id);
                      form_data__3.append('debitaccount', this.state.debitaccount);
                      form_data__3.append('date', this.state.date);
                      form_data__3.append('username', username);

                      axios.post(serverconfig.backendserverurl+'/customqueries/batchcreatecandidate_incomes', form_data__3, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                      .then(res => {
                        console.log("incomes uploaded")
                        this.setState({journalno:res.data.receipt_ref})
                      
                      })
                      .catch(error => console.log(error))
                      //end of incomes upload
                      })
                      .catch(error => console.log(error))


           //now makem final call here
           let form_data__2 = new FormData();
           form_data__2.append('action', 'update_number');
 
           axios.post(serverconfig.backendserverurl+'/customqueries/getautogenerated_recruiterno', form_data__2, {
             headers: {
               'content-type': 'multipart/form-data'
             }
            })
          .then(res => {
            console.log("fanally updated")
         
          })
          .catch(error => console.log(error))
        //end of fisrt form


      
      })

}

}




  handlePhotoChange= async(e) =>{
    
    //this.setState({ Photo: e.target.files[0]});

    if(e.target.files[0]){
      this.setState({
        photofile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Photo: newFile});
    console.log(newFile);

  } 

 


  render(){



    const items_columns = [
      {
        title: 'Item',
        dataIndex: 'income_category',
        key: 'key',
      },
    
      {
        title: 'Amount',
        dataIndex: 'income_amount',
        key: 'key',
      },
      {
        title: 'Action',
        dataIndex: 'key',
        key: 'key',
        render: (text,record) =>
        <p>
      
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => {
            this.setState({ attached_incomes:  [...this.state.attached_incomes.filter(todo => todo.key!==text)]});
        }}>
          <DeleteOutlined style={{color:'red'}} />
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];




    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>

            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
              receipttotal={this.calculateTotalOfPayments()} 
              date={this.state.date}
              receiptitems={this.state.attached_incomes}
              journalno={this.state.journalno}
              paidby={this.state.first_name+" "+this.state.second_name}
              ref={el => (this.componentRef = el)} /> 
            </div>


            <Result
            status="success"
            title="Successfully created new candidate."
            extra={[
              <a>
              <ReactToPrint
                trigger={() =>                     
                  <div style={{}}>
                    <Tooltip title="Click to print" placement="top">
                    <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                    <h6 >Print Receipt</h6>
                    </Tooltip>
                  </div>                          
                    }
                    content={() => this.componentRef}
                  />  
                  </a>,
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,

            ]}
            />
            </Card>
        );

      }else{


            return (
              <div>
                {
                 this.state.userrights.create_candidate===true?
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >

                 <FormItem label="Date of registration"
                      name='date'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select date',
                        },
                      ]}
                    >
                        <DatePicker value={moment()} onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                    </FormItem>

                  <FormItem label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input first name',
                        },
                      ]}          
                  >
                    <Input  placeholder="Put a name here." value={this.state.first_name} onChange={(val)=>{this.setState({first_name:val.target.value})}}       />
                  </FormItem>
        
       
                  <FormItem label="Second Name"
                      name="second_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input second name',
                        },
        
                      ]}           
                  >
                    <Input  placeholder="Put a name here." value={this.state.second_name} onChange={(val)=>{this.setState({second_name:val.target.value})}} />
                  </FormItem>
      
                  <FormItem label="Gender"
                     name="gender"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="Gender" 
                      style={{ width: 120 }} 
                      value={this.state.gender} 
                      onChange={(val)=>{this.setState({gender:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      </Select>
                  </FormItem>

                  <FormItem label= { <h6 style={{display:'flex'}} >Height</h6>}                       
                  >
                    <Input placeholder="Height." value={this.state.height} onChange={(val)=>{this.setState({height:val.target.value})}} type='number' />

                 </FormItem>

                 <FormItem label= { <h6 style={{display:'flex'}} >Weight</h6>}                       
                  >
                    <Input placeholder="Weight." value={this.state.weight} onChange={(val)=>{this.setState({weight:val.target.value})}} type='number' />

                 </FormItem>
      
                  
                  <FormItem label="National ID"
                  name="ID_number"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter ID Number',
                    },
                  ]}                   
                  
                  >
                  <Input  placeholder="ID Number." value={this.state.national_id_no} onChange={(val)=>{this.setState({national_id_no:val.target.value})}} />
                  </FormItem>

                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="National id attachment" style={{marginRight:3}}>
                    <Input type="file" accept="image/png, image/jpeg" placeholder="ID attachment " 
                      onChange={async(e) =>{
                      //this.setState({ Photo: e.target.files[0]});

                      if(e.target.files[0]){
                        this.setState({
                          nationalid_attachmentfile: URL.createObjectURL(e.target.files[0])
                        })
                      }

                      const file = e.target.files[0];
                      //send to resizer
                      const image = await this.resizeFile(file);
                      const newFile = this.dataURIToBlob(image);
                      this.setState({ nationalid_attachment: newFile});

                    } 
                  }/>
                  </FormItem> 
      
                  {this.state.nationalid_attachment?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.nationalid_attachmentfile}    
                  />
                  :
                  null
                  }
      
                  </div>
              
                      
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Photo" style={{marginRight:3}}>
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Member Photo"  onChange={this.handlePhotoChange}/>
                  </FormItem> 
      
                  {this.state.Photo?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.photofile}    
                  />
                  :
                  null
                  }
      
                  </div>
        
                  <h3>Contact Section</h3>
                 
                  <FormItem label="Residential (Physical) Address"
                      name="areaofresidence"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter residence',
                        },
                      ]}           
                  >
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={(val)=>{this.setState({Area_Of_Residence:val.target.value})}} />
                  </FormItem>
        
                  <FormItem label="District"
                     name="district"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter district of residence',
                       },
                     ]}           
                  >
                    <Select
                     placeholder="Ug District"
                      style={{  }} 
                      value={this.state.District}
                       onChange={(val)=>{this.setState({District:val})}} 
                       
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       
                       >
                      {this.state.ugdistricts.map(
                        (dist)=>(
                          <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                        ))}
                    </Select>
                  </FormItem>
        
                  <FormItem label= { <h6 style={{display:'flex'}} >* Phone number</h6>}                       
                  >
                    <Input placeholder="Phone contact." value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val.target.value})}}  maxLength={10}/>

                 </FormItem> 

                <h3>All passport details</h3>
                 <FormItem label= { <h6 style={{display:'flex'}} >* Passport Number</h6>}>
                    <Input
                      placeholder="passport_no"
                      value={this.state.passport_no}
                      onChange={(val)=>{this.setState({passport_no:val.target.value})}}
                    />
                 </FormItem>

                 <FormItem label= { <h6 style={{display:'flex'}} >* Passport place of issuance</h6>}>
                    <Input
                      placeholder="passport_placeofissuance"
                      value={this.state.passport_placeofissuance}
                      onChange={(val)=>{this.setState({passport_placeofissuance:val.target.value})}}
                    />
                 </FormItem>

                 <FormItem label="Passport date of issuance"
                    >
                        <DatePicker onChange={(date, dateString)=>{this.setState({ passport_date_ofissuance: dateString})}} format={dateFormat} />
                  </FormItem>

                  <FormItem label="Passport expiry date"
                    >
                        <DatePicker onChange={(date, dateString)=>{this.setState({ passport_dateofexpiry: dateString})}} format={dateFormat} />
                  </FormItem>

               
                 <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Passport attachment" style={{marginRight:3}}>
                    <Input type="file" accept="image/png, image/jpeg" placeholder="Passport attachment"  
                    onChange={async(e) =>{

                      //this.setState({ Photo: e.target.files[0]});
                  
                      if(e.target.files[0]){
                        this.setState({
                          passport_attachmentfile: URL.createObjectURL(e.target.files[0])
                        })
                      }
                  
                      const file = e.target.files[0];
                      //send to resizer
                      const image = await this.resizeFile(file);
                      const newFile = this.dataURIToBlob(image);
                      this.setState({ passport_attachment: newFile});
                      console.log(newFile);
                  
                    } }/>
                  </FormItem> 
      
                  {this.state.passport_attachment?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.passport_attachmentfile}    
                  />
                  :
                  null
                  }
      
                  </div>

                  <FormItem label= { <h6 style={{display:'flex'}} >Number of children</h6>}                       
                  >
                    <Input placeholder="No of children." value={this.state.no_of_children} onChange={(val)=>{this.setState({no_of_children:val.target.value})}} type='number' />

                 </FormItem> 

 
                 <FormItem label="Marital status"
                     name="marital_status"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="marital_status" 
                      style={{ width: 120 }} 
                      value={this.state.marital_status} 
                      onChange={(val)=>{this.setState({marital_status:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                      <Option value="married">Married</Option>
                      <Option value="single">Single</Option>
                      </Select>
                  </FormItem>


                  <FormItem label= { <h6 style={{display:'flex'}} >Religion</h6>}                       
                  >
                    <Input placeholder="Religion." value={this.state.religion} onChange={(val)=>{this.setState({religion:val.target.value})}}  />
                 </FormItem> 

                 <FormItem label="Date of birth"
                      name='date'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select date',
                        },
                      ]}
                    >
                        <DatePicker onChange={(date, dateString)=>{this.setState({ date_of_birth: dateString})}} format={dateFormat} />
                  </FormItem>

                  <FormItem label= { <h6 style={{display:'flex'}} >Next of kin name</h6>}                       
                  >
                    <Input placeholder="next_of_kin_name." value={this.state.next_of_kin_name} onChange={(val)=>{this.setState({next_of_kin_name:val.target.value})}}  />
                 </FormItem> 

                 <FormItem label= { <h6 style={{display:'flex'}} >Next of kin relationship</h6>}                       
                  >
                    <Input placeholder="next_of_kin_relationship." value={this.state.next_of_kin_relationship} onChange={(val)=>{this.setState({next_of_kin_relationship:val.target.value})}}  />
                 </FormItem> 

                 <FormItem label= { <h6 style={{display:'flex'}} >Next of kin contact</h6>}                       
                  >
                    <Input placeholder="next_of_kin_contact." value={this.state.next_of_kin_contact} onChange={(val)=>{this.setState({next_of_kin_contact:val.target.value})}} maxLength={10}  />
                 </FormItem> 

                 <FormItem label="Education level"
                     name="education_level"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="education_level" 
                      style={{ width: 120 }} 
                      value={this.state.education_level} 
                      onChange={(val)=>{this.setState({education_level:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                      <Option value="nursery">Nursery</Option>
                      <Option value="primary">primary</Option>
                      <Option value="secondary">Secondary</Option>
                      <Option value="high school">High school</Option>
                      <Option value="tertiary">Tertiary</Option>
                      <Option value="undergraduate">Undergraduate</Option>
                      <Option value="graduate">Graduate and above</Option>
                      </Select>
                  </FormItem>

                  <FormItem label="Previous employmnet details">
                      <TextArea
                            placeholder="previous_employmentdetails."
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            value={this.state.previous_employmentdetails}
                            onChange={(val)=>{this.setState({previous_employmentdetails:val.target.value})}}
                          />
                  </FormItem>

                  <FormItem label="Skills and competences">
                    <div style={{display:'flex',flexDirection:'row'}}>
                    <PlusCircleFilled 
                    style={{fontSize: '35px',margin:2,color:primarycolor.primarycolor}}
                    onClick={()=>{
                      this.setState({skills_modalvisible:true})
                    }}
                    />
                    &nbsp;
                    {
                      this.state.skills_set.length>0?
                      <div style={{display:'flex',flexDirection:'row',margin:2}} >
                        {
                          this.state.skills_set.map((tm)=>{
                            return (<h4>{tm},</h4>)
                          })
                        }

                      </div>
                      :
                      null
                    }

                    </div>
                  </FormItem>



                  <FormItem label="Languages">
                      <TextArea
                            placeholder="languages. (Seperate them with commas)"
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            value={this.state.languanges}
                            onChange={(val)=>{this.setState({languanges:val.target.value})}}
                          />
                  </FormItem>

                  <FormItem label="Parent details">
                      <TextArea
                            placeholder="parent_details"
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            value={this.state.parent_details}
                            onChange={(val)=>{this.setState({parent_details:val.target.value})}}
                          />
                  </FormItem>


                  <FormItem label="Application form attachment" style={{marginRight:3}}>
                    <Input type="file" accept=".pdf" placeholder="Application form attachment"  
                    onChange={async(e) =>{
                      this.setState({ applicationform_attachment: e.target.files[0]});
                  
                    } }/>
                  </FormItem> 

                  <FormItem label="Conscent form attachment" style={{marginRight:3}}>
                    <Input type="file" accept=".pdf" placeholder="Conscent form attachment"  
                    onChange={async(e) =>{
                      this.setState({ consentform_attachment: e.target.files[0]});
                  
                    } }/>
                  </FormItem> 


                  <FormItem label="Broker (Company)"       
                  >
                    <Select
                       placeholder="Broker (Company)"
                      style={{  }} 
                      value={this.state.company_broker}
                       onChange={(val)=>{this.setState({company_broker:val})}} 
                       
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       
                       >
                      {this.state.companybrokers.map(
                        (dist)=>(
                          <Option value={dist.id}>{dist.companynames} | {dist.telephone}</Option>
                        ))}
                    </Select>
                  </FormItem>

                  <FormItem label="Broker (Personal)"       
                  >
                    <Select
                     placeholder="Broker (Personal)"
                      style={{  }} 
                      value={this.state.personal_broker}
                       onChange={(val)=>{this.setState({personal_broker:val})}} 
                       
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       
                       >
                      {this.state.personalbrokers.map(
                        (dist)=>(
                          <Option value={dist.id}>{dist.fullnames} | {dist.telephone}</Option>
                        ))}
                    </Select>
                  </FormItem>

                  <FormItem label="Cordinator"       
                  >
                    <Select
                     placeholder="Cordinator"
                      style={{  }} 
                      value={this.state.coordinator}
                       onChange={(val)=>{this.setState({coordinator:val})}} 
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       >
                      {this.state.accounts.map(
                        (accnt)=>(
                          <Option value={accnt.id}>{accnt.username}</Option>
                        ))}
                    </Select>
                  </FormItem>

                  <h3>Attach payments to candidate</h3>

                  <FormItem 
                   label="Add pay item"     
                  >

                    <PlusCircleFilled 
                    style={{fontSize: '35px',margin:2,color:primarycolor.primarycolor}}
                    onClick={()=>{

                      this.setState({income_category:''})
                      this.setState({income_amount:0})
                      this.setState({incomeattachemnt_visible:true})
                    }}
                    />

                  </FormItem>

                  {
                    this.state.attached_incomes.length>0?
                    <Table 
                    scroll={{ x: 1000 }}
                    columns={items_columns}
                    pagination={{showQuickJumper:true,showSizeChanger:true }}
                    dataSource={this.state.attached_incomes} bordered/>
                    :
                    null
                  }

                  {
                    this.state.attached_incomes.length>0?
                    <h3>Total: {this.calculateTotalOfPayments()}</h3>
                    :
                    null
                  }


                  {
                    this.state.attached_incomes.length>0?
                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Receiving AC (Credit A/C).</h4>}
                    name="debitaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                    <Select 
                    placeholder="Account" 
                    style={{  }} 
                    value={this.state.debitaccount}
                    onChange={(val)=>{this.setState({debitaccount:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}                    
                    >
                      {this.state.companyaccounts.map(
                        (accnt)=>(
                          <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code}</Option>
                        ))}
                    </Select>
                  </FormItem>
                    :
                    null
                  }



                  <FormItem >
                    <Button  type='primary' htmlType="submit">Submit candidate</Button>
                  </FormItem>
      
                </Form>
                 :
                 null}

              <Modal              
              visible={this.state.skills_modalvisible}
              title={"Skills and competence Form"}
              onCancel={()=>{this.setState({skills_modalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({skills_modalvisible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                        this.setState({skills_modalvisible:false})
                    }}>
                  Ok
                </Button>
                ]}
                >
                <div style={{display:'start',flexDirection:'row',justifyContent:'center'}}>
                {
                  this.state.skills.length>0?
                  <div>
                    {
                      this.state.skills.map((item)=>{
                        return (<h3>
                               {item.name} 

                               <PlusCircleFilled 
                                style={{fontSize: '35px',margin:2,color:this.state.skills_set.indexOf(item.name)> -1?'yellow':primarycolor.primarycolor}}
                                onClick={()=>{
                                  if(this.state.skills_set.indexOf(item.name)> -1){
                                    console.log("skill removed")
                                    const newList = this.state.skills_set.filter((trans) => trans!== item.name);
                                    this.setState({skills_set:newList});
                                  }else{
    
                                    this.setState({
                                      skills_set: [...this.state.skills_set, item.name]
                                      });
    
                                      console.log("skill added")
                                  }

                                }}
                              />

                        </h3>)
                      })
                    }
 
                  </div>
                  :
                  null
                }

                </div>

               </Modal> 


               <Modal              
              visible={this.state.incomeattachemnt_visible}
              title={"Pay Item Form"}
              onCancel={()=>{this.setState({incomeattachemnt_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({incomeattachemnt_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                      var obj_ct={
                        "key":uuid(),
                        "income_category":this.state.income_category,
                        "income_amount":this.state.income_amount,
                        "itemnumber":(Number(this.state.itemnumber)+1),
                      }

                      this.setState({
                        attached_incomes: [...this.state.attached_incomes, obj_ct]
                        });

                      this.setState({incomeattachemnt_visible:false})

                      this.setState({itemnumber:(Number(this.state.itemnumber)+1)})

                    }}>
                  Ok
                </Button>
                ]}
                >

                <FormItem label="Income Category">
                      <Select placeholder="Income Category"
                      showSearch
                      optionFilterProp="children"
                       value={this.state.income_category}
                        onChange={(val)=>{this.setState({income_category:val})}} >
                        {this.state.incomecategories.map(
                          (ctgry)=>(
                            <Option value={ctgry.category_name}>{ctgry.category_name}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  <FormItem label= { <h6 style={{display:'flex'}} >Paid amount</h6>}                       
                  >
                    <Input placeholder="Amount." value={this.state.income_amount} onChange={(val)=>{this.setState({income_amount:val.target.value})}} type='number' />

                 </FormItem>

               </Modal> 
              </div>
            );

          }

      }

    }
    
  }



export default RecruitorForm;
