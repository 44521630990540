import React from 'react';
import { Form, Input,message,Button,Select,DatePicker,InputNumber,Modal,Spin,Result } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var username=''
var token= ''
var bizuserid= ''


class ExpenseForm extends React.Component {

  state = {
    staffs:[],
    expensecategories:[],
    date:moment().format(dateFormat).toString(),  
    quantity:0,
    rate:0,
    amounttobepaid:0,
    balance:0,
    description:'',
    expensecategory:'',
    expenseto:'',
    expensetocontact:'',
    debitorphonetwo:'',
    lastexpenseno:'',
    expenseno:'',
    expensetotype:'nonaccountholder',
    recruits:[],
    selected_recruit:'',
    datasubmittedsuccessfully:false,


    ////////////////////////////////
    //payments form variables
    amountpaid:0,
    paymentmode:'',
    amount:'',
    comments:'',
    phonenumberfrom:'',
    phonenumberto:'',
    accountto:'',
    clientbank:'',
    transreference:'',
    chequeno:'',
    datarequested:true,


    companyaccounts: [],
    creditaccount:'',
    selected_broker:'',
    personalbrokers: [],
    userrights:{},
    total_to_be_paid:0

  }
  

  componentDidMount(){
    if(localStorage.getItem("username")){
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }



    axios.get(serverconfig.backendserverurl+`/api/recruits/`)
    .then(res => {
        this.setState({
          recruits:res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/expensecategories/`)
    .then(res => {
        this.setState({
          expensecategories:res.data
        })

    })


    //get last slip number
    let form_data = new FormData();
    axios.post(serverconfig.backendserverurl+'/customqueries/getsaccolastexpenseno', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({lastexpenseno:res.data.lastnumber})
        this.setState({expenseno:Number(res.data.lastnumber)+1})

      //  this.setState({datarequested:false})
    })
    .catch(error => console.log(error))


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        //this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/personalbrokers`)
    .then(res => {
        this.setState({
            personalbrokers:res.data
        })
        this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

}

showPaymentModal=()=>{
  this.setState({ viewpaymentmodal: true });
  
}

closepaymentmodal = () => {
  this.setState({ viewpaymentmodal: false });
 };


  //submit button pressed
  handleFormSubmit=(event) =>{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('expense_category', this.state.expensecategory);
      form_data.append('expenseto_name', this.state.expenseto);
  
      form_data.append('expenseto_type', this.state.expensetotype);
      form_data.append('expenseto_contact', this.state.expensetocontact);
      form_data.append('rate', this.state.total_to_be_paid);
      form_data.append('quantity', 1);
      form_data.append('amount_paid', this.state.amountpaid);
      form_data.append('total_to_be_paid', this.state.total_to_be_paid);
      form_data.append('details', this.state.description);
      form_data.append('user', username);
      form_data.append('expenseno', this.state.expenseno);
      form_data.append('selected_recruit', this.state.selected_recruit);
      form_data.append('selected_broker', this.state.selected_broker);

      
      //payment variables here
      form_data.append('comments', this.state.comments);
      form_data.append('phonenumberfrom', this.state.phonenumberfrom);
      form_data.append('phonenumberto', this.state.phonenumberto);
      form_data.append('accountto',this.state.accountto);
      form_data.append('clientbank',this.state.clientbank);
      form_data.append('transreference', this.state.transreference);
      form_data.append('chequeno',this.state.chequeno);
      form_data.append('creditaccount', this.state.creditaccount);
      form_data.append('paymentmode', this.state.paymentmode);
      
      axios.post(serverconfig.backendserverurl+'/customqueries/createnormalexpense', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
      this.setState({datarequested:false})
      message.info('Success fully added Expense')
      this.setState({datasubmittedsuccessfully:true})
    }        
  
    )
    .catch(error => console.log(error))
    

  }

  handledatechange= (date, dateString) => this.setState({ date: dateString});
  handlequantityChange= (e) => this.setState({ quantity: e.target.value});
  handlerateChange= (e) => this.setState({ rate: e.target.value});

  handleamountpaidChange= (e) => this.setState({ amountpaid: e.target.value});
  handlebalanceChange= (e) => this.setState({ balance: e.target.value});

  handledescriptionChange= (e) => this.setState({ description: e.target.value});

  getTotalPayment=()=>{
    var total=0;
    total=Number(this.state.rate)*Number(this.state.quantity)
    return total;
  }

  handleexpensecategoryChange= (e) => this.setState({ expensecategory: e});
  handleexpensetoChange= (e) => this.setState({ expenseto: e.target.value});

  handleexpensetocontactChange= (e) => this.setState({ expensetocontact: e});
  handledebitorphonetwoChange= (e) => this.setState({ debitorphonetwo: e});

  handleexpensenoChange= (e) => this.setState({ expenseno: e.target.value});

  handlepaymentmodeChange= (e) => {
      this.setState({paymentmode:e})
  }


  handleselected_recruitChange= (e) =>{
    this.setState({ selected_recruit: e});
  }

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  onPaymentFinish=(event) =>{
    var paymentmode=event.paymentmode;
    var amount=event.amount;
    var comments=event.comments;
    var phonenumberfrom=event.phonenumberfrom;
    var phonenumberto=event.phonenumberto;
    var accountto=event.accountto;
    var clientbank=event.clientbank;
    var transreference=event.transreference;
    var chequeno=event.chequeno;
  
    this.setState({paymentmode: paymentmode});
    this.setState({ amountpaid: amount});
    this.setState({comments:comments});
    this.setState({phonenumberfrom:phonenumberfrom});
    this.setState({phonenumberto: phonenumberto});
    this.setState({accountto:accountto});
    this.setState({clientbank:clientbank});
    this.setState({transreference: transreference});
    this.setState({chequeno:chequeno});
    this.setState({ viewpaymentmodal: false });
  
    }

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created Expense "
            subTitle="Expenses are reported in the Income Statement"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return (
          <div>

        {
         this.state.userrights.create_expenses===true?
            <Form 
            name="expense Form"
            onFinish={(event) => this.handleFormSubmit(event)}
            >
    
          <FormItem label="Date"
            name='date'
            rules={[
              {
                required: true,
                message: 'Please Select date',
              },
            ]}
          >
              <DatePicker value={moment()} onChange={this.handledatechange} format={dateFormat} />
          </FormItem>
          
    
          
          <FormItem label="Expense Category"
              name="expensecategory"
              rules={[
                {
                  required: true,
                  message: 'Please Select Expense category',
                },
              ]}
            >
                <Select placeholder="Expense Category" style={{ width: 120 }} value={this.state.expensecategory} onChange={this.handleexpensecategoryChange} >
                  {this.state.expensecategories.map(
                    (ctgry)=>(
                      <Option value={ctgry.id}>{ctgry.category_name}</Option>
                    ))}
                </Select>
            </FormItem>
            
            <FormItem 
              label="Expense To Type"
              name="expensetotype"
              rules={[
                {
                  required: true,
                  message: 'Please select payment mode',
                },
              ]}
              >
                  <Select 
                  value={this.state.expensetotype} 
                  onChange={(val)=>{this.setState({expensetotype:val})}}
                  placeholder="expense to" >
                        <Option value='accountholder'>Candidate</Option>
                        <Option value='broker'>Broker</Option>
                        <Option value='nonaccountholder'>Operations</Option>
                  </Select>
              </FormItem>
  
              {
                this.state.expensetotype==="nonaccountholder"?
                <div>
                  <FormItem label="Expense to"
                  name="expenseto"
                  rules={[
                    {
                      required: false,
                      message: 'Please enter name',
                    },
                  ]}          
                
                
                >
                  <Input  placeholder="expenseto." value={this.state.expenseto} onChange={this.handleexpensetoChange} />
                </FormItem>
        
              
              <FormItem label="Expense to contact" >
                <PhoneInput
                      countrySelectProps={{ unicodeFlags: true }}
                      defaultCountry="UG"
                      placeholder="Enter phone"
                      value={this.state.expensetocontact} onChange={this.handleexpensetocontactChange}/>
                </FormItem>
                </div>
                :
                <div>

                  {
                    
                  this.state.expensetotype==="accountholder"?
                  <FormItem label="Select Candidate">
                    <Select 
                    placeholder="Candidate" 
                    style={{  }} 
                    value={this.state.selected_recruit}
                    onChange={this.handleselected_recruitChange} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}              
                    >
                      {this.state.recruits.map(
                        (accnt)=>(
                          <Option value={accnt.id}>{accnt.FullName} | {accnt.recruit_number}</Option>
                        ))}
                    </Select>
                  </FormItem>
                      :
                      null
                  }


                {
                    this.state.expensetotype==="broker"?
                    <FormItem label="Select Broker">
                      <Select 
                      placeholder="Broker" 
                      style={{  }} 
                      value={this.state.selected_broker}
                      onChange={(val)=>{this.setState({selected_broker:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}              
                      >
                        {this.state.personalbrokers.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.fullnames}</Option>
                          ))}
                      </Select>
                    </FormItem>
                        :
                        null
                    }
                
                </div>

              }
  
            
         {/*   <FormItem label="Quantity"
                      name="quantity"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Qunatity',
                        },
                      ]}             
                    
                    >
                      <Input name="quantity" type="number"  placeholder="Quantity" value={this.state.quantity} onChange={this.handlequantityChange}/>
                    </FormItem>
            
          
          <FormItem label="Rate"
                      name="rate"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Rate',
                        },
                      ]}             
                    
                    >
                      <Input name="rate" type="number"  placeholder="Rate" value={this.state.rate} onChange={this.handlerateChange}/>
                    </FormItem> */ }
            
            
            <FormItem label="Amount to be paid">
              <Input  type="number"  placeholder="Amount to be paid" value={this.state.total_to_be_paid} onChange={(val)=>{this.setState({total_to_be_paid:val.target.value})}} />
            </FormItem>
            
    
          <FormItem label="Amount Paid">
              <Input name="amountpaid" type="number" placeholder="Amount paid" value={this.state.amountpaid} onChange={this.handleamountpaidChange} onClick={this.showPaymentModal}/>
            </FormItem>
            
{ /*   
            <FormItem label="Expense No">
            <Input name="expenseno"   placeholder="Expense No" value={this.state.expenseno} onChange={this.handleexpensenoChange}/>
          </FormItem>*/}
  
  
          <FormItem label="Description">
                <TextArea
                      placeholder="Details."
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      value={this.state.description}
                      onChange={this.handledescriptionChange}
                    />
          </FormItem>

          {
            this.state.paymentmode==="accountcredit"?
            null
            :
              <FormItem label={<h4 style={{fontWeight:'bold'}}>Paying AC (Credit A/C).</h4>}
              name="creditaccount"
              rules={[
                {
                  required: true,
                  message: 'Please select account',
                },
              ]}
            >
                  <Select 
                  placeholder="Account" 
                  style={{  }} 
                  value={this.state.creditaccount}
                  onChange={(val)=>{this.setState({creditaccount:val})}} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                    
                  >
                    {this.state.companyaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                      ))}
                  </Select>
              </FormItem>
          }
          
    
          <FormItem>
            <Button type="primary" htmlType="submit" >Create</Button>
          </FormItem>
          </Form>
          :
          null}
    
    
    
       {/* expense payment modal */}
       <Modal
              visible={this.state.viewpaymentmodal}
              title="Expense Payment Form"
              width={600}
              onCancel={this.closepaymentmodal}
              footer={[
                <Button key="back" onClick={this.closepaymentmodal}>
                  Cancel
                </Button>,
                ]}
                >
              <Form
                  name="paymentform"
                  onFinish={(event) => this.onPaymentFinish(event)}
              >
    
               <FormItem 
                      label="Payment Mode"
                      name="paymentmode"
                      rules={[
                        {
                          required: true,
                          message: 'Please select payment mode',
                        },
                      ]}
                      >
                          <Select 
                          value={this.state.paymentmode} 
                          onChange={this.handlepaymentmodeChange}
                          placeholder="Payment Mode" >
                                <Option value='cash'>Cash</Option>
                                {
                                  this.state.expensetotype==="accountholder"?
                                  <Option value='accountcredit'>Account Credit</Option>
                                  :
                                  null
                                }
                                <Option value='mobilemoney'>Mobile Money</Option>
                                <Option value='cheque'>Cheque</Option>
                                <Option value='electronicfundtransfer'>Electronic Fund Transfer</Option>                         
  
                          </Select>
                </FormItem>
    
                <FormItem label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount',
                    },
                  ]}
                  
                  >
                    <InputNumber
                      style={{ width: 150 }} 
                      defaultValue={0.0}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    />    
                                
                  </FormItem>
                  
                  {
                    this.state.paymentmode=="mobilemoney"?
    
                    <div>
                    <FormItem 
                    label="Phone From"
                    name="phonenumberfrom"
                    rules={[
                      {
                        required: false,
                        message: 'Please input Phone',
                      },
                    ]}
                    
                    >
                          <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone"
                                />
                    </FormItem> 
    
                    <FormItem 
                    label="Phone To"
                    name="phonenumberto"
                    rules={[
                      {
                        required: false,
                        message: 'Please input Phone',
                      },
                    ]}
                    
                    >
                          <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone"
                                />
                    </FormItem> 
                    </div>
                    :
                    <br></br>
                  }
    
                    {
                    this.state.paymentmode=="electronicfundtransfer"               
                    ?
                    <div>
    
                  <FormItem label="Account To"
                    name="accountto"
                    rules={[
                      {
                        required: false,
                        message: 'Please input account',
                      },
                    ]}
                  
                  >
                    <Input placeholder="Account To"  />
                  </FormItem>
              
                  <FormItem label="Client Bank"
                    name="clientbank"
                    rules={[
                      {
                        required: false,
                        message: 'Please input bank',
                      },
                    ]}
                  
                  >
                    <Input placeholder="Client Bank"  />
                  </FormItem>
              
              
                  <FormItem label="Transaction Reference"
                    name="transreference"
                    rules={[
                      {
                        required: false,
                        message: 'Please input reference',
                      },
                    ]}
                  
                  >
                    <Input placeholder="Reference"  />
                  </FormItem>
    
                    </div>
    
                    :
                    <br></br>
    
                  }
    
    
                  {
                    this.state.paymentmode=="cheque"                
                    ?
                    <div>
    
                  <FormItem label="Cheque Number"
                    name="chequeno"
                    rules={[
                      {
                        required: false,
                        message: 'Please input cheque number',
                      },
                    ]}
                  
                  >
                    <Input placeholder="Cheque Number"  />
                  </FormItem>
            
                    </div>
    
                    :
                    <br></br>
    
                  }
    
    
                <FormItem label="Comments"
                name="comments"
                rules={[
                  {
                    required: false,
                    message: 'Please input comments',
                  },
                ]}
                
                >
                    <TextArea
                      placeholder="Comment here"
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                </FormItem>
    
                <FormItem>
                  <Button  type="primary" htmlType="submit">Add Payment</Button>
                </FormItem>
                </Form>
    
          </Modal>
    
          </div>
        );

      }


    }
  }

}


export default ExpenseForm;



