import React from 'react';
import RecruitorForm from '../components/RecruiterForm'
import RecruitorFormEdit from './RecruiterFormEdit'
import CandidateDetails from './CandidateDetailsView'
import CandidatePremedicalsList_Cand from './CandidatePremedicalsListView_Cand'
import CandidateGccmedicalsList_Cand from './CandidateGccmedicalsListView_Cand'
import ExpenseList_Cand from './ExpenseListView_Cand'
import CandidateTrainingList_Cand from './CandidateTrainingListView_Cand'
import CandidateInterpolList_Cand from './CandidateInterpolListView_Cand'
import CandidateContractList_Cand from './CandidateContractsListView_Cand'
import CandidateVisasList_Cand from './CandidateVisasListView_Cand'
import CandidateTicketList_Cand from './CandidateTicketListView_Cand'
import CordinatorReportList_Cand from './CordinatorReportListView_Cand'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin, Card,Tooltip,Image,Avatar,Empty,Tabs,Form,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,EditOutlined,WalletFilled,LoadingOutlined,EditFilled,AliyunOutlined,UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from 'moment';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

const { RangePicker } = DatePicker;


function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Search } = Input;


var ngoid= ''
var bizuserid= ''
var token= ''

class RecruiteeQualifiedList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    recruits: [],
    recruits_placeholder: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},
    loaded_image:false,
    current_selected_candidate:'',
    loan_particular_data_loading:false,
    dateone:'',
    datetwo:'',

  };

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();

}

//component mount method
componentmountactions=()=>{
  axios.get(serverconfig.backendserverurl+`/api/recruits/?status=${'Qualified'}`)
  .then(res => {
      this.setState({
        recruits:res.data
      })

      this.setState({
        recruits_placeholder:res.data
      })
  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })

}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/recruits/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );

  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    const columns = [
      {
        title: 'Profile',
        dataIndex: 'Photo',
        key: 'id',
        render: (text,record) =>
        <p>
          <Avatar size={70} icon={      
            <Image
            width={this.state.loaded_image===false?0:70}
            height={this.state.loaded_image===false?0:70}
            src={text}   
            preview={true} 
            onLoad={()=>{this.setState({loaded_image:true})}}
            
            />} />
          {
            this.state.loaded_image===false &&record.Photo?
            <Spin size="small">
            <div className="content" />
            </Spin>      
                  :
            null
          }          

        </p>,
      },
      {
        title: 'Candidate no',
        dataIndex: 'recruit_number',
        key: 'id',
        ...this.getColumnSearchProps('recruit_number'),
      },
      {
        title: 'Date of registration',
        dataIndex: 'date_of_registration',
        key: 'id',
        ...this.getColumnSearchProps('date_of_registration'),
      },
      {
        title: 'FullName',
        dataIndex: 'FullName',
        key: 'id',
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: 'Phone contact',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
        ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'id',
        ...this.getColumnSearchProps('gender'),
      },
      {
        title: 'Residence',
        dataIndex: 'AreaOfResidence',
        key: 'id',
        ...this.getColumnSearchProps('AreaOfResidence'),
      },

      {
        title: 'Date of birth',
        dataIndex: 'date_of_birth',
        key: 'id',
        ...this.getColumnSearchProps('date_of_birth'),
      },
      {
        title: 'Broker',
        dataIndex: 'persona_broker_name',
        key: 'id',
        ...this.getColumnSearchProps('persona_broker_name'),
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
          
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin size="large">
            <div className="content" />
          </Spin>           
        </div>
      )

    }else{
      return(
     <div>
       
       <Form  layout="inline" >

{ /*             <FormItem label="Search by date of entry">
                  <RangePicker defaultValue={moment()} 
                  onChange={(date, dateString) =>{
                    this.setState({ dateone: dateString[0]});
                    this.setState({ datetwo: dateString[1]});
                  }}
                   format={dateFormat} />
              </FormItem>

              <FormItem>
              <Button onClick={()=>{

                  let form_data = new FormData();
                  form_data.append('dateone', this.state.dateone);
                  form_data.append('datetwo', this.state.datetwo);
                  form_data.append('backendserverurl', serverconfig.backendserverurl);

                  if(this.state.dateone===''||this.state.datetwo===''){
                    message.error("Dates missing")
                  }else{
                      this.setState({datarequested:true})
                      this.setState({datasearch:true})

                      this.setState({recruits:[]})
                      //Now submit sale data to database
                      axios.post(serverconfig.backendserverurl+'/customqueries/getcandidates_report', form_data,{
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                      .then(res =>{
                        this.setState({datarequested:false})
                        this.setState({recruits:JSON.parse(res.data.report)})
                      }).catch(error => console.log(error))   
                  }

                  }} type="primary" htmlType="button">Search</Button>
              </FormItem> */}

              <FormItem label="Search by name ,passport number or candidate ID">

              <Search
                    placeholder="Enter name ,passport number or candidate ID"
                    onChange={(value) => {
                      var val=value.target.value
                      console.log(val)
                      if (val !== '') {
                        //set one is by NAME
                        const results_1 = this.state.recruits.filter((request) => {
                          return request.FullName.toLowerCase().startsWith(val.toLowerCase());
                          // Use the toLowerCase() method to make it case-insensitive
                        });
                  
                        if (results_1.length === 0) {
                          // this.setState({loanissues:this.state.loanissues_placeholder});

                          //STEP TWO BY NUMBER
                          const results_2 = this.state.recruits.filter((request) => {
                            return String(request.recruit_number).startsWith(val);
                            // Use the toLowerCase() method to make it case-insensitive
                          });

                          if (results_2.length === 0) {

                            //STEP THREE BY PASSPORT NUMBER
                            const results_3 = this.state.recruits.filter((request) => {
                              return JSON.parse(request.passport_details).passport_no.startsWith(val);
                              // Use the toLowerCase() method to make it case-insensitive
                            });

                            if (results_3.length === 0) {
                              this.setState({recruits:this.state.recruits_placeholder});
                            }else{
                              this.setState({recruits:results_3});

                            }


                          }else{
                            this.setState({recruits:results_2});
                          }

                        } else {
                          this.setState({recruits:results_1});
                          console.log(results_1)
                        }
                  
                      } else {
                        this.setState({recruits:this.state.recruits_placeholder});
                      }
                  
                    }}
                    style={{
                      width: 400,
                      margin:5
                    }}
                    />
                </FormItem>


                <FormItem label="Search by broker name">
            <Search
                  placeholder="Enter broker name"
                  onChange={(value) => {
                    var val=value.target.value
                    console.log(val)
                    if (val !== '' || val !== undefined) {
                      //set one is by NAME
                      const results_1 = this.state.recruits.filter((request) => {
                        return String(request.persona_broker_name).toLowerCase().startsWith(val.toLowerCase());
                        // Use the toLowerCase() method to make it case-insensitive
                      });
                
                      if (results_1.length === 0) {
                       this.setState({recruits:this.state.recruits_placeholder});

                      } else {
                        this.setState({recruits:results_1});
                        console.log(results_1)
                      }
                
                    } else {
                      this.setState({recruits:this.state.recruits_placeholder});
                    }
                
                  }}
                  style={{
                    width: 400,
                    margin:5
                  }}
                  />
              </FormItem>


              <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>} >Download excel candidates data</Button>}>
                <ExcelSheet data={this.state.recruits} name="Candidates">
                    <ExcelColumn label="Candidate no" value="recruit_number"/>
                    <ExcelColumn label="Name" value="FullName"/>
                    <ExcelColumn label="Tel" value="RegisteredPhoneNumber1"/>
                    <ExcelColumn label="National ID" value="national_id_no"/>
                    <ExcelColumn label="Area of residence" value="AreaOfResidence"/>
                    <ExcelColumn label="Gender" value="gender"/>
                    <ExcelColumn label="Marital status" value="marital_status"/>
                    <ExcelColumn label="Religion" value="religion"/>
                    <ExcelColumn label="Date of birth" value="date_of_birth"/>

                    <ExcelColumn label="Education level" value="education_level"/>
                    <ExcelColumn label="Previous employment details" value="previous_employmentdetails"/>
                    <ExcelColumn label="Skills" value="skills_and_competence"/>
                    <ExcelColumn label="Languages" value="languanges"/>
                    
                </ExcelSheet>
            </ExcelFile>
            </Form>


       <Table 
        columns={columns} 
        scroll={{ x: 1000 }}
        pagination={{showQuickJumper:true,showSizeChanger:true }}
        dataSource={this.state.recruits}
        bordered
        
        expandable={{
          expandedRowRender: (record) =>{
            if (Number(record.id)===Number(this.state.current_selected_candidate)){
              return (
                <div
                  style={{
                    margin: 0,
                  }}
                 >

                  {
                    this.state.loan_particular_data_loading===true?
                    <div style={{display:'flex',margin:20}}>
                    <Spin size="large">
                      <div className="content" />
                    </Spin>         
                    </div>
                    :

                    <Tabs defaultActiveKey="1" onChange={callback}>

                    <TabPane tab="Candidate Summary/CV" key="02" >
                      <CandidateDetails candidateID={record.id} />

                    </TabPane>

                    <TabPane tab="Update Candidate details" key="01" >
                      <RecruitorFormEdit candidateID={record.id} />
                    </TabPane>
  
                    <TabPane tab="Premedicals" key="03" >
                      <CandidatePremedicalsList_Cand candidateID={record.id}/>
                    </TabPane>
                 
                    <TabPane tab="Gcc medicals" key="04" >
                    <CandidateGccmedicalsList_Cand candidateID={record.id}/>
                    </TabPane>

                    <TabPane tab="Expenses" key="05" >
                    <ExpenseList_Cand candidateID={record.id}/>
                    </TabPane>

                    <TabPane tab="Training" key="06" >
                      <CandidateTrainingList_Cand candidateID={record.id}/>

                    </TabPane>

                    <TabPane tab="Interpol" key="07" >
                      <CandidateInterpolList_Cand candidateID={record.id}/>

                    </TabPane>

                    <TabPane tab="Contracts" key="08" >
                      <CandidateContractList_Cand candidateID={record.id}/>
                    </TabPane>

                    <TabPane tab="Visas" key="09" >
                      <CandidateVisasList_Cand candidateID={record.id}/>
                    </TabPane>

                    <TabPane tab="Tickets" key="10" >
                      <CandidateTicketList_Cand candidateID={record.id}/>
                    </TabPane>

                    <TabPane tab="Status Reports" key="11" >
                      <CordinatorReportList_Cand candidateID={record.id}/>
                    </TabPane>
                     </Tabs>
 
                  }

                </div>
                );
            }else{
              return null;
            }
          } ,
          rowExpandable: (record) => record.id !== this.state.current_selected_candidate.id,
          onExpand:(condition,record)=>{
            this.setState({loan_particular_data_loading:true})
            this.setState({current_selected_candidate:record.id})
            this.setState({loan_particular_data_loading:false})
          },
        }}
        
        />
       
      <br></br>
      <br></br>

      <Collapse defaultActiveKey={['0']} onChange={callback}>
      <Panel header="Collapse Panel to open agent entry Form" key="1">
        <h2>Create new candidate here</h2>
        <RecruitorForm /> 
      </Panel>
      </Collapse>  

  </div>
    )

    }

  }
}

export default RecruiteeQualifiedList; 
