import React from 'react';
import { Route } from 'react-router-dom';
import QuickMenu from './views/MainBranch/containers/QuickMenu'
import DashBoard from './views/MainBranch/containers/DashBoard'

import DistrictList from './views/MainBranch/containers/UgDistrictListView'
import UgDistrictDetail from './views/MainBranch/containers/UgDistrictDetailView'
import AccountList from './views/MainBranch/containers/AccountsListView'
import AccountDetail from './views/MainBranch/containers/AccountDetailView'

import RecruiteeList from './views/MainBranch/containers/RecruteesListView'
import SkillsList from './views/MainBranch/containers/SkillsListView'
import SkillDetail from './views/MainBranch/containers/SkillDetailView'

import personalbrokerssList from './views/MainBranch/containers/PersonalBrokersListView'
import PersonalBrokerDetails from './views/MainBranch/containers/PersonalBrokerDetails'

import companybrokerList from './views/MainBranch/containers/CompanyBrokersListView'
import CompanyBrokerDetails from './views/MainBranch/containers/CompanyBrokerDetails'

import RecruitmentAgencyList from './views/MainBranch/containers/RecruitmentAgencyListViews'
import TransportPartnersList from './views/MainBranch/containers/TransportPartnersListView'

import RecruitmentAgencyDetails from './views/MainBranch/containers/RecruitmentAgencyDetails'
import TransportPartnerDetails from './views/MainBranch/containers/TransportPartnerDetails'

import ResourcesList from './views/MainBranch/containers/ResourcesListView'
import ExpenseCategoryList from './views/MainBranch/containers/ExpenseCategorysListView'
import CompanyAccountList from './views/MainBranch/containers/CompanyAccountsListView'
import ExpenseList from './views/MainBranch/containers/ExpenseListView'
import ExpenseDetail from './views/MainBranch/containers/ExpenseDetailView'

import TillSheetReport from './views/MainBranch/containers/TillSheetReportView'
import TrialBalanceReport from './views/MainBranch/containers/TrialBalanceView'
import LedgerReport from './views/MainBranch/containers/LedgerReportView'
import CandidateTrainingList from './views/MainBranch/containers/CandidateTrainingListView'
import CandidateTrainingEditForm from './views/MainBranch/containers/CandidateTrainingEditForm'
import CandidatePremedicalsList from './views/MainBranch/containers/CandidatePremedicalsListView'
import CandidateGccmedicalsList from './views/MainBranch/containers/CandidateGccmedicalsListView'
import CandidateGccmedicalEditForm from './views/MainBranch/containers/CandidateGccmedicalEditForm'
import CandidatePremedicalEditForm from './views/MainBranch/containers/CandidatePremedicalEditForm'

import CandidateInterpolList from './views/MainBranch/containers/CandidateInterpolListView'
import CandidateInterpolEditForm from './views/MainBranch/containers/CandidateInterpolEditForm'

import CandidateContractList from './views/MainBranch/containers/CandidateContractsListView'
import CandidateContractEditForm from './views/MainBranch/containers/CandidateContractEditForm'

import CandidateVisasList from './views/MainBranch/containers/CandidateVisasListView'
import CandidateVisaEditForm from './views/MainBranch/containers/CandidateVisaEditForm'

import CandidateTicketList from './views/MainBranch/containers/CandidateTicketListView'
import CandidateTicketEditForm from './views/MainBranch/containers/CandidateTicketEditForm'

import CordinatorReportList from './views/MainBranch/containers/CordinatorReportListView'

import IncomeCategoryList from './views/MainBranch/containers/IncomeCategorysListView'
import IncomeCategoryDetail from './views/MainBranch/containers/IncomeCategoryDetailView'
import  IncomeList from './views/MainBranch/containers/IncomeListView'
import IncomeDetail from './views/MainBranch/containers/IncomeDetailView'
import IncomeStatementReport from './views/MainBranch/containers/IncomeStatementView'
import MedicalFacilityList from './views/MainBranch/containers/MedicalFacilityListView'
import MedicalFacilityDetail from './views/MainBranch/containers/MedicalFacilityDetailView'

import TrainingFacilityList from './views/MainBranch/containers/TrainingFacilityListView'
import TrainingFacilityDetail from './views/MainBranch/containers/TrainingFacilityDetailView'

import CandidateConditionsList from './views/MainBranch/containers/CandidateConditionsListView'
import CandidateContractModificationForm from './views/MainBranch/components/CandidateContractModificationForm'

import CandidateIssueReportList from './views/MainBranch/containers/CandidateIssueReportListView'
import DataimportForm from './views/MainBranch/components/DataImportForm'

const SaccoBaseRouter = () =>(

     <div>
        <Route exact path='/' component={DashBoard} />
        <Route exact path='/quickmenu/' component={QuickMenu} />
        <Route exact path='/ugdistricts/' component={DistrictList}  />
        <Route exact path='/ugdistricts/:districtID' component={UgDistrictDetail}  />
        <Route exact path='/useraccounts/' component={AccountList} />
        <Route exact path='/useraccounts/:accountID' component={AccountDetail} />
        <Route exact path='/recruits/' component={RecruiteeList} />
        <Route exact path='/skills/' component={SkillsList} />
        <Route exact path='/skills/:skillID' component={SkillDetail} />
        <Route exact path='/personalbrokers/' component={personalbrokerssList} />
        <Route exact path='/personalbrokers/:brokerID' component={PersonalBrokerDetails} />

        <Route exact path='/companybrokers/' component={companybrokerList} />
        <Route exact path='/companybrokers/:companyID' component={CompanyBrokerDetails} />

        <Route exact path='/recruitmentagencies/' component={RecruitmentAgencyList} />
        <Route exact path='/transportpartners/' component={TransportPartnersList} />

        <Route exact path='/transportpartners/:partnerID' component={TransportPartnerDetails} />
        <Route exact path='/recruitmentagencies/:agencyID' component={RecruitmentAgencyDetails} />
        <Route exact path='/resources/' component={ResourcesList} />
        <Route exact path='/expensecategories/' component={ExpenseCategoryList} />
        <Route exact path='/companyaccounts/' component={CompanyAccountList} />

        <Route exact path='/expenses/' component={ExpenseList}  />
        <Route exact path='/expenses/:expenseID' component={ExpenseDetail}  />

        <Route exact path='/trialbalance/' component={TrialBalanceReport}  />
        <Route exact path='/ledgerreport/' component={LedgerReport}  />
        <Route exact path='/tillsheet/' component={TillSheetReport}  />
        <Route exact path='/candidatestraining/' component={CandidateTrainingList}  />
        <Route exact path='/candidatestraining/:trainingID' component={CandidateTrainingEditForm} />
        <Route exact path='/candidate_premidicals/' component={CandidatePremedicalsList}  />
        <Route exact path='/candidate_premidicals/:medicalID' component={CandidatePremedicalEditForm}  />

        <Route exact path='/candidate_gccmidicals/' component={CandidateGccmedicalsList}  />
        <Route exact path='/candidate_gccmidicals/:medicalID' component={CandidateGccmedicalEditForm} />

        <Route exact path='/candidate_interpols/' component={CandidateInterpolList}  />
        <Route exact path='/candidate_interpols/:interpolID' component={CandidateInterpolEditForm} />

        <Route exact path='/candidate_contracts/' component={CandidateContractList}  />
        <Route exact path='/candidate_contracts/:contractID' component={CandidateContractEditForm} />

        <Route exact path='/candidate_visas/' component={CandidateVisasList}  />
        <Route exact path='/candidate_visas/:visaID' component={CandidateVisaEditForm} />

        <Route exact path='/candidate_tickets/' component={CandidateTicketList}  />
        <Route exact path='/candidate_tickets/:ticketID' component={CandidateTicketEditForm} />

        <Route exact path='/cordinator_reports/' component={CordinatorReportList}  />

        <Route exact path='/incomecategories/' component={IncomeCategoryList}  />
        <Route exact path='/incomecategories/:categoryID' component={IncomeCategoryDetail}  />
        <Route exact path='/incomes/' component={IncomeList}  />
        <Route exact path='/incomes/:incomeID' component={IncomeDetail}  />
        <Route exact path='/incomestatement/' component={IncomeStatementReport} />
        <Route exact path='/medical_facilities/' component={MedicalFacilityList} />
        <Route exact path='/medical_facilities/:facilityID' component={MedicalFacilityDetail}  />

        <Route exact path='/training_facilities/' component={TrainingFacilityList} />
        <Route exact path='/training_facilities/:facilityID' component={TrainingFacilityDetail}  />

        <Route exact path='/candidate_conditions/' component={CandidateConditionsList} />
        <Route exact path='/candidate_contract_modifications/' component={CandidateContractModificationForm} />

        <Route exact path='/candidate_issues_report/' component={CandidateIssueReportList} />
        <Route exact path='/data_import/' component={DataimportForm} />

    </div>
);

export default SaccoBaseRouter;


