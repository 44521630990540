import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Descriptions,Result,InputNumber,Form,Input,message,Select,DatePicker } from 'antd';
import { Row, Col,Collapse,Spin } from 'antd';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../../serverconn'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


function callback(key) {
    console.log(key);
  }


  var token= ''
  var sacco= ''
  var username= ''

class IncomeDetail extends React.Component{

    state={
        income: {},
        incomecategories:[],
        date:moment().format(dateFormat).toString(),  
        quantity:0,
        rate:0,
        amounttobepaid:0,
        balance:0,
        description:'',
        incomecategory:'',
        debitor:'',
        debitorphoneone:'',
        debitorphonetwo:'',
        datarequested:true,
        incomepayments:[],
        datasubmittedsuccessfully:false,
    }

    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
         username= ''
      }
    
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        const incomeID=this.props.match.params.incomeID;
        axios.get(`${serverconfig.backendserverurl}/api/incomes/${incomeID}`)
        .then(res => {  
            this.setState({income: res.data})
            this.setState({date: res.data.date})
            this.setState({quantity: res.data.quantity})
            this.setState({rate: res.data.rate})
            this.setState({description:res.data.details})
            this.setState({incomecategory:res.data.income_category})
        })

    
        axios.get(serverconfig.backendserverurl+`/api/incomecategories/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              incomecategories:res.data
            })

            this.setState({datarequested:false})

        })

        axios.get(serverconfig.backendserverurl+`/api/incomepayments/?sacco=${sacco}&Income=${incomeID}`)
        .then(res => {
            this.setState({
              incomepayments:res.data
            })
        })


    }


    handledatechange= (date, dateString) => this.setState({ date: dateString});
    handlequantityChange= (e) => this.setState({ quantity: e.target.value});
    handlerateChange= (e) => this.setState({ rate: e.target.value});
  
    handleamounttobepaidChange= (e) =>{
      this.setState({ amounttobepaid: e.target.value});
    } 
    handledescriptionChange= (e) => this.setState({ description: e.target.value});
  
    handleincomecategoryChange= (e) => this.setState({ incomecategory: e});
    handledebitorChange= (e) => this.setState({ debitor: e.target.value});
  
    handledebitorphoneoneChange= (e) => this.setState({ debitorphoneone: e});
    handledebitorphonetwoChange= (e) => this.setState({ debitorphonetwo: e});


    //calculate income payments total
    calculateincomepaymentsTotal=()=>{
      var tot=0
      this.state.incomepayments.map(
        (item)=>{
          tot+=Number(item.amount)
        })
  
      return tot;

    }
  
    getTotalPayment=()=>{
      var total=0;
      total=Number(this.state.rate)*Number(this.state.quantity)
      return total;
    }


    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    const incomeID=this.props.match.params.incomeID;

    console.log(this.state.issuestaff)

    let form_data = new FormData();
    form_data.append('incomeID', incomeID);
    form_data.append('income_category', this.state.incomecategory);
    form_data.append('rate', this.state.rate);
    form_data.append('quantity', this.state.quantity);
    form_data.append('total_to_be_paid', this.getTotalPayment());
    form_data.append('details', this.state.description);
    form_data.append('user', username);
    
    axios.post(serverconfig.backendserverurl+'/customqueries/updatincome', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{
    this.setState({datarequested:false})
    message.info(res.data.message)
    this.setState({datasubmittedsuccessfully:true})
  } 
  )
  .catch(error => console.log(error))

  }


    render(){
      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        if (this.state.datasubmittedsuccessfully===true){
          return(
              <Card>
              <Result
              status="success"
              title="Successfully Updated income "
              subTitle="Incomes are reported in the Income Statement"
              extra={[
                <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
              ]}
              />
              </Card>
          );
  
        }else{

          return(
            <div>
                <Row>
                <Card title={this.state.income.income_item}>
                    <Descriptions title="">
                    <Descriptions.Item label="Date">{this.state.income.date}</Descriptions.Item>
                    <Descriptions.Item label="Category">{this.state.income.incomecategoryname}</Descriptions.Item>
                    <Descriptions.Item label="Income from type">{this.state.income.incomefrom_type}</Descriptions.Item>
                    <Descriptions.Item label="Income from">{this.state.income.incomefrom_name}</Descriptions.Item>
                    <Descriptions.Item label="Income from contact">{this.state.income.incomefrom_contact}</Descriptions.Item>
                    <Descriptions.Item label="Rate"><CurrencyFormat value={this.state.income.rate} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></Descriptions.Item>
                    <Descriptions.Item label="Quantity">{this.state.income.quantity}</Descriptions.Item>
                    <Descriptions.Item label="Total Amount"><CurrencyFormat value={this.state.income.total_to_be_paid} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></Descriptions.Item>
                    <Descriptions.Item label="Amount Paid"><CurrencyFormat value={this.calculateincomepaymentsTotal()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></Descriptions.Item>
                    <Descriptions.Item label="Pending Balance"><CurrencyFormat value={Number(this.state.income.total_to_be_paid)-this.calculateincomepaymentsTotal()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></Descriptions.Item>
                    </Descriptions>
                </Card>
  
                </Row>
                <br/>
  
                <Collapse defaultActiveKey={['0']} onChange={callback}>
                <Panel header="Open Update Form" key="1">
                <h4>Income Update</h4>

                {
                  this.state.income.systemgenerated===true?
                  <div style={{display:'flex',alignItems:'flex-end',alignSelf:'flex-end'}}>
                  <h4 style={{color:'red',fontWeight:'bold',display:'flex',alignSelf:'flex-end'}}>System Generated incomes are non editable</h4>
                  </div>
                  :
                <Form 
                                  name="income Form"
                                  onFinish={(event) => this.handleFormSubmit(event)}
                                  >
                  
                                <FormItem label="Income Category"
                  
                                            >
                                                <Select placeholder="Income Category" style={{ width: 120 }} value={this.state.incomecategory} onChange={this.handleincomecategoryChange} >
                                                {this.state.incomecategories.map(
                                                    (ctgry)=>(
                                                    <Option value={ctgry.id}>{ctgry.category_name}</Option>
                                                    ))}
                                                </Select>
                                            </FormItem>
                                    
                  
                                <FormItem label="Quantity"
                                    >
                                    <Input name="quantity" type="number"  placeholder="Quantity" value={this.state.quantity} onChange={this.handlequantityChange}/>
                                    </FormItem>
                                    
                                
                                <FormItem label="Rate"
                                  >
                                  <Input name="rate" type="number"  placeholder="Rate" value={this.state.rate} onChange={this.handlerateChange}/>
                                  </FormItem>
                                    
                                    
                                <FormItem label="Amount to be paid"           
                                        >
                                        <Input  type="number"  placeholder="Amount to be paid" value={this.getTotalPayment()}  readOnly/>
                                        </FormItem>
                                
                  
                                <FormItem label="Description">
                                <Input  placeholder="Details." value={this.state.description} onChange={this.handledescriptionChange} />
                                </FormItem>
                  
                                <FormItem>
                                    <Button type="primary" htmlType="submit" >Update</Button>
                                </FormItem>
                            </Form>             

                }
  
                
              
                </Panel>
                </Collapse>
            
            </div>
        )

        }


      }

    }
}

export default IncomeDetail; 