import React from 'react';
import { Form, Input, Button,Select,Checkbox,Upload,Spin } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined,PhoneFilled } from '@ant-design/icons';
import * as serverconfig from '../../serverconn'
import Resizer from "react-image-file-resizer";

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class AccountUpdateForm extends React.Component {
  formRef = React.createRef();

  state = {
    profilepic:null,   
    profileuploadname:"",
    datarequested:false,
    userprofile:{},
    account_type:'',
    phone_number:''
  }


  componentDidMount(){
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.formRef.current.setFieldsValue({
      username: this.props.username,
      email: this.props.email,
      active: false,
      branchmanager: false,

    });

    //this.setState({datarequested:false})
    axios.get(`${serverconfig.backendserverurl}/api/accounts/${this.props.accountID}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })

        this.setState({datarequested:false})

        this.setState({account_type:res.data.account_type})
        this.setState({phone_number:res.data.phone_number})

    })


  }




  //submit button pressed
  onFinish=values =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('username', values.username);
    form_data.append('email', values.email);
    form_data.append('is_active', values.active);
    form_data.append('branch_manager', values.branchmanager);

    form_data.append('account_type', this.state.account_type);
    form_data.append('phone_number', this.state.phone_number);

    this.state.profilepic==null?
     console.log("No profile file")
     :
     form_data.append('profile_pic', this.state.profilepic, this.state.profilepic?this.state.profilepic.name:"");

     form_data.append('is_admin', this.state.userprofile.is_admin);
     form_data.append('is_staff', this.state.userprofile.is_staff);
     form_data.append('is_superuser', this.state.userprofile.is_superuser);

    //Now send the post resquest
      axios.put(`${serverconfig.backendserverurl}/api/accounts/${this.props.accountID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res =>this.setState({datarequested:false}),
      window.location.reload(false)
     )
    .catch(error => console.log(error)) 

    };

     normFile = e => {
      console.log('Upload event:', e);
    
      if (Array.isArray(e)) {
        return e;
      }
    
      return e && e.fileList;
    };


  handleprofilepicChange=   async (e) =>{

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ profilepic: newFile});

     //this.setState({ profilepic: e.target.files[0]});
  } 



//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin> 
        </div>
      )

    }else{

      return (
        <div>
          <Form  
          onFinish={this.onFinish}        
          ref={this.formRef}>
  
            <FormItem 
            name="username"
            label="User Name"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Put a name here." defaultValue={this.props.username} />
            </FormItem>

            <Form.Item label="Phone number">
              <Input prefix={<PhoneFilled className="site-form-item-icon" />} placeholder="Phone number"  value={this.state.phone_number}  onChange={(val)=>{this.setState({phone_number:val.target.value})}} />
            </Form.Item>
  
            <FormItem 
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
            >
              <Input  type="mail" prefix={<MailOutlined className="site-form-item-icon" />}  placeholder="Put a name here." defaultValue={this.props.email}  />
            </FormItem>
  
            <FormItem label="Profile Image">
                <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={this.handleprofilepicChange}/>
            </FormItem>
  
            <FormItem name="active" label="Active" valuePropName="checked" noStyle>
              <Checkbox >Active</Checkbox>
            </FormItem>
  
            <FormItem name="branchmanager" label="Branch Manager" valuePropName="checked" noStyle>
              <Checkbox >Managerial</Checkbox>
            </FormItem>

          <FormItem label="Account type">
              <Select placeholder="Account type" 
              style={{ width: 120 }} 
              value={this.state.account_type} 
              onChange={(val)=>{this.setState({account_type:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
              >
              <Option value="staff">Staff</Option>
              <Option value="cordinator">Cordinator</Option>
              </Select>
          </FormItem>

          <FormItem>
            <Button htmlType="submit" type="primary">Update</Button>
          </FormItem>
          </Form>
  
        </div>
        
      );

    }
   
  }

}

export default AccountUpdateForm;

