import React from 'react';
import { Form, Input, Button,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class MedicalFacilityForm extends React.Component {

  state = {
    name:'' ,  
    address:'',
    phone_contact:'',
    datarequested:false,
  }

  componentDidMount(){

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('address', this.state.address);
    form_data.append('phone_contact', this.state.phone_contact);

      axios.post(serverconfig.backendserverurl+'/api/medical_facilities/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Facility Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>

      <FormItem label="Address"
        name="address"
        rules={[
          {
            required: true,
            message: 'Please input address',
          },
        ]}    
      >
        <Input name="address"  placeholder="address" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
      </FormItem>

      <FormItem label="Phone contact"
        name="phone_contact"
        rules={[
          {
            required: true,
            message: 'Please input phone_contact',
          },
        ]}    
      >
        <Input name="phone_contact"  placeholder="phone_contact" value={this.state.phone_contact} onChange={(val)=>{this.setState({phone_contact:val.target.value})}} />
      </FormItem>
     
      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
      </FormItem>
       </Form>
        </div>
      );

    }

   
  }

}


export default MedicalFacilityForm;



