import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var bizuserid= ''


class ReportToPrint extends React.Component {

    state = {
      companyprofile:{}
    };
  
    componentDidMount(){
      if(localStorage.getItem("bizuserid")){
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
      })
  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.system_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
              
                </Col>

     </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>INCOME STATEMENT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <h4>INCOMES </h4>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Income Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.incomecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalincomes} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>   

            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />
            <h4>EXPENSES</h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Expense Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.expenses.map(
                        (item)=>(
                          <tr>
                          <td>{item.expensecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalexpenses} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                        <tr>
                        <td style={{fontWeight:'bolder',fontSize:20}}>SURPLUS</td>
                        <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.surplus} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table> 

                <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   
        </div>
      );
    }
  }




class IncomeStatementReport extends React.Component {

  state = {
   expenses:[],
   incomes:[],
   dateone:'',
   datetwo:'',
   totalincomes:0,
   totalexpenses:0,
   surplus:0,
   datarequested:false,

  };

  componentDidMount(){
      if(localStorage.getItem("bizuserid")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }

  handledatechange= (date, dateString) =>{
    this.setState({ dateone: dateString[0]});
    this.setState({ datetwo: dateString[1]});
  } 

  //search incomes method
  getstatement=()=>{
   let form_data = new FormData();
   form_data.append('dateone', this.state.dateone);
   form_data.append('datetwo', this.state.datetwo);
   
   if(this.state.dateone===''||this.state.datetwo===''){
    alert("Please are dates missing")
   }else{
    this.setState({datarequested:true})

       //Now submit sale data to database
       axios.post(serverconfig.backendserverurl+'/customqueries/getincomestatement', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
         })
         .then(res =>{
           this.setState({datarequested:false})
 
             this.setState({expenses:JSON.parse(res.data.expensereport)})
             this.setState({incomes:JSON.parse(res.data.incomesreport)})
             this.setState({totalincomes:res.data.totalincomes})
             this.setState({totalexpenses:res.data.totalexpenses})
             this.setState({surplus:res.data.surplus})
         } 
         )
         .catch(error => console.log(error)) 
   }

   }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
      
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            totalincomes={this.state.totalincomes} 
            totalexpenses={this.state.totalexpenses} 
            surplus={this.state.surplus} 
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}
            incomes={this.state.incomes}
            expenses={this.state.expenses}
            ref={el => (this.componentRef = el)} /> 

          </div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="INCOME STATEMENT" key="1">
            <Form  layout="inline" >
                <FormItem label="Date Range">
                    <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button onClick={this.getstatement} type="primary" htmlType="button">Search</Button>
                </FormItem> 

           </Form>

           <br></br>
           <h4>INCOMES </h4>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Income Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.incomecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalincomes} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>   

            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />
            <h4>EXPENSES</h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Expense Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.expenses.map(
                        (item)=>(
                          <tr>
                          <td>{item.expensecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalexpenses} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                        <tr>
                        <td style={{fontWeight:'bolder',fontSize:20}}>SURPLUS</td>
                        <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.state.surplus} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                    </tbody>
                </reactstrp.Table> 


          <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

          <ReactToPrint
          trigger={() => 
          <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
          Print
          </Button>
          }
          content={() => this.componentRef}
        />
        </div>

        </Panel>
        </Collapse>
        </div>

    )

    }


    
  }
}

export default IncomeStatementReport; 
