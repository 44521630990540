import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin} from 'antd';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class SkillDetail extends React.Component{

    state={
        idtype: {},
        name:'' ,  
        description:'',
        datarequested:true,
        IDvaliditydays:0,

    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        const skillID=this.props.match.params.skillID;
        axios.get(`${serverconfig.backendserverurl}/api/skills/${skillID}`)
        .then(res => {  
            this.setState({
                idtype: res.data

            })

            this.setState({name:res.data.name})
            this.setState({description:res.data.description})
            this.setState({datarequested:false})

        })
    }

    handlenameChange= (e) => this.setState({ name: e.target.value});
    handleIDDescriptionChange= (e) => this.setState({ description: e.target.value});
  

    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const skillID=this.props.match.params.skillID;

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('description', this.state.description);

    axios.put(`${serverconfig.backendserverurl}/api/skills/${skillID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated '))
    .catch(error => console.log(error))


  }

    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.idtype.id_type_name}>
                        <p>Desc: {this.state.idtype.description}</p>
                    </Card>
                    </Col>
                    <Col span={2}>
                 
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                   
                    </Col>
    
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>Supply Update</h4>
                        <Form>
                            <FormItem label="Name">
                            <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={this.handlenameChange} />
                            </FormItem>
                            <FormItem label="Description">
                            <Input name="description"  placeholder="Description here" value={this.state.description} onChange={this.handleIDDescriptionChange} />
                            </FormItem>
                        
                            <FormItem>
                            <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                            </FormItem>
                        </Form>
    
                    </Card>
    
                    </Col>
    
                  
                    </Row>
    
                    <br/>
    
                </div>
            )


          }

       
    }
}

export default SkillDetail; 