import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import UserCreationForm from '../components/UserCreationForm'

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var sacco= ''
var username=''
var token= ''
var ngoid= ''

class AccountList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    accounts: [],
    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    create_candidate:true,
    view_candidates:true,

    create_premedical:true,
    view_premedicals:true,

    create_gccmedical:true,
    view_gccmedicals:true,

    create_expenses:true,
    view_expenses:true,

    create_trainingschedules:true,
    view_trainingschedules:true,

    create_interpol:true,
    view_interpol:true,

    create_contract:true,
    view_contracts:true,

    create_visas:true,
    view_visas:true,

    create_tickets:true,
    view_tickets:true,

    create_candidatereports:true,
    view_candidatereports:true,

    can_view_incomes:true,
    can_enter_incomes:true,
    receive_report_notifications:false

  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  
  //////////////////////////////////////
  ///// change password here
  handlePasswordChange=(values)=>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('password1', values.new_password1);
    form_data.append('password2', values.new_password2);
    form_data.append('userid', this.state.selectedid);
  
    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/updatecompanyuserpassword', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({datarequested:false})

      message.info(res.data.message);
    }
    )
    .catch(error => console.log(error)) 
  
  }




  ///////////////////////////////////////////
  //getting simple user account rights
  showAccountRights=(text) =>{
    this.setState({ rightsmodalvisible: true });

    //make api call to get the user rights
    axios.get(`${serverconfig.backendserverurl}/api/accountrights?user=${text}`)
    .then(res => {  
        this.setState({
            rights: res.data[0]
        })
        this.setState({create_candidate:res.data[0].create_candidate})
        this.setState({view_candidates:res.data[0].view_candidates})

        this.setState({create_premedical:res.data[0].create_premedical})
        this.setState({view_premedicals:res.data[0].view_premedicals})

        this.setState({create_gccmedical:res.data[0].create_gccmedical})
        this.setState({view_gccmedicals:res.data[0].view_gccmedicals})

        this.setState({create_expenses:res.data[0].create_expenses})
        this.setState({view_expenses:res.data[0].view_expenses})

        this.setState({create_trainingschedules:res.data[0].create_trainingschedules})
        this.setState({view_trainingschedules:res.data[0].view_trainingschedules})

        this.setState({create_interpol:res.data[0].create_interpol})
        this.setState({view_interpol:res.data[0].view_interpol})

        this.setState({create_contract:res.data[0].create_contract})
        this.setState({view_contracts:res.data[0].view_contracts})

        this.setState({create_visas:res.data[0].create_visas})
        this.setState({view_visas:res.data[0].view_visas})

        this.setState({create_tickets:res.data[0].create_tickets})
        this.setState({view_tickets:res.data[0].view_tickets})

        this.setState({create_candidatereports:res.data[0].create_candidatereports})
        this.setState({view_candidatereports:res.data[0].view_candidatereports})

        this.setState({can_view_incomes:res.data[0].can_view_incomes})
        this.setState({can_enter_incomes:res.data[0].can_enter_incomes})
        this.setState({receive_report_notifications:res.data[0].receive_report_notifications})

        
    })

  }

  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/accounts/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };


  //make an update now on the user rights
  updaterights=values=>{
    let form_data = new FormData();

    form_data.append('create_candidate', this.state.create_candidate)
    form_data.append('view_candidates', this.state.view_candidates)
    form_data.append('create_premedical', this.state.create_premedical)
    form_data.append('view_premedicals', this.state.view_premedicals)

    form_data.append('create_gccmedical', this.state.create_gccmedical)
    form_data.append('view_gccmedicals', this.state.view_gccmedicals)

    form_data.append('create_expenses', this.state.create_expenses)
    form_data.append('view_expenses', this.state.view_expenses)

    form_data.append('create_trainingschedules', this.state.create_trainingschedules)
    form_data.append('view_trainingschedules', this.state.view_trainingschedules)

    form_data.append('create_interpol', this.state.create_interpol)
    form_data.append('view_interpol', this.state.view_interpol)

    form_data.append('create_contract', this.state.create_contract)
    form_data.append('view_contracts', this.state.view_contracts)
    
    form_data.append('create_visas', this.state.create_visas)
    form_data.append('view_visas', this.state.view_visas)

    form_data.append('create_tickets', this.state.create_tickets)
    form_data.append('view_tickets', this.state.view_tickets)

    form_data.append('create_candidatereports', this.state.create_candidatereports)
    form_data.append('view_candidatereports', this.state.view_candidatereports)

    form_data.append('can_view_incomes', this.state.can_view_incomes)
    form_data.append('can_enter_incomes', this.state.can_enter_incomes)
    form_data.append('receive_report_notifications', this.state.receive_report_notifications)

    //Now send the post resquest
    axios.put(`${serverconfig.backendserverurl}/api/accountrights/${this.state.rights.id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => window.location.reload(false)
    )
    .catch(error => console.log(error))

  }


  componentDidMount(){

    if(localStorage.getItem("sacco")){
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/accounts/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})

    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: 'Profile',
        dataIndex: 'profile_pic',
        key: 'id',
        render: (text,record) => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={record.profile_pic}
        />,
      },
      {
        title: 'Account type',
        dataIndex: 'account_type',
        key: 'id',
        ...this.getColumnSearchProps('account_type'),
      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'id',
        ...this.getColumnSearchProps('username'),
      },
      {
        title: 'Phone ',
        dataIndex: 'phone_number',
        key: 'id',
        ...this.getColumnSearchProps('phone_number'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/useraccounts/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
{/*        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover> */}
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to edit,view user rights</p>} title="Edit,View rights">
         <UserOutlined onClick={(event) => this.showAccountRights(text)} style={{color:'blue'}}/>
        </Popover > 
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click to change password</p>} title="Password Change">
          <LockFilled style={{color:'#154360'}} onClick={() => this.displaypasswordupdatemodal(text)}/>
        </Popover>
        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>       
        </div>
      )

    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL ACCOUNTS" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.accounts} bordered/>
            </Panel>
            </Collapse>
            <br></br>
            <UserCreationForm />
            <br></br>

            <Drawer
                title={this.state.rights.username+" "+"Account Rights"}
                width={520}
                placement="right"
                closable={false}
                onClose={this.onresetdrawerClose}
                visible={this.state.rightsmodalvisible}
              >
  

                    <h3 style={{color:'red'}}>Edit User Rights</h3>

                    <Form  
                      onFinish={this.updaterights}        
                      >
                      <FormItem name="create_candidate" label="create_candidate" >
                          <Switch
                              checked={this.state.create_candidate}
                              onChange={(val)=>{this.setState({create_candidate:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>  

                      <FormItem name="view_candidates" label="view_candidates" >
                          <Switch
                              checked={this.state.view_candidates}
                              onChange={(val)=>{this.setState({view_candidates:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem> 

                      <FormItem name="create_premedical" label="create_premedical" >
                          <Switch
                              checked={this.state.create_premedical}
                              onChange={(val)=>{this.setState({create_premedical:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem> 
                      

                      <FormItem name="view_premedicals" label="view_premedicals" >
                          <Switch
                              checked={this.state.view_premedicals}
                              onChange={(val)=>{this.setState({view_premedicals:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem> 

                      <FormItem name="create_gccmedical" label="create_gccmedical" >
                          <Switch
                              checked={this.state.create_gccmedical}
                              onChange={(val)=>{this.setState({create_gccmedical:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem> 

                      <FormItem name="view_gccmedicals" label="view_gccmedicals" >
                          <Switch
                              checked={this.state.view_gccmedicals}
                              onChange={(val)=>{this.setState({view_gccmedicals:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem> 

                      <FormItem name="create_expenses" label="create_expenses" >
                          <Switch
                              checked={this.state.create_expenses}
                              onChange={(val)=>{this.setState({create_expenses:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem> 

                      <FormItem name="view_expenses" label="view_expenses" >
                          <Switch
                              checked={this.state.view_expenses}
                              onChange={(val)=>{this.setState({view_expenses:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="create_trainingschedules" label="create_trainingschedules" >
                          <Switch
                              checked={this.state.create_trainingschedules}
                              onChange={(val)=>{this.setState({create_trainingschedules:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>


                      <FormItem name="view_trainingschedules" label="view_trainingschedules" >
                          <Switch
                              checked={this.state.view_trainingschedules}
                              onChange={(val)=>{this.setState({view_trainingschedules:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      {/*new rights */}
             

                      <FormItem name="create_interpol" label="create_interpol" >
                          <Switch
                              checked={this.state.create_interpol}
                              onChange={(val)=>{this.setState({create_interpol:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="view_interpol" label="view_interpol" >
                          <Switch
                              checked={this.state.view_interpol}
                              onChange={(val)=>{this.setState({view_interpol:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="create_contract" label="create_contract" >
                          <Switch
                              checked={this.state.create_contract}
                              onChange={(val)=>{this.setState({create_contract:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>
                      
                       {/*new rights */}


                      <FormItem name="view_contracts" label="view_contracts" >
                          <Switch
                              checked={this.state.view_contracts}
                              onChange={(val)=>{this.setState({view_contracts:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="create_visas" label="create_visas" >
                          <Switch
                              checked={this.state.create_visas}
                              onChange={(val)=>{this.setState({create_visas:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="view_visas" label="view_visas" >
                          <Switch
                              checked={this.state.view_visas}
                              onChange={(val)=>{this.setState({view_visas:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="create_tickets" label="create_tickets" >
                          <Switch
                              checked={this.state.create_tickets}
                              onChange={(val)=>{this.setState({create_tickets:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="view_tickets" label="view_tickets" >
                          <Switch
                              checked={this.state.view_tickets}
                              onChange={(val)=>{this.setState({view_tickets:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="create_candidatereports" label="create_candidatereports" >
                          <Switch
                              checked={this.state.create_candidatereports}
                              onChange={(val)=>{this.setState({create_candidatereports:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="view_candidatereports" label="view_candidatereports" >
                          <Switch
                              checked={this.state.view_candidatereports}
                              onChange={(val)=>{this.setState({view_candidatereports:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="can_view_incomes" label="can_view_incomes" >
                          <Switch
                              checked={this.state.can_view_incomes}
                              onChange={(val)=>{this.setState({can_view_incomes:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="can_enter_incomes" label="can_enter_incomes" >
                          <Switch
                              checked={this.state.can_enter_incomes}
                              onChange={(val)=>{this.setState({can_enter_incomes:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                      <FormItem name="receive_report_notifications" label="receive_report_notifications" >
                          <Switch
                              checked={this.state.receive_report_notifications}
                              onChange={(val)=>{this.setState({receive_report_notifications:val})}}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              />
                      </FormItem>

                     
                        <FormItem>
                          <Button htmlType="submit" type="primary">Update</Button>
                        </FormItem>
                        
                      </Form>

              </Drawer>


              <Modal              
                visible={this.state.showpasswordupdatemodal}
                title="Password change Form"
                width={600}
                onCancel={this.closepasswordupdatemodal}

                footer={[
                  <Button key="back" onClick={this.closepasswordupdatemodal}>
                    Cancel
                  </Button>
                  ]}
                  >
              <Form
              onFinish={this.handlePasswordChange}        
              name="passwordreset"
              className="login-form">
        
              <Form.Item
                  name="new_password1"
                  label="New Password one"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password one"
                  />


                </Form.Item>

          
                <Form.Item
                  name="new_password2"
                  label="Confirm New Password"
                  dependencies={['new_password1']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('new_password1') === value) {
                          return Promise.resolve();
                        }
          
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm" />

                </Form.Item>

              <Form.Item >
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Confirm Change
                  </Button>
              </Form.Item>
            </Form>
                    
            </Modal>
        </div>
    )
    }
   
  }
}

export default AccountList; 
