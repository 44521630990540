import React from 'react';
import CandidateConditionForm from '../components/CandidateConditionForm'
import CandidateAppConditionForm from '../components/CandidateAppConditionForm '

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var token= ''

class CandidateConditionsList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    candidate_conditions: [],
    datarequested:true,
    candidateapp_conditions: [],
  };


  componentDidMount(){

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/candidate_conditions/`)
    .then(res => {
        this.setState({
            candidate_conditions:res.data
        })
        //this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/candidateapp_conditions/`)
    .then(res => {
        this.setState({
          candidateapp_conditions:res.data
        })
        this.setState({datarequested:false})
    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = (id) => {

    axios.delete(`${serverconfig.backendserverurl}/api/candidate_conditions/${id}/`, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(res =>{
      window.location.reload(false)
      message.info('Operation successful')
    }
    );

  };

  render() {
    const columns = [
      {
        title: ' Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'id',
        ...this.getColumnSearchProps('description'),
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
       
        <Popover content={<p>Click here to delete </p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => {
          axios.delete(`${serverconfig.backendserverurl}/api/candidate_conditions/${text}/`, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }).then(res =>{
            window.location.reload(false)
            message.info('Operation successful')
          }
          );

          }}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];


    const app_columns = [
      {
        title: ' Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'id',
        ...this.getColumnSearchProps('description'),
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
      
        <Popover content={<p>Click here to delete</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => {
          axios.delete(`${serverconfig.backendserverurl}/api/candidateapp_conditions/${text}/`, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }).then(res =>{
            window.location.reload(false)
            message.info('Operation successful')
          }
          );

          }}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <Row>

<Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
<Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="CANDIDATE CONDITIONS (REPORT TITLES)" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.candidate_conditions}
            bordered
            size='small'
            />
             </Panel>
             </Collapse>
            <br />
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open condition Form" key="1">
              <h2>Create new condition here</h2>
              <CandidateConditionForm /> 
            </Panel>
            </Collapse>
  </Col>

  <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
  <Collapse defaultActiveKey={['1']} onChange={this.callback}>
    <Panel header="CANDIDATE APP CONDITIONS (PRESELECTED CONDITIONS)" key="1">
    <Table 
    columns={app_columns} 
    scroll={{ x: 1000 }}
    pagination={{showQuickJumper:true,showSizeChanger:true }}
    dataSource={this.state.candidateapp_conditions}
    bordered
    size='small'
    />
    </Panel>
    </Collapse>

  <br />
    <Collapse defaultActiveKey={['0']} onChange={callback}>
    <Panel header="Collapse Panel to open App condition Form" key="1">
      <h2>Create new condition here</h2>
      <CandidateAppConditionForm /> 
    </Panel>
    </Collapse>

  </Col>

  </Row>


            
        </div>
    )}

  }
}

export default CandidateConditionsList; 
