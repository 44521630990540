import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin} from 'antd';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class MedicalFacilityDetail extends React.Component{

    state={
        name:'' ,  
        address:'',
        phone_contact:'',
        datarequested:true,
    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const facilityID=this.props.match.params.facilityID;
        axios.get(`${serverconfig.backendserverurl}/api/medical_facilities/${facilityID}`)
        .then(res => {  
            this.setState({name:res.data.name})
            this.setState({address:res.data.address})
            this.setState({phone_contact:res.data.phone_contact})
            this.setState({datarequested:false})
        })
    }

  
    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const facilityID=this.props.match.params.facilityID;

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('address', this.state.address);
    form_data.append('phone_contact', this.state.phone_contact);

    axios.put(`${serverconfig.backendserverurl}/api/medical_facilities/${facilityID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated ID'))
    .catch(error => console.log(error))
  }


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                   
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>Facility Update</h4>
                        <Form>
                        <FormItem label="Facility Name"
                     
                      >
                        <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
                      </FormItem>

                      <FormItem label="Address"
                           
                      >
                        <Input name="address"  placeholder="address" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
                      </FormItem>

                      <FormItem label="Phone contact"
                         
                      >
                        <Input name="phone_contact"  placeholder="phone_contact" value={this.state.phone_contact} onChange={(val)=>{this.setState({phone_contact:val.target.value})}} />
                      </FormItem>
                        
                      <FormItem>
                      <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                      </FormItem>

                      </Form>
    
                    </Card>
    
                </div>
            )
        }
    }
}

export default MedicalFacilityDetail; 