import React from 'react';
import CandidateTrainingForm from '../components/CandidateTrainingForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Form,Spin,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from 'moment';

var CryptoJS = require("crypto-js");

const { RangePicker } = DatePicker;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}


var token= ''
var sacco= ''

class CandidateTrainingList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    candidate_trainings: [],
    datarequested:true,
    dateone:'',
    datetwo:''

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/candidate_trainings`)
    .then(res => {
        this.setState({
            candidate_trainings:res.data
        })
        this.setState({datarequested:false})
    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/candidate_trainings/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  render() {
    const columns = [
      {
        title: 'Candidate',
        dataIndex: 'candidate_name',
        key: 'id',
        ...this.getColumnSearchProps('candidate_name'),
      },
      {
        title: 'Start date',
        dataIndex: 'start_date',
        key: 'id',
        ...this.getColumnSearchProps('start_date'),
      },
      {
        title: 'End date',
        dataIndex: 'end_date',
        key: 'id',
        ...this.getColumnSearchProps('end_date'),
      },
      {
        title: 'Training institution',
        dataIndex: 'training_school',
        key: 'id',
        ...this.getColumnSearchProps('training_school'),
      },
      {
        title: 'Training details',
        dataIndex: 'traing_details',
        key: 'id',
        ...this.getColumnSearchProps('traing_details'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
        ...this.getColumnSearchProps('status'),
      },
      
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <a href={record.attachment}>Download</a>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/candidatestraining/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="CANDIDATE TRAINING" key="1">

            <Form  layout="inline" >
              
              <FormItem label="Search by start date">
                  <RangePicker defaultValue={moment()} 
                  onChange={(date, dateString) =>{
                    this.setState({ dateone: dateString[0]});
                    this.setState({ datetwo: dateString[1]});
                  }}
                   format={dateFormat} />
              </FormItem>

              <FormItem>
              <Button onClick={()=>{

                  let form_data = new FormData();
                  form_data.append('dateone', this.state.dateone);
                  form_data.append('datetwo', this.state.datetwo);
                  form_data.append('backendserverurl', serverconfig.backendserverurl);

                  if(this.state.dateone===''||this.state.datetwo===''){
                    message.error("Dates missing")
                  }else{
                      this.setState({datarequested:true})
                      this.setState({datasearch:true})

                      this.setState({candidate_trainings:[]})
                      //Now submit sale data to database
                      axios.post(serverconfig.backendserverurl+'/customqueries/gettrainingsreport', form_data,{
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                      .then(res =>{
                        this.setState({datarequested:false})
                          this.setState({candidate_trainings:JSON.parse(res.data.report)})
                      } 
                      )
                      .catch(error => console.log(error))   
                  }

                  }} type="primary" htmlType="button">Search</Button>
              </FormItem> 

              <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>} >Download excel </Button>}>
                    <ExcelSheet data={this.state.candidate_trainings} name="Training schedules">
                        <ExcelColumn label="Candidate" value="candidate_name"/>
                        <ExcelColumn label="Start date" value="start_date"/>
                        <ExcelColumn label="End date" value="end_date"/>
                        <ExcelColumn label="Training facility" value="training_school"/>
                        <ExcelColumn label="Details" value="traing_details"/>
                    </ExcelSheet>
                </ExcelFile>
            </Form>


            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.candidate_trainings}
            bordered/>
             </Panel>
             </Collapse>
            <br /><br />

            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open Form" key="1">
              <h2>Create new entry here</h2>
              <CandidateTrainingForm 
                requestType="post"
                idtypeID={null}
                btnText="Create"
                /> 
            </Panel>
            </Collapse>
        </div>
    )

    }

   
  }
}

export default CandidateTrainingList; 
