import React from 'react';
import CandidateVisaForm from '../components/CandidateVisaForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''

class CandidateVisasList_Cand_display extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    candidate_visas: [],
    datarequested:true,
  };


  componentDidMount(){
    var candidateID=this.props.candidateID

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/candidate_visas/?candidate=${candidateID}`)
    .then(res => {
        this.setState({
            candidate_visas:res.data
        })
        this.setState({datarequested:false})
    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/candidate_visas/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  render() {
    const columns = [
      {
        title: 'Candidate',
        dataIndex: 'candidate_name',
        key: 'id',
        ...this.getColumnSearchProps('candidate_name'),
      },
      {
        title: 'Date of application',
        dataIndex: 'date_of_application',
        key: 'id',
        ...this.getColumnSearchProps('date_of_application'),
      },
      {
        title: 'Date accepted',
        dataIndex: 'date_accepted',
        key: 'id',
        ...this.getColumnSearchProps('date_accepted'),
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
        ...this.getColumnSearchProps('status'),
      },

      {
        title: 'Details',
        dataIndex: 'more_details',
        key: 'id',
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <a href={record.attachment}>Download</a>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/candidate_visas/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return(
        <div>
            <reactstrp.Table bordered>
                <thead>
                  <tr>
                    <th>Date of application</th>
                    <th>Date accepted</th>
                    <th>Status  </th>
                    <th>More details</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.candidate_visas.map(
                  (item)=>(
                    <tr>
                    <td>{item.date_of_application}</td>
                    <td>{item.date_accepted}</td>
                    <td>{item.status}</td>
                    <td>{item.more_details}</td>
                    </tr>
                  ))}

              </tbody>
              </reactstrp.Table> 

           
        </div>
        )

    }

  }
}

export default CandidateVisasList_Cand_display; 
