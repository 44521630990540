
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,InputNumber,Table,Popover,Modal,Image,Form,Select,Spin} from 'antd';
import { Drawer, List,Alert,Affix,notification } from 'antd';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  SnippetsOutlined,
  KeyOutlined,
  FilePdfFilled,
  RightCircleFilled,
  DollarCircleOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  PayCircleOutlined,
  BarChartOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  BankFilled,
  AndroidOutlined,
  DeploymentUnitOutlined,
  GroupOutlined,
  AccountBookFilled,
  CalculatorFilled,
  FileFilled,
  MessageFilled,
  LoadingOutlined,
  FundViewOutlined,
  ArrowLeftOutlined,
  
} from '@ant-design/icons';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import UIfx from 'uifx';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions/auth'
import axios from 'axios'
import UserAccountUpdateForm from '../components/UserAccountUpdateForm'
import WrappedPasswordResetForm from './PasswordResetForm'
import * as serverconfig from '../../serverconn'
import * as serversocketconfig from '../../serversocketconfig'

import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import ReconnectingWebSocket from 'reconnecting-websocket';
import tickAudio from '../../../assets/sounds/that-was-quick.mp3';

var CryptoJS = require("crypto-js");

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;
const dateFormat = 'DD/MM/YYYY';
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const tick = new UIfx(tickAudio,  {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100
});

const pStyle = {
  fontSize: 16,
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};


var notificationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/notificationsocket/');




const DescriptionItem = ({ title, content }) => (
  <div
    className="site-description-item-profile-wrapper"
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
    }}
  >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

var bizuserid= ''
var instance_id= ''
var token= ''


class SaccoLayout extends React.Component{

  state = {
    collapsed: false,
    theme: 'dark',
    current: '1',
    titlecolor: '#fff',
    titlevisibility:"visible",
    userprofile:{},
    companyprofile:{},
    isToggle: null,
    visible: false,
    resetmodalvisible:false,
    updateformvisible:'none',
    userrights:{},
    date_today:moment().format(dateFormat).toString(),
    dashboardlocked:false,
    userbalancemodalvisible:false,
    userto:'',
    transferamount:0,
    datarequested:false,
    transfermode:'',
    safemanagertransfermode:'',
    pendingtransfers:[],
    numberofnotifications:0,
    notificationinfo:'',
    balanceacceptancemodalvisible:false,
    selectedtransferobject:{},
    acceptancestatus:'',

    shownotificationmodal:false,
    notifications:[],

  };

  // show the profile modal
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // update user profile
  showUpdateForm=()=>{
    this.state.updateformvisible=="none"?
    this.setState({
      updateformvisible: "block",
    })
    :
    this.setState({
      updateformvisible: "none",
    })    
  }


  /////////////////////////////////////////////
  //PASSWORD RESET MODAL METHODS BEGIN HERE

  showpasswordresetModal = () => {
    this.setState({
      resetmodalvisible: true,
    });
  };

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ resetmodalvisible: false });

  };


  //show user balance modal
  showuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: true });
  }

  hideuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: false });
  }

  //show hide balance acceptance modal
  openbalanceacceptancemodalmodal=(record)=>{
    this.setState({ acceptancestatus: ''})
    //console.log(record)
    this.setState({balanceacceptancemodalvisible: true });
    this.setState({selectedtransferobject:record})

  }

  closebalanceacceptancemodalmodal=()=>{
    this.setState({balanceacceptancemodalvisible: false });

  }


//component did mount
  componentDidMount(){
    if(localStorage.getItem("instance_id")){
      instance_id= localStorage.getItem("instance_id")
    }else{
      instance_id= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //component mount method
    this.componentmountmethod();
    
  }
  

//component mount method
componentmountmethod=()=>{

  //get the user profile here
  axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}/`)
  .then(res => {
      this.setState({
        userprofile:res.data
      })
   
  })

  axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
  .then(res => {
      this.setState({
        companyprofile:res.data
      })
  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
     console.log("data: "+res.data[0] )
      this.setState({
        userrights:res.data[0]
      })
  })



    //destroy all previous notifications
    notification.destroy()

    axios.get(`${serverconfig.backendserverurl}/api/adminnotification?read=${false}`)
    .then(res => {

        console.log(res.data)
      //now loop through the tasks
      res.data.map(
        (ntfy)=>{
          notification.open({
            top:60,
            duration:0,
            message:  String(ntfy.title),
            description:String(ntfy.message),
            icon: <MessageFilled style={{ color:ntfy.escalation_level==="Normal"?"green":ntfy.escalation_level==="leveltwo"?"orange":"red" }} />,
          })
          
          this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})
        })

        this.setState({notifications:res.data})
    })



    //observer the socket changes now
    notificationsocket.onopen = () => {
      console.log("onopen for money notification");
    // connection opened
    };


    notificationsocket.onmessage = async (e) => {
        notification.destroy()

        // a message was received
        console.log("onmessage for stock",e.data);
        const data = JSON.parse(e.data);

        this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})

        //play notification
        tick.play();

        notification.open({
          top:60,
          duration:0,
          message:  data.title,
          description:data.message,
          icon: <MessageFilled style={{ color:data.escalation_level==="Normal"?"green":data.escalation_level==="leveltwo"?"orange":"red" }} />,
        })

    };

}



  //method for changing color theme 
  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });

    this.setState({
      titlecolor: value ? '#fff' : '#A9A9A9',
    });
    
  };

  //method for collupsing of the menu
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
    this.setState({titlevisibility: collapsed ? "hidden" :"visible"});
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    this.setState({titlevisibility:"hidden"});

  };


  handleusertoChange= (e) => this.setState({ userto: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});
  handletransfermodeChange= (e) => this.setState({ transfermode: e});
  handlesafemanagertransfermodeChange= (e) => this.setState({ safemanagertransfermode: e});
  handleacceptancestatusChange= (e) => this.setState({ acceptancestatus: e});



   render(){


    return (
      <div>
        {
         this.props.isAuthenticated ?
         <Layout style={{ minHeight: '100vh' }} >
           {
             this.state.dashboardlocked===false?
             <Sider
             collapsedWidth="0"
             collapsed={this.state.collapsed}
             onCollapse={this.onCollapse} 
             theme={this.state.theme}
             breakpoint="lg"
             onBreakpoint={broken => {
               console.log(broken);
             }}
             zeroWidthTriggerStyle={{display:'none'}}
             style={{width:400}}
             >
               <br></br>
              <div className="logo" style={{ paddingLeft: 30}} mode="inline">
              <Row>
   
                <Col span={6}>
   {/*               <Avatar size={40} icon={<UserOutlined />} alt="Logo" src={this.state.companyprofile.company_logo} />
           */}               
                <Image
                 width={50}
                 height={50}
                 src={this.state.companyprofile.company_logo}   
                 preview={false} 
                 />
                <h6 style={{display:'flex',color:'white',margin:3}}>{this.state.companyprofile.system_name}</h6> 

                </Col>
   {/*             <Col span={18}><NavLink to='/'><h5 style={{color:this.state.titlecolor,paddingTop: 10,visibility:this.state.collapsed?"hidden":"visible" }}>{this.state.companyprofile.company_name}</h5></NavLink></Col>
         */}           </Row>
              <br/>
              </div>
   
              <Menu 
              theme={this.state.theme}
              onClick={this.handleClick}
              defaultSelectedKeys={['1']} 
              mode="vertical">
   
               <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <HomeOutlined />
                      <span>Home</span>
                    </span>
                  }
                >
               <Menu.Item key="0" >
                 <RightCircleFilled />
                 <NavLink to='/'><span>Home</span></NavLink>
               </Menu.Item>
   
                <Menu.Item key="899900" >
                 <RightCircleFilled />
                 <NavLink to='/quickmenu/'><span>Quick Menu</span></NavLink>
   
               </Menu.Item> 
               </SubMenu>

                  {this.state.userrights.view_candidates?
               <SubMenu
                  key="sub44"
                  title={
                    <span>
                      <CalculatorFilled />
                      <span>Candidates</span>
                    </span>
                  }
                >
    
                  <Menu.Item key="70088" >
                    <RightCircleFilled />
                    <NavLink to='/recruits/'><span>Candidates</span></NavLink>
                  </Menu.Item>

                </SubMenu>
                :
                null}



                <SubMenu
                  key="sub44112"
                  title={
                    <span>
                      <CalculatorFilled />
                      <span>Medicals</span>
                    </span>
                  }
                >
    
                  {this.state.userrights.view_premedicals?
                  <Menu.Item key="7110088" >
                    <RightCircleFilled />
                    <NavLink to='/candidate_premidicals/'><span>Premedicals</span></NavLink>
                  </Menu.Item>
                  :null}
                  
                  {this.state.userrights.view_gccmedicals?
                  <Menu.Item key="71211110088" >
                    <RightCircleFilled />
                    <NavLink to='/candidate_gccmidicals/'><span>Gcc medicals</span></NavLink>
                  </Menu.Item>
                   :null}

                </SubMenu>


                {this.state.userrights.view_expenses?

               <SubMenu
                      key="sub4"
                      title={
                        <span>
                          <CalculatorFilled />
                          <span>Expenses</span>
                        </span>
                      }
                    >
    
                  <Menu.Item key="7" >
                    <RightCircleFilled />
                    <NavLink to='/expenses/'><span>Expenses</span></NavLink>
                  </Menu.Item>
      
                </SubMenu>
                :null}

                {
                  this.state.userrights.can_view_incomes?
                    <SubMenu
                    key="sub5"
                    title={
                      <span>
                        <DollarCircleOutlined />
                        <span>Incomes</span>
                      </span>
                    }
                  >

                <Menu.Item key="8" >
                  <RightCircleFilled />
                  <NavLink to='/incomes/'><span>Incomes</span></NavLink>
                </Menu.Item>
    
                </SubMenu>
                  :
                  null
                }



              {this.state.userrights.view_trainingschedules?
                <SubMenu
                      key="sub6"
                      title={
                        <span>
                          <CalculatorFilled />
                          <span>Training</span>
                        </span>
                      }
                    >
    
                  <Menu.Item key="7001" >
                    <RightCircleFilled />
                    <NavLink to='/candidatestraining/'><span>Candidates training</span></NavLink>
                  </Menu.Item>
      
                </SubMenu>:
                null}

                <SubMenu
                      key="sub11"
                      title={
                        <span>
                          <CalculatorFilled />
                          <span>Bookings</span>
                        </span>
                      }
                    >
                 {this.state.userrights.view_interpol?
                  <Menu.Item key="7111888001" >
                    <RightCircleFilled />
                    <NavLink to='/candidate_interpols/'><span>Candidates interpol</span></NavLink>
                  </Menu.Item>
                  :
                  null}
                 

                {this.state.userrights.view_visas?
                <Menu.Item key="711142542001" >
                    <RightCircleFilled />
                    <NavLink to='/candidate_visas/'><span>Candidates visas</span></NavLink>
                  </Menu.Item>
                  :
                  null}

                {this.state.userrights.view_tickets?
                <Menu.Item key="71114254200001" >
                    <RightCircleFilled />
                    <NavLink to='/candidate_tickets/'><span>Candidates tickets</span></NavLink>
                  </Menu.Item>
                   :
                   null}

                </SubMenu>


          {this.state.userrights.view_contracts?
                <SubMenu
                      key="sub111"
                      title={
                        <span>
                          <CalculatorFilled />
                          <span>Contracts</span>
                        </span>
                      }
                    >
    
                  <Menu.Item key="7111001" >
                    <RightCircleFilled />
                    <NavLink to='/candidate_contracts/'><span>Candidates contracts</span></NavLink>
                  </Menu.Item>

                  <Menu.Item key="7__22111001" >
                    <RightCircleFilled />
                    <NavLink to='/candidate_contract_modifications/'><span>Modifications/ actions</span></NavLink>
                  </Menu.Item>
      
                </SubMenu>:
                null}

                


            {this.state.userrights.view_candidatereports?
                <SubMenu
                      key="sub111111"
                      title={
                        <span>
                          <CalculatorFilled />
                          <span>Candidate reports</span>
                        </span>
                      }
                    >
    
                  <Menu.Item key="71114254200001" >
                    <RightCircleFilled />
                    <NavLink to='/cordinator_reports/'><span>Cordinator reports</span></NavLink>
                  </Menu.Item>

                  <Menu.Item key="7111425420000991" >
                    <RightCircleFilled />
                    <NavLink to='/candidate_issues_report/'><span>Candidate issues from App</span></NavLink>
                  </Menu.Item>
      
                </SubMenu>
                :
                null}

               
               {
                  this.state.userprofile.branch_manager?
                  <SubMenu
                  key="sub222"
                  title={
                    <span>
                      <TeamOutlined />
                      <span>Partners</span>
                    </span>
                  }
                >
          
               <Menu.Item key="3888" >
                 <NavLink to='/personalbrokers/'><span>Personal agents</span></NavLink>
               </Menu.Item>

               <Menu.Item key="38888" >
                 <NavLink to='/companybrokers/'><span>Local recruitment partners</span></NavLink>
               </Menu.Item>
               
               <Menu.Item key="3888899" >
                 <NavLink to='/recruitmentagencies/'><span>Foreign recruitment partners</span></NavLink>
               </Menu.Item>

               <Menu.Item key="388889999" >
                 <NavLink to='/transportpartners/'><span>Transport partners</span></NavLink>
               </Menu.Item>

               <Menu.Item key="388833389999" >
                 <NavLink to='/medical_facilities/'><span>Medical facilities</span></NavLink>
               </Menu.Item>

               <Menu.Item key="388222833389999" >
                 <NavLink to='/training_facilities/'><span>Training facilities</span></NavLink>
               </Menu.Item>

               </SubMenu>
                  :
                  null
                } 


               {
                  this.state.userprofile.branch_manager?
                  <SubMenu
                  key="sub8"
                  title={
                    <span>
                      <SettingOutlined />
                      <span>Settings</span>
                    </span>
                  }
                >

               <Menu.Item key="38" >
                 <NavLink to='/useraccounts/'><span>User Accounts</span></NavLink>
               </Menu.Item>

               <Menu.Item key="388">
                 <NavLink to='/skills/'><span>Skills</span></NavLink>
               </Menu.Item>

               <Menu.Item key="38822">
                 <NavLink to='/resources/'><span>Resources</span></NavLink>
               </Menu.Item>

               <Menu.Item key="38811122">
                 <NavLink to='/expensecategories/'><span>Expense categories</span></NavLink>
               </Menu.Item>

               <Menu.Item key="112228" >
                 <NavLink to='/incomecategories/'><span>Income Categories</span></NavLink>
               </Menu.Item>  

               <Menu.Item key="3811811122">
                 <NavLink to='/companyaccounts/'><span>Company accounts</span></NavLink>
               </Menu.Item>

               <Menu.Item key="381181163673122">
                 <NavLink to='/ugdistricts/'><span>Ugandan district</span></NavLink>
               </Menu.Item>

               <Menu.Item key="3811111181163673122">
                 <NavLink to='/candidate_conditions/'><span>Preselected conditions</span></NavLink>
               </Menu.Item>
               
               </SubMenu>

                :
                null
              } 

              
          {
                  this.state.userprofile.branch_manager?
               <SubMenu
                  key="sub9"
                  title={
                    <span>
                      <FilePdfFilled />
                      <span>Financial reports</span>
                    </span>
                  }
                >


                  <Menu.Item key="23" >
                  <NavLink to='/tillsheet/'><span>Journal</span></NavLink>
                  </Menu.Item>                  
               
                  <Menu.Item key="221123" >
                  <NavLink to='/ledgerreport/'><span>Ledger</span></NavLink>
                  </Menu.Item>                  
               
                  <Menu.Item key="22123" >
                  <NavLink to='/trialbalance/'><span>Trial Balance</span></NavLink>
                  </Menu.Item>     

                  <Menu.Item key="220099123" >
                  <NavLink to='/incomestatement/'><span>Income statement</span></NavLink>
                  </Menu.Item>               
               
               </SubMenu>
               :
               null}
   
              </Menu>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
   
            </Sider>
             :
             null
           }
        
         <Layout className="site-layout">
   
            <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {className: 'trigger',
              onClick: this.toggle,
            })}
              extra={[
                <Badge  onClick={()=>{
                  this.setState({shownotificationmodal:true})
              
                }}  pill color="danger">{this.state.numberofnotifications}</Badge>,
                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={serverconfig.backendserverurl+this.state.userprofile.profile_pic} />,
                <Dropdown.Button overlay={(    <Menu >
                  <Menu.Item key="1" onClick={this.props.logout}>
                    <LogoutOutlined />
                    Log out
                  </Menu.Item>
                  <Menu.Item key="2" onClick={this.showDrawer}>
                    <UserOutlined />
                    View Profile
                  </Menu.Item>
                  <Menu.Item key="3" onClick={this.showpasswordresetModal}>
                    <KeyOutlined />
                    Change Password
                  </Menu.Item>
                  
                </Menu>)}>
                Hi, {this.state.userprofile.username}
                </Dropdown.Button>
              ]}
              >
          </PageHeader>
        

           <Content style={{ margin: '0 16px' }}>
             <br></br>
             {
               this.state.notificationinfo===""?
               null
               :
               <NavLink >
                <Alert showIcon message={this.state.notificationinfo} type="info" onClick={()=>{this.showuserbalancemodalvisible()}} />
               </NavLink>
             }

             <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
             <br></br>

             <div style={{display:'flex',flexDirection:'row'}}>
              
              {
              this.props.location.pathname==='/'?
              null:
              <Affix>

              <Tooltip title="Go to home">
              <HomeOutlined style={{display:'flex',fontSize: 30,marginRight:30}} onClick={()=>{
                this.props.history.push('/')      
                }}/>
              </Tooltip>
              </Affix>
               }


                {
                  this.props.location.pathname==='/'?
                  null:
                  <Affix>
                  <Tooltip title="Go back">
                  <ArrowLeftOutlined style={{display:'flex',fontSize: 30,marginRight:5}} onClick={()=>{
                    this.props.history.goBack()      
                    }}/>
                  </Tooltip>
                  </Affix>
                }

              </div>
              <br></br>
             
             {this.props.children}
             </div>
           </Content>
           <Footer style={{ textAlign: 'center' }}>System Design © 2021 Created by PI-Tech</Footer>
         </Layout>
       </Layout>
         :
         null
        }

        <Drawer
          title="Account Profile"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Avatar
              size={100}
              icon={<UserOutlined />}
              alt="client logo"
              src={serverconfig.backendserverurl+this.state.userprofile.profile_pic}
              />
          </p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="User Name" content={this.state.userprofile.username} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Email" content={this.state.userprofile.email} />
            </Col>
          </Row>         
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Button type="primary" onClick={this.showUpdateForm}>
               {
                 this.state.updateformvisible=="none"?
                 "Open Update Form"
                 :
                 "Close Update Form"
               }
               
               </Button>
          </p>
          
          <div style={{display:this.state.updateformvisible}}>
           <UserAccountUpdateForm accountID={this.state.userprofile.id} username={this.state.userprofile.username} email={this.state.userprofile.email}/>       

          </div>
        </Drawer>
        

        <Drawer
          title="Password Change Form"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onresetdrawerClose}
          visible={this.state.resetmodalvisible}
        >
            <WrappedPasswordResetForm />

        </Drawer>


        <Modal            
          visible={this.state.shownotificationmodal}
          title="Notifications"
          onCancel={()=>{
            this.setState({shownotificationmodal:false})

            //mark them as read
            this.state.notifications.map((item)=>{
              let form_data = new FormData();
              form_data.append('read', true);

              axios.put(serverconfig.backendserverurl+`/api/adminnotification/${item.id}/`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
              .then(res => {
                console.log(res.data)
              })

            })

          }}
      
          footer={[
            <Button key="back" onClick={()=>{
              this.setState({shownotificationmodal:false})

              console.log(this.state.notifications)
              //mark them as read
              this.state.notifications.map((item)=>{
                console.log(item.id)
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/adminnotification/${Number(item.id)}`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })

              }) 

            }}>
              Cancel
            </Button>
            ]}
            >

          {this.state.notifications.map(
            (note)=>{
              return(<Alert closable style={{margin:6}} showIcon message={note.title} description={note.message} type={note.escalation_level==="Normal"?"success":note.escalation_level==="Critical"?"error":"warning"} />);
              
            }

          )}
          
        </Modal>  
    </div>
  
  );

  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(
      actions.logout(),
      )
  }
}


export default withRouter(connect(null,mapDispatchToProps)(SaccoLayout)) ;
