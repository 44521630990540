import React from 'react';
import { Form, Input, Button,Spin,DatePicker,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import moment from 'moment';

import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''

class CandidateTrainingEditForm extends React.Component {

  state = {
    start_date:'' ,  
    candidate:'',
    end_date:'',
    training_school:'',
    traing_details:'',
    attachment:null,
    datarequested:true,
    recruits:[],
    selected_recruit:'',
    training_facilities:[],
    status:'',
    companyprofile:{},

  }

  componentDidMount(){
    const trainingID=this.props.match.params.trainingID;
   
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/training_facilities/`)
    .then(res => {
        this.setState({
          training_facilities:res.data
        })
     //   this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/recruits/`)
    .then(res => {
        this.setState({
          recruits:res.data
        })

        //this.setState({datarequested:false})
    })

    axios.get(`${serverconfig.backendserverurl}/api/candidate_trainings/${trainingID}`)
    .then(res => {  
        this.setState({
            idtype: res.data
        })

        this.setState({start_date:res.data.start_date})
        this.setState({candidate:res.data.candidate})
        this.setState({end_date:res.data.end_date})
        this.setState({training_school:res.data.training_school})
        this.setState({traing_details:res.data.traing_details})
        this.setState({status:res.data.status})

        this.setState({datarequested:false})

    })

    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    const trainingID=this.props.match.params.trainingID;

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('start_date', this.state.start_date);
    form_data.append('candidate', this.state.candidate);
    form_data.append('end_date', this.calculateend_date());
    form_data.append('training_school', this.state.training_school);
    form_data.append('traing_details', this.state.traing_details);
    form_data.append('status', this.state.status);

    axios.put(serverconfig.backendserverurl+`/api/candidate_trainings/${trainingID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>  {

      //now update the object reource file
      let form_data1 = new FormData();
      form_data1.append('pri_id', trainingID);
      form_data1.append('update_type', "training");

      this.state.attachment==null?
      console.log("No pre attachmnet file")
      :
      form_data1.append('attachment', this.state.attachment, this.state.attachment?this.state.attachment.name:"");

      axios.post(serverconfig.backendserverurl+'/customqueries/late_fileupload', form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      }).catch(error => console.log(error))
      //end the object file upload


  })
  .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});


//calculate the end date
calculateend_date=()=>{
  var end_date=''
  var no_days=Number(this.state.companyprofile.training_no_of_weeks)*7

  if (this.state.start_date!=""){
    var dataSplit = this.state.start_date.split('/');
    var dateConverted;
    if (dataSplit[2].split(" ").length > 1) {

        var hora = dataSplit[2].split(" ")[1].split(':');
        dataSplit[2] = dataSplit[2].split(" ")[0];
        dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);

    } else {
        dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
    }

    var year = dateConverted.getFullYear();
    var month = dateConverted.getMonth();
    var date = dateConverted.getDate();

    var newdate=new Date(year,month,date+no_days).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');

    end_date=newdate
  }


  return end_date;
}


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>   
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

          <FormItem label="Start date"
           
          >
              <DatePicker value={moment()} onChange={(date, dateString) => this.setState({start_date: dateString})} format={dateFormat} />
          </FormItem>

          <h4>End date: {this.calculateend_date()}</h4>

{/*          <FormItem label="End date"
         
          >
              <DatePicker value={moment()} onChange={(date, dateString) => this.setState({end_date: dateString})} format={dateFormat} />
          </FormItem>*/}


        <FormItem label="Select Candidate">
          <Select 
          placeholder="Candidate" 
          style={{  }} 
          value={this.state.candidate}
          onChange={(val)=>{this.setState({candidate:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.recruits.map(
              (accnt)=>(
                <Option value={accnt.id}>{accnt.FullName} | {accnt.recruit_number}</Option>
              ))}
          </Select>
      </FormItem>


      <FormItem label="Training school"
        
      >
      
       <Select 
          placeholder="Facility" 
          style={{  }} 
          value={this.state.training_school}
          onChange={(val)=>{this.setState({training_school:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.training_facilities.map(
              (accnt)=>(
                <Option value={accnt.name}>{accnt.name}</Option>
              ))}
          </Select>
      </FormItem>

      <FormItem label="Training details"
         
      >
        <Input name="traing_details"  placeholder="traing_details here" value={this.state.traing_details} onChange={(val)=>{this.setState({traing_details:val.target.value})}} />
      </FormItem>


      <FormItem label="Attachment" style={{marginRight:3}}>
        <Input type="file" accept=".pdf" placeholder="file attachment"  
        onChange={async(e) =>{
          this.setState({ attachment: e.target.files[0]});
        } }/>
      </FormItem> 

      <FormItem label="Status"
      >
        <Select 
          value={this.state.status} 
          onChange={(val)=>{this.setState({status:val})}}
          placeholder="Status" >
          <Option value='Ongoing'>Ongoing</Option>
          <Option value='Passed'>Passed</Option>
          <Option value='Failed'>Failed</Option>  
          </Select>
      </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default CandidateTrainingEditForm;



