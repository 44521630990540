import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image,Progress, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined,WarningFilled,CheckCircleFilled } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var bizuserid= ''

class CordinatorReportForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    report_details:'',
    case_obj:{},
    attachment:null,
    userprofile:{},

    percentage_uploaded:0,
    data_uploading:false,
    report_title:'',
    escalation_level:'Normal',
    candidate:'',

    recruits:[],
    selected_recruit:'',
    candidate_conditions:[]

  }

  componentDidMount(){

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/candidate_conditions/`)
    .then(res => {
        this.setState({
            candidate_conditions:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/recruits/?coordinator=${bizuserid}`)
    .then(res => {
        this.setState({
          recruits:res.data
        })

        this.setState({datarequested:false})
    })

}


  //submit button pressed
  handleFormSubmit=(event) =>{

        //progress tracker
        const upload_config = {
          onUploadProgress: (progressEvent)=> {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log(percentCompleted)
            this.setState({percentage_uploaded:percentCompleted})
          }
        }

        //first get the noumber here
        let form_data_1 = new FormData();
        form_data_1.append('action', "get_number");
        axios.post(serverconfig.backendserverurl+'/customqueries/getautogenerated_reportno', form_data_1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        if (res.data.message==="success"){
          console.log("got account: " +String(res.data.account_no))
          var  account_no=String(res.data.account_no)

          this.setState({data_uploading:true})
  
          let form_data = new FormData();
          form_data.append('date', this.state.date);
          form_data.append('report_details', this.state.report_details);
          form_data.append('report_title', this.state.report_title);
          form_data.append('cordinator', bizuserid);
          form_data.append('candidate', this.state.candidate);
          form_data.append('report_no', account_no);
          form_data.append('escalation_level', this.state.escalation_level);

          axios.post(serverconfig.backendserverurl+'/api/cordinator_reports/', form_data,upload_config, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(res =>{

            //now update the object reource file
            let form_data2 = new FormData();
            form_data2.append('pri_id', res.data.new_object_id);
            form_data2.append('update_type', "coreport");

            this.state.attachment==null?
            console.log("No pre attachmnet file")
            :
            form_data2.append('attachment', this.state.attachment, this.state.attachment?this.state.attachment.name:"");

            axios.post(serverconfig.backendserverurl+'/customqueries/late_fileupload', form_data2, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
            .then(res => {
              //this.setState({datarequested:false})
              //window.location.reload(false)
            }).catch(error => console.log(error))
            //end the object file upload



            //check if automated 
            let form_data_1 = new FormData();
            form_data_1.append('action', "update_number");
            axios.post(serverconfig.backendserverurl+'/customqueries/getautogenerated_reportno', form_data_1, {
            headers: {
              'content-type': 'multipart/form-data'
            }
            })
            .then(res => {
            console.log("numbers updated")
            })
            .catch(error => console.log(error))

            this.setState({data_uploading:false})
            console.log(res.data)
            this.setState({datasubmittedsuccessfully:true})

          })
          .catch(error => console.log(error))


        }else{
          message.error("Failed")
        }

      })

  }

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>       
         </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully created report"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >

                  <FormItem label="Select Candidate">
                    <Select 
                    placeholder="Candidate" 
                    style={{  }} 
                    value={this.state.candidate}
                    onChange={(val)=>{this.setState({candidate:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}              
                    >
                      {this.state.recruits.map(
                        (accnt)=>(
                          <Option value={accnt.id}>{accnt.FullName} | {accnt.recruit_number}</Option>
                        ))}
                    </Select>
                </FormItem>


                  <FormItem label="Report Title"
                    name="report_title"
                    rules={[
                      {
                        required: true,
                        message: 'Please title',
                      },
                    ]}             
                  >
               
                    <Select 
                    placeholder="Report title" 
                    style={{  }} 
                    value={this.state.report_title}
                    onChange={(val)=>{this.setState({report_title:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}              
                    >
                      {this.state.candidate_conditions.map(
                        (accnt)=>(
                          <Option value={accnt.name}>{accnt.name}</Option>
                        ))}
                    </Select>
                  </FormItem>


                  <FormItem label="Report details">

                  <CKEditor
                  editor={ClassicEditor}
                  onChange={(e,editor)=>{
                    this.setState({report_details:editor.getData()})
                  }}
                  data={"<h1><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; REPORT </strong></h1>"}
                  onInit={ editor => {
                    console.log( 'Editor is ready to use!', editor );
                  } }
                  >
                  </CKEditor>
                  </FormItem>

                  <FormItem label="Escalation level">
                    <Select 
                      value={this.state.escalation_level} 
                      onChange={(val)=>{this.setState({escalation_level:val})}}
                      placeholder="escalation_level" >
                      <Option value='Normal'>Level 1 (Normal)</Option>
                      <Option value='leveltwo'>Level 2 (Intermediate)</Option>
                      <Option value='Critical'>Level 3 (Critical)</Option>
                      </Select>
                  </FormItem>


                  <FormItem label="File attachment" style={{marginRight:3}}
                  >
                        <Input type="file" accept=".pdf" placeholder="file"  
                        onChange={(e)=>{ 
                          this.setState({attachment:e.target.files[0]})
                        
                      }}/>
                  </FormItem> 

                  <FormItem >
                    <Button  type='primary' htmlType="submit">Submit</Button>
                  </FormItem>

                  {
                    this.state.data_uploading===true?
                    <div style={{display:'flex',alignSelf:'center',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
                    <Progress type="circle" percent={this.state.percentage_uploaded} />
                    </div>
                    :
                    null
                  }
      
                </Form>
              </div>
            );
      }

    }
    
  }

}

export default CordinatorReportForm;

