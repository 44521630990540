import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
const { Option } = Select;

class CandidateAppConditionForm  extends React.Component {

  state = {
    name:'' ,  
    description:'',
    datarequested:false,
    escalation_level:''
  }

  componentDidMount(){
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('description', this.state.description);
    form_data.append('escalation_level', this.state.escalation_level);

      axios.post(serverconfig.backendserverurl+'/api/candidateapp_conditions/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Condition Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>

      <FormItem label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input description',
          },
        ]}    
      >
        <Input name="description"  placeholder="description" value={this.state.description} onChange={(val)=>{this.setState({description:val.target.value})}} />
      </FormItem>

      <FormItem label="Escalation level"
      name="escalation_level"
      rules={[
        {
          required: true,
          message: 'Please select level',
        },
      ]} 
      >
        <Select 
          value={this.state.escalation_level} 
          onChange={(val)=>{this.setState({escalation_level:val})}}
          placeholder="escalation_level" >
          <Option value='Normal'>Level 1 (Normal)</Option>
          <Option value='leveltwo'>Level 2 (Intermediate)</Option>
          <Option value='Critical'>Level 3 (Critical)</Option>
        </Select>
      </FormItem>
     
      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
      </FormItem>
       </Form>
        </div>
      );

    }
   
  }

}


export default CandidateAppConditionForm ;



