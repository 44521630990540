import React from 'react';
import { Form, Input, Button,Spin,Image } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''


class PersonalBrokerDetails extends React.Component {

  state = {
    fullnames:'' ,  
    national_id:'',
    telephone:'',
    date_ofregistration:'',
    Photo:null,
    Photo_url:'',
    datarequested:false,
  }

  componentDidMount(){
    const brokerID=this.props.match.params.brokerID;


    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/personalbrokers/${brokerID}`)
    .then(res => {  
        this.setState({
            idtype: res.data

        })

        this.setState({fullnames:res.data.fullnames})
        this.setState({national_id:res.data.national_id})
        this.setState({telephone:res.data.telephone})
        this.setState({date_ofregistration:res.data.date_ofregistration})

        this.setState({datarequested:false})

    })



  }


  //imgae resizer
  resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      400,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });


  //convert back to file
  dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };



  //submit button pressed
  handleFormSubmit=(event) =>{
    const brokerID=this.props.match.params.brokerID;

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('fullnames', this.state.fullnames);
    form_data.append('national_id', this.state.national_id);
    form_data.append('telephone', this.state.telephone);
    form_data.append('date_ofregistration', moment().format(dateFormat).toString());

    this.state.Photo==null?
    console.log("No profile file")
    :
    form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");

    axios.put(serverconfig.backendserverurl+ `/api/personalbrokers/${brokerID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>        
       </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Full names"
      
      >
        <Input name="fullnames"  placeholder="Put names here." value={this.state.fullnames} onChange={(val)=>{this.setState({fullnames:val.target.value})}} />
      </FormItem>

      <FormItem label="National id"
       
      >
        <Input name="national_id"  placeholder="Put national id here." value={this.state.national_id} onChange={(val)=>{this.setState({national_id:val.target.value})}} />
      </FormItem>

      <FormItem label="Telephone"
       
      >
        <Input name="telephone"  placeholder="Put telephone here." value={this.state.telephone} onChange={(val)=>{this.setState({telephone:val.target.value})}} maxLength={10} />
      </FormItem>


      <div style={{display:'flex',flexDirection:'row'}}>
        <FormItem label="Photo" style={{marginRight:3}}>
              <Input type="file" accept="image/png, image/jpeg"
               placeholder="agent Photo" 
                onChange={async(e) =>{
    
                  //this.setState({ Photo: e.target.files[0]});
              
                  if(e.target.files[0]){
                    this.setState({
                      photofile: URL.createObjectURL(e.target.files[0])
                    })
                  }
              
                  const file = e.target.files[0];
                  //send to resizer
                  const image = await this.resizeFile(file);
                  const newFile = this.dataURIToBlob(image);
                  this.setState({ Photo: newFile});
                  console.log(newFile);
              
                } 
              }/>
        </FormItem> 

        {this.state.Photo?
        <Image
        width={50}
        height={50}
        src={this.state.photofile}    
        />
        :
        null
        }

        </div>


      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default PersonalBrokerDetails;



