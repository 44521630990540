import React from 'react';

import RecruiteeNonQualifiedList from './RecruteesListView_NonQualified'
import RecruiteeQualifiedList from './RecruteesListView_Qualified'
import RecruitList_ByCandidateList from './RecruteesListView_ByCandidate'


import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin, Card,Tooltip,Image,Avatar,Empty,Tabs,Form,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,EditOutlined,WalletFilled,LoadingOutlined,EditFilled,AliyunOutlined,UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from 'moment';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

const { RangePicker } = DatePicker;


function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var ngoid= ''
var bizuserid= ''
var token= ''

class RecruiteeList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    recruits: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:false,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},
    loaded_image:false,
    current_selected_candidate:'',
    loan_particular_data_loading:false,
    dateone:'',
    datetwo:'',

  };

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

}

    
  render() {
   
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin size="large">
            <div className="content" />
          </Spin>           
        </div>
      )

    }else{
      return(
     <div>

        <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Pending qualification" key="01" >
          <RecruiteeNonQualifiedList />
        </TabPane>

        <TabPane tab="Qualified Candidates" key="02" >
          <RecruiteeQualifiedList />
        </TabPane>

        </Tabs>
    </div>
    )

    }

  }
}

export default RecruiteeList; 
