import React from 'react';
import { Form, Input, Button,Spin,DatePicker,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import moment from 'moment';

import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''

class CandidateVisaEditForm extends React.Component {

  state = {
    date_of_application:'' ,  
    candidate:'',
    date_accepted:'',
    medical_facility:'',
    doctor_name:'',
    attachment:null,
    datarequested:true,
    recruits:[],
    selected_recruit:'',
    doctor_contact:'',
    more_details:'',
    status:'Pending',
  }


  componentDidMount(){
    const visaID=this.props.match.params.visaID;

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/recruits/`)
    .then(res => {
        this.setState({
          recruits:res.data
        })

      //  this.setState({datarequested:false})

    })

    axios.get(`${serverconfig.backendserverurl}/api/candidate_visas/${visaID}`)
    .then(res => {  
       
        this.setState({date_of_application:res.data.date_of_application})
        this.setState({date_accepted:res.data.date_accepted})
        this.setState({candidate:res.data.candidate})
        this.setState({status:res.data.status})
        this.setState({more_details:res.data.more_details})
        this.setState({datarequested:false})

    })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    const visaID=this.props.match.params.visaID;

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('date_of_application', this.state.date_of_application);
    form_data.append('date_accepted', this.state.date_accepted);
    form_data.append('status', this.state.status);
    form_data.append('candidate', this.state.candidate);
    form_data.append('more_details', this.state.more_details);

    axios.put(serverconfig.backendserverurl+`/api/candidate_visas/${visaID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>  {

      //now update the object reource file
      let form_data1 = new FormData();
      form_data1.append('pri_id', visaID);
      form_data1.append('update_type', "visa");

      this.state.attachment==null?
      console.log("No pre attachmnet file")
      :
      form_data1.append('attachment', this.state.attachment, this.state.attachment?this.state.attachment.name:"");

      axios.post(serverconfig.backendserverurl+'/customqueries/late_fileupload', form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      }).catch(error => console.log(error))
      //end the object file upload


  })
  .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>   
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

          <FormItem label={"Date of application ("+this.state.date_of_application+")"}
          
          >
              <DatePicker onChange={(date, dateString) => this.setState({date_of_application: dateString})} format={dateFormat} />
          </FormItem>


          <FormItem label={"Date accepted ("+this.state.date_accepted+")"}
          >
              <DatePicker onChange={(date, dateString) => this.setState({date_accepted: dateString})} format={dateFormat} />
          </FormItem>

       
        <FormItem label="Select Candidate">
          <Select 
          placeholder="Candidate" 
          style={{  }} 
          value={this.state.candidate}
          onChange={(val)=>{this.setState({candidate:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.recruits.map(
              (accnt)=>(
                <Option value={accnt.id}>{accnt.FullName} | {accnt.recruit_number}</Option>
              ))}
          </Select>
      </FormItem>

      <FormItem label="Status"
      >
        <Select 
          value={this.state.status} 
          onChange={(val)=>{this.setState({status:val})}}
          placeholder="Status" >
          <Option value='Pending'>Pending</Option>
          <Option value='Accepted'>Accepted</Option>
          <Option value='Denied'>Denied</Option>  
          </Select>
      </FormItem>

      <FormItem label="More details"
      
      >
        <Input name="more_details"  placeholder="more_details here" value={this.state.more_details} onChange={(val)=>{this.setState({more_details:val.target.value})}} />
      </FormItem>

      <FormItem label="Attachment" style={{marginRight:3}}>
        <Input type="file" accept=".pdf" placeholder="file attachment"  
        onChange={async(e) =>{
          this.setState({ attachment: e.target.files[0]});
        } }/>
      </FormItem> 

      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default CandidateVisaEditForm;



