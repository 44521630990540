import React from 'react';
import { Form, Input, Button,Spin,Image } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''


class CompanyBrokerForm extends React.Component {

  state = {
    companynames:'' ,  
    location:'',
    telephone:'',
    date_ofregistration:'',
    company_regsitration_no:'',
    Photo:null,
    Photo_url:'',
    datarequested:false,
    email_address:''
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }




  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    
    let form_data = new FormData();
    form_data.append('companynames', this.state.companynames);
    form_data.append('location', this.state.location);
    form_data.append('telephone', this.state.telephone);
    form_data.append('company_regsitration_no', this.state.company_regsitration_no);
    form_data.append('email_address', this.state.email_address);

    form_data.append('date_ofregistration', moment().format(dateFormat).toString());

    axios.post(serverconfig.backendserverurl+'/api/companybrokers/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>        
       </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Company names"
        name="companynames"
        rules={[
          {
            required: true,
            message: 'Please input companynames',
          },
        ]}
      >
        <Input name="companynames"  placeholder="Put names here." value={this.state.companynames} onChange={(val)=>{this.setState({companynames:val.target.value})}} />
      </FormItem>

      <FormItem label="Company location"
        name="location"
        rules={[
          {
            required: true,
            message: 'Please input location',
          },
        ]}
      >
        <Input name="location"  placeholder="Put location here." value={this.state.location} onChange={(val)=>{this.setState({location:val.target.value})}} />
      </FormItem>

      <FormItem label="Telephone"
        name="telephone"
        rules={[
          {
            required: true,
            message: 'Please input telephone',
          },
        ]}
      >
        <Input name="telephone"  placeholder="Put telephone here." value={this.state.telephone} onChange={(val)=>{this.setState({telephone:val.target.value})}} maxLength={10} />
      </FormItem>

      <FormItem label="company regsitration_no"
        name="company_regsitration_no"
        rules={[
          {
            required: true,
            message: 'Please input company_regsitration_no',
          },
        ]}
      >
        <Input name="company_regsitration_no"  placeholder="Put company_regsitration_no here." value={this.state.company_regsitration_no} onChange={(val)=>{this.setState({company_regsitration_no:val.target.value})}} />
      </FormItem>

      <FormItem label="company email_address"
        name="email_address"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input email_address',
          },
        ]}
      >
        <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />}    name="email_address"  placeholder="Put email_address here." value={this.state.email_address} onChange={(val)=>{this.setState({email_address:val.target.value})}} />
      </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default CompanyBrokerForm;



