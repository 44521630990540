import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, 
   AccountBookOutlined,CalculatorFilled,
   SwapOutlined,GroupOutlined,AccountBookFilled,
    DollarCircleOutlined,EditFilled,MessageFilled
    ,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled,TeamOutlined,FilePdfFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../../serverconn'
import * as serversocketconfig from '../../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import * as primarycolor from '../../primarycolor'
import QuickMenu from './QuickMenu';

import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import BloodtypeSharpIcon from '@mui/icons-material/BloodtypeSharp';
import LocalHospitalSharpIcon from '@mui/icons-material/LocalHospitalSharp';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import OpacityIcon from '@mui/icons-material/Opacity';
import TwoWheelerSharpIcon from '@mui/icons-material/TwoWheelerSharp';
var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var instance_id= ''
var token= ''


class DashBoard extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    nonworkingday:{},
    depositsno:0,
    withdrawsno:0,
    depositvolume:0,
    withdrawvolume:0,
    tranquerymode:'Daily',
    expensevolumes:0,
    incomevolumes:0,
    datareload:true,
    withdrawpercentage:0,
    withdrwdiff:0,
    depositpercentage:0,
    depositdiff:0,
    percenategincomes:0,
    incomediff:0,
    percenategexpnses:0,
    expensdiff:0,
    weeklydays:[],
    weeklydeposits:[],
    weeklywithdraws:[],
    weeklyincomes:[],
    weeklyexpenses:[],

    weeklychartdata:[],
    weeklychartitems:[],

    graphtranquerymode:'weekly',
    loanproccessuerymode:'daily',

    totalapplications:0,
    totalapplicationamounts:0,
    totalassessments:0,
    totalassessmentsamounts:0,
    totalapprovals:0,
    totalapprovalsamounts:0,
    totaldisbursements:0,
    totaldisbursementsamounts:0,

    projectedmonths:"three",
    monthsprojected:[],
    projectedinterest:[],
    projectedprinciple:[],

    applicationvsdates:[],
    applicationvs:[],
    issuancesvs:[],
    applicvsmode:'weekly',

    total_of_agents:0,

    healthcentres:[],
    collectionhubs:[],
    samples:[],
    supplies:[],
    healthcentres:[],
    delivery_agents:[],
    pending_deliveries:[],
    recruits:[],
    personalbrokers: [],
    candidate_contracts: [],
    recruitment_agencies:[],
    tranquerymode:'Daily',
    stat_totals:{},
    userrights:{},



  };



  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){

    if(localStorage.getItem("instance_id")){
      instance_id= localStorage.getItem("instance_id")
    }else{
      instance_id= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
   .then(res => {
       this.setState({
        userprofile:res.data
       })
   })


   axios.get(serverconfig.backendserverurl+`/api/app_profile/${1}`)
   .then(res => {
       this.setState({
         companyprofile:res.data
       })

       this.setState({datarequested:false})
   })


   axios.get(serverconfig.backendserverurl+`/api/recruits`)
   .then(res => {
       this.setState({recruits:res.data})
   })


   axios.get(serverconfig.backendserverurl+`/api/personalbrokers`)
   .then(res => {
       this.setState({
           personalbrokers:res.data
       })
   })

   axios.get(serverconfig.backendserverurl+`/api/candidate_contracts`)
   .then(res => {
       this.setState({
           candidate_contracts:res.data
       })
   })

   axios.get(serverconfig.backendserverurl+`/api/recruitment_agencies`)
   .then(res => {
       this.setState({
           recruitment_agencies:res.data
       })
   })


    //get first statistics
    let form_data = new FormData();
    form_data.append('tranquerymode', this.state.tranquerymode);

    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/gettransactiontatistics', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({stat_totals:res.data.feedback})
      this.setState({datareload:false})

    })
    .catch(error => console.log(error)) 


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })


}


//get stat data
gettransactiondata=(val)=>{
  this.setState({datareload:true})

  //get first statistics
  let form_data = new FormData();
  form_data.append('tranquerymode', val);

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/gettransactiontatistics', form_data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
    this.setState({stat_totals:res.data.feedback})
    this.setState({datareload:false})

  })
  .catch(error => console.log(error)) 


}



render() {

if(this.state.dashboardlocked===true){
  return(
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
    <h3 style={{fontWeight:'bolder',color:'red'}}>NOTE: Your dashboard is currently locked. Contact system adminstrators.Your Licence may be expired or some thing else. Thanks</h3>
    </div>
  )
}else{

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.userprofile.branch_manager===true){
        return(
          <div className="animated fadeIn">  
          <h3 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>{this.state.companyprofile.system_name}</h3>

          <Row>

        {
          this.state.userrights.view_candidates===true?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <NavLink to='/recruits/'> 
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                
                  <UserOutlined style={{color:primarycolor.primarycolor,fontSize: 50}} />
  
                  <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.recruits.length}</h6>
                  <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total of recruits </h6>
  
                </div>
              </Card>
            </NavLink>
  
          </Col>
          :
          null
        }


        {
          this.state.userrights.view_contracts===true?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <NavLink to='/candidate_contracts/'> 
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                
                  <FilePdfFilled style={{color:primarycolor.primarycolor,fontSize: 50}} />
  
                  <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.candidate_contracts.length}</h6>
                  <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total Contracts </h6>
  
                </div>
              </Card>
            </NavLink>
  
          </Col>
          :
          null
        }
       

        {
          this.state.userprofile.branch_manager===true?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <NavLink to='/recruitmentagencies/'> 
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                
                  <TeamOutlined style={{color:primarycolor.primarycolor,fontSize: 50}} />
  
                  <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.recruitment_agencies.length}</h6>
                  <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total Recruitment agencies </h6>
  
                </div>
              </Card>
            </NavLink>
  
          </Col>
          :
          null
        }


        {
          this.state.userprofile.branch_manager===true?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <NavLink to='/personalbrokers/'> 
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
                
                  <TeamOutlined style={{color:primarycolor.primarycolor,fontSize: 50}} />
  
                  <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.personalbrokers.length}</h6>
                  <h6 style={{display:'flex',marginRight:5,fontSize:16}}> Total Personal brokers </h6>
  
                </div>
              </Card>
            </NavLink>
  
          </Col>
          :
          null
        }
 
        </Row>
        <br></br>

        <Row>
        <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

            <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
               this.gettransactiondata(val)
            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Candidate entries </h5>
                <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.stat_totals.recruits_total}</h6>

          </div>


              </Card>
            </Col>



            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

            <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
               this.gettransactiondata(val)
            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Premedicals </h5>
                <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.stat_totals.premedical_total}</h6>

          </div>


              </Card>
            </Col>

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Gcc medicals</h5>
              <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.stat_totals.gcc_medical_total}</h6>

            </div>


              </Card>
            </Col>

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Training entries</h5>
              <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.stat_totals.trainingsstart_total}</h6>

            </div>


              </Card>
            </Col>


            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Expense Volume</h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.stat_totals.expenses_total} displayType={'text'} thousandSeparator={true}  /></h6>

            </div>
            
            </Card>
            </Col>


            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Incomes Volume</h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.stat_totals.incomes_total} displayType={'text'} thousandSeparator={true}  /></h6>

            </div>
            
            </Card>
            </Col>


            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Interpol applications ({this.state.stat_totals.interpolsapplied_total})</h5>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Interpol issuances ({this.state.stat_totals.interpolsissued_total})</h5>

            </div>

              </Card>
            </Col>


            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Contract issuances ({this.state.stat_totals.contractissuance_total})</h5>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} started Contracts ({this.state.stat_totals.contractstarted_total})</h5>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} ended Contracts ({this.state.stat_totals.contractended_total})</h5>

            </div>

              </Card>
            </Col>


            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Visa applications ({this.state.stat_totals.visaapplications_total})</h5>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Visa acceptances ({this.state.stat_totals.visaacceptance_total})</h5>

            </div>

              </Card>
            </Col>

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Travel ticket payments ({this.state.stat_totals.ticketpayment_total})</h5>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Ticket departures ({this.state.stat_totals.ticketdeparture_total})</h5>

            </div>

              </Card>
            </Col>


            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
              this.gettransactiondata(val)

            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Coordinator reports</h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.stat_totals.cordinatorreports_total}</h6>

            </div>
            
            </Card>
            </Col>

       
        </Row>

        </div>
      )

      }else{
        return(<QuickMenu />);

      }



    }
  
 }

}
}

export default DashBoard; 
