import React from 'react';
import CordinatorReportForm from '../components/CordinatorReportForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined,PrinterFilled,EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import ReactToPrint from 'react-to-print';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var token= ''


class ReportToPrint extends React.Component {

  componentDidMount(){
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }

  //get the age
   getAge=(birth)=> {
    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth() + 1;
    var curr_year = today.getFullYear();
 
    var pieces = birth.split('/');
    var birth_date = pieces[0];
    var birth_month = pieces[1];
    var birth_year = pieces[2];
 
    if (curr_month == birth_month && curr_date >= birth_date) return parseInt(curr_year-birth_year);
    if (curr_month == birth_month && curr_date < birth_date) return parseInt(curr_year-birth_year-1);
    if (curr_month > birth_month) return parseInt(curr_year-birth_year);
    if (curr_month < birth_month) return parseInt(curr_year-birth_year-1);
 }


  render() {
    return (
      <div style={{padding:20}}>
           <h2>Date : {this.props.selected_report.date}</h2>
           <h2>Report No: {this.props.selected_report.report_no}</h2>
           <h2>Cordinator: {this.props.selected_report.cordinator_name}</h2>

           <h1 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>CORDINATOR REPORT</h1>

           <reactstrp.Table bordered>
                    <tbody>

                        <tr>
                        <td>
                        {
                          this.props.selected_report.patient_Photo!=''?
                          <div style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>
                          <img height="200" width="200"  alt="Candidate Photo" 
                          src={this.props.candidateobj.Photo}
                        
                          />
                          </div>
                          :
                          null
                        }

                        </td>
                        <td>

                        <h3 style={{fontWeight:'bolder'}}>Name: {this.props.candidateobj.FullName} </h3>
                        <h3 style={{fontWeight:'bolder'}}>Date of birth: {this.props.candidateobj.date_of_birth}</h3>

                        <h3 style={{fontWeight:'bolder'}}>Age: { this.props.candidateobj.date_of_birth!=undefined?this.getAge(this.props.candidateobj.date_of_birth):null}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Location: {this.props.candidateobj.AreaOfResidence}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Phone contact: {this.props.candidateobj.RegisteredPhoneNumber1}</h3>

                        </td>

                        </tr>
                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Report title:
                            </h4>
                          </td>
                          <td>
                            <h5>
                            {this.props.selected_report.report_title}
                            </h5>
                          </td>
                        </tr>

                    </tbody>
           </reactstrp.Table> 
           <br></br>

           <div>
          { ReactHtmlParser(this.props.selected_report.report_details) }
          </div>

      </div>
    );
  }
}



class CordinatorReportList_Cand extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    cordinator_reports: [],
    datarequested:true,
    cased_mode_visible:false,
    showprintermodal:false,
    selected_report:{},
    candidateobj:{}
  };


  componentDidMount(){
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/cordinator_reports`)
    .then(res => {
        this.setState({
            cordinator_reports:res.data
        })
        this.setState({datarequested:false})
    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/cordinator_reports/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  render() {

    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Candidate',
        dataIndex: 'candidate_name',
        key: 'id',
        ...this.getColumnSearchProps('candidate_name'),
      },
      {
        title: 'Cordinator',
        dataIndex: 'cordinator_name',
        key: 'id',
        ...this.getColumnSearchProps('cordinator_name'),
      },

      {
        title: 'Report no',
        dataIndex: 'report_no',
        key: 'id',
        ...this.getColumnSearchProps('report_no'),
      },
      {
        title: 'Report title',
        dataIndex: 'report_title',
        key: 'id',
        ...this.getColumnSearchProps('report_title'),
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <a href={record.attachment}>Download</a>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here view details</p>} title="View details">
         <FundViewOutlined style={{color:'blue'}} 
          onClick={()=>{
          this.setState({selected_report:record})

          //get attached candidate
          axios.get(`${serverconfig.backendserverurl}/api/recruits/${record.candidate}`)
          .then(res => {  
              this.setState({
                  candidateobj: res.data
              })

              this.setState({cased_mode_visible:true})

            })


         
         }}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to print case details</p>} title="Print">
        <PrinterFilled style={{color:'blue',fontSize: '20px'}} 
          onClick={()=>{
              this.setState({selected_report:record})

              //get attached candidate
              axios.get(`${serverconfig.backendserverurl}/api/recruits/${record.candidate}`)
              .then(res => {  
                  this.setState({
                      candidateobj: res.data
                  })

                  this.setState({showprintermodal:true})


                })

          }}/>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>

          <div style={{display: "none"}}>
              &nbsp;&nbsp;
              <ReportToPrint
              selected_report={this.state.selected_report}
              candidateobj={this.state.candidateobj}

              ref={el => (this.componentRef = el)} /> 
          </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="CORDINATOR REPORTS" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.cordinator_reports}
            bordered/>
             </Panel>
             </Collapse>
            <br /><br />

            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open Form" key="1">
              <h2>Create new entry here</h2>
              <CordinatorReportForm 
                requestType="post"
                idtypeID={null}
                btnText="Create"
                /> 
            </Panel>
            </Collapse>

            <Modal
                  visible={this.state.showprintermodal}
                  title="Printer Request"
                  width={200}
                  onCancel={(val)=>{this.setState({showprintermodal:false})}}  
                  footer={[
                    <Button key="back" onClick={(val)=>{this.setState({showprintermodal:false})}}>
                      Cancel
                    </Button>,
                    <ReactToPrint
                      trigger={() =>  
                      <Button onClick={(val)=>{this.setState({showprintermodal:false})}} type="primary">Yes</Button>        
                    }
                    content={() => this.componentRef}
                    onAfterPrint={(val)=>{this.setState({showprintermodal:false})}}
  
                    />
                    ]}
                    >
                    <h4>Print?</h4>
                      
               </Modal>


               <Modal
                  visible={this.state.cased_mode_visible}
                  title="Case details"
                  //width={200}
                  onCancel={(val)=>{this.setState({cased_mode_visible:false})}}  
                  footer={[
                    <Button key="back" onClick={(val)=>{this.setState({cased_mode_visible:false})}}>
                      Cancel
                    </Button>
                    ]}
                    >
                <ReportToPrint 
                 selected_report={this.state.selected_report}
                 candidateobj={this.state.candidateobj}
                 />
                    
               </Modal>

        </div> )

    }

  }
}

export default CordinatorReportList_Cand; 
