import React from 'react';
import { Form, Input, Button,Spin,DatePicker,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import moment from 'moment';

import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

var token= ''

class CandidateContractEditForm extends React.Component {

  state = {
    date:'' ,  
    candidate:'',
    end_date:'',
    status:'Pending',
    contract_no:'',
    attachment:null,
    datarequested:true,
    recruits:[],
    selected_recruit:'',
    doctor_contact:'',
    employer_name:'',
    recruitment_agencies: [],
    recruitment_agency:'',
    employer_national_id:'',
    employer_address_street:'',
    employer_address_city:'',
    employer_mobile_contact:'',
    employer_mobile_contact_two:'',
    issuance_date:'',

  }


  componentDidMount(){
    const contractID=this.props.match.params.contractID;

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/recruits/`)
    .then(res => {
        this.setState({
          recruits:res.data
        })

        //this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/recruitment_agencies`)
    .then(res => {
        this.setState({
            recruitment_agencies:res.data
        })
        this.setState({datarequested:false})
    })


    axios.get(`${serverconfig.backendserverurl}/api/candidate_contracts/${contractID}`)
    .then(res => {  
      
        this.setState({start_date:res.data.start_date})
        this.setState({end_date:res.data.end_date})
        this.setState({candidate:res.data.candidate})
        this.setState({recruitment_agency:res.data.recruitment_agency})
        this.setState({contract_no:res.data.contract_no})

        this.setState({employer_name:res.data.employer_name})
        this.setState({employer_national_id:res.data.employer_national_id})

        this.setState({employer_address_street:res.data.employer_address_street})
        this.setState({employer_address_city:res.data.employer_address_city})
        this.setState({employer_mobile_contact:res.data.employer_mobile_contact})
        this.setState({employer_mobile_contact_two:res.data.employer_mobile_contact_two})
        this.setState({status:res.data.status})
        this.setState({issuance_date:res.data.issuance_date})

        this.setState({datarequested:false})

    })



  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    const contractID=this.props.match.params.contractID;

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('start_date', this.state.start_date);
    form_data.append('end_date', this.state.end_date);
    form_data.append('issuance_date', this.state.issuance_date);

    form_data.append('candidate', this.state.candidate);
    form_data.append('recruitment_agency', this.state.recruitment_agency);

    form_data.append('status', this.state.status);
    form_data.append('contract_no', this.state.contract_no);
    form_data.append('employer_name', this.state.employer_name);
    form_data.append('employer_national_id', this.state.employer_national_id);
    form_data.append('employer_address_street', this.state.employer_address_street);
    form_data.append('employer_address_city', this.state.employer_address_city);

    form_data.append('employer_mobile_contact', this.state.employer_mobile_contact);
    form_data.append('employer_mobile_contact_two', this.state.employer_mobile_contact_two);

    axios.put(serverconfig.backendserverurl+`/api/candidate_contracts/${contractID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{

      //now update the object reource file
      let form_data1 = new FormData();
      form_data1.append('pri_id', contractID);
      form_data1.append('update_type', "contract");

      this.state.attachment==null?
      console.log("No pre attachmnet file")
      :
      form_data1.append('attachment', this.state.attachment, this.state.attachment?this.state.attachment.name:"");

      axios.post(serverconfig.backendserverurl+'/customqueries/late_fileupload', form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      }).catch(error => console.log(error))
      //end the object file upload




  } )
  .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>   
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

      <FormItem label="Issuance date">
          <DatePicker  onChange={(date, dateString) => this.setState({issuance_date: dateString})} format={dateFormat} />
      </FormItem>
       
        <FormItem label="Select Candidate">
          <Select 
          placeholder="Candidate" 
          style={{  }} 
          value={this.state.candidate}
          onChange={(val)=>{this.setState({candidate:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.recruits.map(
              (accnt)=>(
                <Option value={accnt.id}>{accnt.FullName} | {accnt.recruit_number}</Option>
              ))}
          </Select>
      </FormItem>

      <FormItem label="Select Agency">
          <Select 
          placeholder="Agency" 
          style={{  }} 
          value={this.state.recruitment_agency}
          onChange={(val)=>{this.setState({recruitment_agency:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}              
          >
            {this.state.recruitment_agencies.map(
              (agncy)=>(
                <Option value={agncy.id}>{agncy.companynames} | {agncy.location_city}</Option>
              ))}
          </Select>
      </FormItem>


      <FormItem label="Status"
      >
        <Select 
          value={this.state.status} 
          onChange={(val)=>{this.setState({status:val})}}
          placeholder="Status" >
          <Option value='Pending'>Pending</Option>
          <Option value='Running'>Running</Option>
          <Option value='Terminated'>Terminated</Option>  
          <Option value='Concluded'>Concluded</Option>                         
          </Select>
      </FormItem>

      <FormItem label="Contract no"
      
      >
        <Input name="contract_no"  placeholder="contract_no here" value={this.state.contract_no} onChange={(val)=>{this.setState({contract_no:val.target.value})}} />
      </FormItem>


      <FormItem label="Employer name"
        
      >
        <Input name="employer_name"  placeholder="Employer name" value={this.state.employer_name} onChange={(val)=>{this.setState({employer_name:val.target.value})}} />
      </FormItem>

      <FormItem label="Employer national id"
       
      >
        <Input name="employer_national_id"  placeholder="Employer national id" value={this.state.employer_national_id} onChange={(val)=>{this.setState({employer_national_id:val.target.value})}} />
      </FormItem>

      <FormItem label="Employer address street name"
          
      >
        <Input name="employer_address_street"  placeholder="Employer address street" value={this.state.employer_address_street} onChange={(val)=>{this.setState({employer_address_street:val.target.value})}} />
      </FormItem>

      <FormItem label="Employer address city"
        
      >
        <Input name="employer_address_city"  placeholder="Employer address city" value={this.state.employer_address_city} onChange={(val)=>{this.setState({employer_address_city:val.target.value})}} />
      </FormItem>


      <FormItem label="Employer contact one"
           
      >
        <Input name="employer_mobile_contact"  placeholder="Employer contact one" value={this.state.employer_mobile_contact} onChange={(val)=>{this.setState({employer_mobile_contact:val.target.value})}} />
      </FormItem>

      <FormItem label="Employer contact two"
          
      >
        <Input name="employer_mobile_contact_two"  placeholder="Employer contact two" value={this.state.employer_mobile_contact_two} onChange={(val)=>{this.setState({employer_mobile_contact_two:val.target.value})}} />
      </FormItem>


      <FormItem label="Attachment" style={{marginRight:3}}>
        <Input type="file" accept=".pdf" placeholder="file attachment"  
        onChange={async(e) =>{
          this.setState({ attachment: e.target.files[0]});
        } }/>
      </FormItem> 

      <FormItem label="Start date"
      >
          <DatePicker  onChange={(date, dateString) => this.setState({start_date: dateString})} format={dateFormat} />
      </FormItem>

      <FormItem label="End date"
      >
          <DatePicker onChange={(date, dateString) => this.setState({end_date: dateString})} format={dateFormat} />
      </FormItem>

      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>

      </Form>
        </div>
      );

    }
    
  }

}


export default CandidateContractEditForm;



