import React from 'react';
import CordinatorReportForm from '../components/CordinatorReportForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined,PrinterFilled,EditOutlined,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import ReactToPrint from 'react-to-print';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import CandidatePremedicalsList_Cand_display from './CandidatePremedicalsListView_Cand_display'
import CandidateGccmedicalsList_Cand_display from './CandidateGccmedicalsListView_Cand_display'
import ExpenseList_Cand_display from './ExpenseListView_Cand_display'
import CandidateTrainingList_Cand_display from './CandidateTrainingListView_Cand_display'
import CandidateInterpolList_Cand_display from './CandidateInterpolListView_Cand_display'
import CandidateContractList_Cand_display from './CandidateContractsListView_Cand_display'
import CandidateVisasList_Cand_display from './CandidateVisasListView_Cand_display'
import CandidateTicketList_Cand_display from './CandidateTicketListView_Cand_display'
import CordinatorReportList_Cand_display from './CordinatorReportListView_Cand_display'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var token= ''


class ReportToPrint extends React.Component {

  componentDidMount(){
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

  state = {
    companyprofile:{},
  };

  //get the age
   getAge=(birth)=> {
    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth() + 1;
    var curr_year = today.getFullYear();
 
    var pieces = birth.split('/');
    var birth_date = pieces[0];
    var birth_month = pieces[1];
    var birth_year = pieces[2];
 
    if (curr_month == birth_month && curr_date >= birth_date) return parseInt(curr_year-birth_year);
    if (curr_month == birth_month && curr_date < birth_date) return parseInt(curr_year-birth_year-1);
    if (curr_month > birth_month) return parseInt(curr_year-birth_year);
    if (curr_month < birth_month) return parseInt(curr_year-birth_year-1);
 }

    //check empty objecty
    isEmptyObject=(obj)=>{
      for ( var name in obj ) {
        return false;
      }
      return true;
    }


  render() {
    return (
      <div style={{padding:20}}>

<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.system_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}</h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
              
                </Col>

     </Row>
     <br></br>
     <br></br>

           <h1 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}> CANDIDATE DETAILS</h1>

           <reactstrp.Table bordered>
                    <tbody>

                        <tr>
                        <td>
                        {
                          this.props.selected_candidate.Photo?
                          <div style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>
                          <img height="200" width="200"  alt="Profile pic" 
                          src={this.props.selected_candidate.Photo}
  
                          />
                          </div>
                          :
                          null
                        }

                        </td>
                        <td>
                        <h3 style={{fontWeight:'bolder'}}>Date of entry : {this.props.selected_candidate.date_of_registration}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Candidate No: {this.props.selected_candidate.recruit_number}</h3>

                        <h3 style={{fontWeight:'bolder'}}>Full name: {this.props.selected_candidate.FullName} {this.props.selected_candidate.patientname}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Gender: {this.props.selected_candidate.gender} {this.props.selected_candidate.patientname}</h3>

                        <h3 style={{fontWeight:'bolder'}}>Date of birth: {this.props.selected_candidate.date_of_birth}</h3>

                        <h3 style={{fontWeight:'bolder'}}>Age : { this.props.selected_candidate.date_of_birth!=undefined?this.getAge(this.props.selected_candidate.date_of_birth):null}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Location: {this.props.selected_candidate.AreaOfResidence}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Phone contact: {this.props.selected_candidate.RegisteredPhoneNumber1}</h3>

                        </td>

                        </tr>
                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              More details:
                            </h4>
                          </td>
                          <td>
                          <h3 style={{fontWeight:'bolder'}}>National Id no: {this.props.selected_candidate.national_id_no}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Number of children: {this.props.selected_candidate.no_of_children}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Height: {this.props.selected_candidate.height}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Weight: {this.props.selected_candidate.weight}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Marital status: {this.props.selected_candidate.marital_status}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Religion: {this.props.selected_candidate.religion}</h3>

                          <h3 style={{fontWeight:'bolder'}}>Education level: {this.props.selected_candidate.education_level}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Employment details: {this.props.selected_candidate.previous_employmentdetails}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Skills and competence: {this.props.selected_candidate.skills_and_competence}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Languages: {this.props.selected_candidate.languanges}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Parent details: {this.props.selected_candidate.parent_details}</h3>

                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Next of kin:
                            </h4>
                          </td>
                          <td>
                          <h3 style={{fontWeight:'bolder'}}>Next of kin name: {this.props.selected_candidate.next_of_kin_name}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Next of kin relationship: {this.props.selected_candidate.next_of_kin_relationship}</h3>
                          <h3 style={{fontWeight:'bolder'}}>Next of kin contact: {this.props.selected_candidate.next_of_kin_contact}</h3>

                           
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Passport details:
                            </h4>
                          </td>
                          <td>

                          {
                            this.isEmptyObject(this.props.selected_candidate.passport_details)===false?
                            <div>
                              <h3 style={{fontWeight:'bolder'}}>Passport number:  {JSON.parse(this.props.selected_candidate.passport_details).passport_no }</h3>
                              <h3 style={{fontWeight:'bolder'}}>Place of issuance: {JSON.parse(this.props.selected_candidate.passport_details).passport_placeofissuance}</h3>
                              <h3 style={{fontWeight:'bolder'}}>Date of Issuance: {JSON.parse(this.props.selected_candidate.passport_details).passport_date_ofissuance}</h3>
                              <h3 style={{fontWeight:'bolder'}}>Date of expiry: {JSON.parse(this.props.selected_candidate.passport_details).passport_dateofexpiry}</h3>
                            </div>
                            :
                            null
                          }

                           
                          </td>
                        </tr>

                      {/*new items*/}
                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Premedicals:
                            </h4>
                          </td>
                          <td>
                            <CandidatePremedicalsList_Cand_display candidateID={this.props.selected_candidate.id}/>


                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Gcc medicals:
                            </h4>
                          </td>
                          <td>
                          <CandidateGccmedicalsList_Cand_display candidateID={this.props.selected_candidate.id}/>

                           
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Expenses on candidate:
                            </h4>
                          </td>
                          <td>
                          <ExpenseList_Cand_display candidateID={this.props.selected_candidate.id}/>

                           
                          </td>
                        </tr>


                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Training:
                            </h4>
                          </td>
                          <td>
                          <CandidateTrainingList_Cand_display candidateID={this.props.selected_candidate.id}/>

                           
                          </td>
                        </tr>


                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Interpol:
                            </h4>
                          </td>
                          <td>
                          <CandidateInterpolList_Cand_display candidateID={this.props.selected_candidate.id}/>

                           
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Contracts:
                            </h4>
                          </td>
                          <td>
                          <CandidateContractList_Cand_display candidateID={this.props.selected_candidate.id}/>

                           
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Visas:
                            </h4>
                          </td>
                          <td>
                            <CandidateVisasList_Cand_display candidateID={this.props.selected_candidate.id}/>

                           
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Tickets:
                            </h4>
                          </td>
                          <td>
                          <CandidateTicketList_Cand_display candidateID={this.props.selected_candidate.id}/>

                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>Cordinator Reports</h4>
                            <h4 style={{fontWeight:'bolder'}}>
                              Cordinator: { this.props.selected_candidate.coordinator_name}
                            </h4>
                          </td>
                          <td>
                            <CordinatorReportList_Cand_display candidateID={this.props.selected_candidate.id}/>
                           
                          </td>
                        </tr>

                    </tbody>
           </reactstrp.Table> 
           <br></br>
      </div>
    );
  }
}



class CandidateDetails extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    cordinator_reports: [],
    datarequested:true,
    cased_mode_visible:false,
    showprintermodal:false,
    selected_candidate:{}
  };


  componentDidMount(){
    var candidateID=this.props.candidateID
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/recruits/${candidateID}/`)
    .then(res => {
        this.setState({
            selected_candidate:res.data
        })
        this.setState({datarequested:false})
    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/cordinator_reports/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>
        </div>
      )

    }else{
      return(
        <div>

          <div style={{display: "none"}}>
              &nbsp;&nbsp;
              <ReportToPrint
              selected_candidate={this.state.selected_candidate}
              ref={el => (this.componentRef = el)} /> 
          </div>

          <br></br>

          <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
          <ReactToPrint
            trigger={() => 
            <Button type="primary"  shape="round" icon={<PrinterOutlined />} >
            Print
            </Button>
            }
            content={() => this.componentRef}
            />
          </div>
          <br></br>


          {
            this.state.selected_candidate.date_of_registration===undefined?
            null:
            < ReportToPrint selected_candidate={this.state.selected_candidate} />
          }

          <h3 style={{fontWeight:'bolder'}}>Candidate attachments</h3>
          <h4>National Id: <a href={this.state.selected_candidate.nationalid_attachment}>Preview or Download </a> </h4>
          <h4>Passport: <a href={this.state.selected_candidate.passport_attachment}>Preview or Download </a> </h4>
          <h4>Application form: <a href={this.state.selected_candidate.applicationform_attachment}>Preview or Download </a> </h4>
          <h4>Conscent form: <a href={this.state.selected_candidate.consentform_attachment}>Preview or Download </a> </h4>

        </div> 
        )

    }

  }
}

export default CandidateDetails; 
