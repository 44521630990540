import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Spin } from 'antd';
import AccountUpdateForm from '../components/AccountUpdateForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class AccountDetail extends React.Component{

    state={
        account: {},
        visible: false,
        childrenDrawer: false,
        datarequested:true,

    }

    showDrawer = () => {
        this.setState({
          visible: true,
        });
      };
    
      onClose = () => {
        this.setState({
          visible: false,
        });
      };
    
      showChildrenDrawer = () => {
        this.setState({
          childrenDrawer: true,
        });
      };
    
      onChildrenDrawerClose = () => {
        this.setState({
          childrenDrawer: false,
        });
      };

    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        const accountID=this.props.match.params.accountID;
        axios.get(`${serverconfig.backendserverurl}/api/accounts/${accountID}`)
        .then(res => {  
            this.setState({
                account: res.data
            })

            this.setState({datarequested:false})

        })

    }



    handleCancel = () => this.setState({ previewVisible: false });


    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>            </div>
        )
  
      }else{
        return(
          <div>
              <Row>
              <Col xs="12" sm="6" lg="4">
              <Card title={this.state.account.username}>
                  <p>Email: {this.state.account.email}</p>

                  <p>Active:
                  {
                      this.state.account.is_active?
                      "Yes":
                      "No"
                  }
                  </p>

                  <p>Branch Manager:
                  {
                      this.state.account.branch_manager?
                      "Yes":
                      "No"
                  }
                  </p>

              </Card>
              </Col>

              <Col xs="12" sm="6" lg="4">
              <Card 
                style={{padding:10}}
                hoverable>

              <br></br>
              <Avatar
                  size={150}
                  icon={<UserOutlined />}
                  alt="Company Logo"
                  src={
                    serverconfig.backendserverurl+`/customqueries/getfile/${Number(this.state.account.id)}/${'user_account'}`
                    //this.state.account.profile_pic
                  }
              />

              <h6>Account Profile Update</h6>
              <Button type="primary" onClick={this.showDrawer}>Update Account</Button>
                      
              </Card>

             
              </Col>

              </Row>
             
              <br/>
              <Drawer
                  title="Account Update Form"
                  width={450}
                  closable={false}
                  onClose={this.onClose}
                  visible={this.state.visible}
                  >
                    
               <AccountUpdateForm 
               accountID={this.state.account.id}
               username={this.state.account.username}
               email={this.state.account.email}
               userprofile={this.state.account}/>       

             </Drawer>

          </div>
      )


      }
       
    }
}

export default AccountDetail; 