import React from 'react';
import ExpenseForm from '../components/ExpenseForm'
import axios from 'axios'
import { Table, Input, Button,DatePicker,Select,Form,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,PrinterOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../../serverconn'
import ExpensePaymentForm from '../components/ExpensePaymentForm'
import moment from 'moment';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';

import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { RangePicker } = DatePicker;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
const { TabPane } = Tabs;


function callback(key) {
  console.log(key);
}


var bizuserid= ''
var token= ''



class ExpenseList_Cand_display extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    expenses: [],
    viewpaymentmodal:false,
    expensedetails:{},
    datarequested:true,
    datasearch:false,
    dateone:'',
    datetwo:'',
    totaltobepaid:0,
    totalpaid:0,
    totalbalance:0,
    expenses_list:[],
    selected_expense:'',
    recruits: [],
    selected_candidate:''

  };

  componentDidMount(){
    var candidateID=this.props.candidateID

      if(localStorage.getItem("bizuserid")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/expenses/?expenseto_recruit=${candidateID}`)
    .then(res => {
        this.setState({
          expenses:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/recruits/`)
    .then(res => {
        this.setState({
          recruits:res.data
        })
    })


    let form_data1 = new FormData();
    axios.post(serverconfig.backendserverurl+`/customqueries/get_expenses_list`, form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          expenses_list:JSON.parse(res.data.report)
        })

        this.setState({datarequested:false})
      })

}

////////////////////////////////////////////////////
 /// PAYMENT METHODS BEGIN
 showpaymentmodal=(id)=>{
  //now proceed to get the data for the product
  axios.get(`${serverconfig.backendserverurl}/api/expenses/${id}`)
  .then(res => {  
      this.setState({
        expensedetails:res.data
      })        
  })

  axios.get(`${serverconfig.backendserverurl}/api/expensepayments/?expense=${id}`)
  .then(res => {  
    const totalPaid =res.data.reduce((totalPaid, item) => totalPaid + item.amount, 0);
    this.setState({
      expenseTotalPayment:totalPaid
    })

  })



this.setState({ viewpaymentmodal: true });

}

closepaymentmodal = () => {
this.setState({ viewpaymentmodal: false });
}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    let form_data = new FormData();
    form_data.append('expenseid', id);
    
    axios.post(serverconfig.backendserverurl+'/customqueries/deleteexpense', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => window.location.reload(false),message.info('Success fully deleted Expense')
  )
  .catch(error => console.log(error))
   
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


handledatechange= (date, dateString) =>{
  this.setState({ dateone: dateString[0]});
  this.setState({ datetwo: dateString[1]});
} 


   //search sales method
   searchSales=()=>{

    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('selected_expense', this.state.selected_expense);

    if(this.state.dateone===''||this.state.datetwo===''){
      alert("Dates missing")
    }else{
        this.setState({datarequested:true})
        this.setState({datasearch:true})

        this.setState({expenses:[]})
        this.setState({totaltobepaid:0})
        this.setState({totalpaid:0})
        this.setState({totalbalance:0})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getexpensereport', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          this.setState({datarequested:false})
            this.setState({expenses:JSON.parse(res.data.report)})
            this.setState({totaltobepaid:res.data.totaltobe})
            this.setState({totalpaid:res.data.totalpaid})
            this.setState({totalbalance:res.data.totalbal})
        } 
        )
        .catch(error => console.log(error))   
    }

    }

    calculateTotal_expense=()=>{
      var total=0
      this.state.expenses.map((item)=>{
        total+=Number(item.total_to_be_paid)
      })

      return total;
    }


  render() {

      var columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Expense Item',
          dataIndex: 'expense_item',
          key: 'id',
          ...this.getColumnSearchProps('expense_item'),
        },
        {
          title: 'Expense To',
          dataIndex: 'expenseto_name',
          key: 'id',
          ...this.getColumnSearchProps('expenseto_name'),

        },
        {
          title: 'Amount',
          dataIndex: 'total_to_be_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/expenses/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click to add payment against expense</p>} title="Payment">
            <WalletFilled style={{color:'blue'}} onClick={() => this.showpaymentmodal(text)}/>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete expense</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
  
          </p>,
        }
      ];

    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
    <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>Date </th>
                <th>Expense item</th>
                <th>Expense to   </th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
            {this.state.expenses.map(
              (item)=>(
                <tr>
                <td>{item.date}</td>
                <td>{item.expense_item}</td>
                <td>{item.expenseto_name}</td>
                <td>{<CurrencyFormat value={item.total_to_be_paid} displayType={'text'} thousandSeparator={true}  />}</td>
                </tr>
              ))}

          </tbody>
          </reactstrp.Table> 

          <h2>Total : <CurrencyFormat value={this.calculateTotal_expense()} displayType={'text'} thousandSeparator={true}  /> </h2>
        </div>
    )


    }
    
  }
}

export default ExpenseList_Cand_display; 
